/* @preserve
 * Leaflet 1.7.1, a JS library for interactive maps. http://leafletjs.com
 * (c) 2010-2019 Vladimir Agafonkin, (c) 2010-2011 CloudMade
 */

!(function (t, i) {
  "object" === typeof exports && "undefined" !== typeof module
    ? i(exports)
    : "function" === typeof define && define.amd
    ? define(["exports"], i)
    : i((t.L = {}));
})(this, function (t) {
  "use strict";
  function h(t) {
    for (var i, e, n = 1, o = arguments.length; n < o; n++) {
      for (i in (e = arguments[n])) {
        t[i] = e[i];
      }
    }
    return t;
  }
  let s =
    Object.create ||
    function (t) {
      return (i.prototype = t), new i();
    };
  function i() {}
  function p(t, i) {
    let e = Array.prototype.slice;
    if (t.bind) {
      return t.bind.apply(t, e.call(arguments, 1));
    }
    let n = e.call(arguments, 2);
    return function () {
      return t.apply(i, n.length ? n.concat(e.call(arguments)) : arguments);
    };
  }
  let e = 0;
  function m(t) {
    return (t._leaflet_id = t._leaflet_id || ++e), t._leaflet_id;
  }
  function n(t, i, e) {
    var n,
      o,
      s = function () {
        (n = !1), o && (r.apply(e, o), (o = !1));
      },
      r = function () {
        n
          ? (o = arguments)
          : (t.apply(e, arguments), setTimeout(s, i), (n = !0));
      };
    return r;
  }
  function o(t, i, e) {
    let n = i[1],
      o = i[0],
      s = n - o;
    return t === n && e ? t : ((((t - o) % s) + s) % s) + o;
  }
  function a() {
    return !1;
  }
  function r(t, i) {
    let e = Math.pow(10, void 0 === i ? 6 : i);
    return Math.round(t * e) / e;
  }
  function u(t) {
    return t.trim ? t.trim() : t.replace(/^\s+|\s+$/g, "");
  }
  function l(t) {
    return u(t).split(/\s+/);
  }
  function c(t, i) {
    for (let e in (Object.prototype.hasOwnProperty.call(t, "options") ||
      (t.options = t.options ? s(t.options) : {}),
    i)) {
      t.options[e] = i[e];
    }
    return t.options;
  }
  function _(t, i, e) {
    let n = [];
    for (let o in t) {
      n.push(
        encodeURIComponent(e ? o.toUpperCase() : o) +
          "=" +
          encodeURIComponent(t[o])
      );
    }
    return (i && -1 !== i.indexOf("?") ? "&" : "?") + n.join("&");
  }
  let d = /\{ *([\w_-]+) *\}/g;
  function f(t, n) {
    return t.replace(d, function (t, i) {
      let e = n[i];
      if (void 0 === e) {
        throw new Error("No value provided for variable " + t);
      }
      return "function" === typeof e && (e = e(n)), e;
    });
  }
  let g =
    Array.isArray ||
    function (t) {
      return "[object Array]" === Object.prototype.toString.call(t);
    };
  function v(t, i) {
    for (let e = 0; e < t.length; e++) {
      if (t[e] === i) {
        return e;
      }
    }
    return -1;
  }
  let y = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
  function x(t) {
    return window["webkit" + t] || window["moz" + t] || window["ms" + t];
  }
  let w = 0;
  function P(t) {
    let i = +new Date(),
      e = Math.max(0, 16 - (i - w));
    return (w = i + e), window.setTimeout(t, e);
  }
  let b = window.requestAnimationFrame || x("RequestAnimationFrame") || P,
    T =
      window.cancelAnimationFrame ||
      x("CancelAnimationFrame") ||
      x("CancelRequestAnimationFrame") ||
      function (t) {
        window.clearTimeout(t);
      };
  function M(t, i, e) {
    if (!e || b !== P) {
      return b.call(window, p(t, i));
    }
    t.call(i);
  }
  function z(t) {
    t && T.call(window, t);
  }
  let C = {
    extend: h,
    create: s,
    bind: p,
    lastId: e,
    stamp: m,
    throttle: n,
    wrapNum: o,
    falseFn: a,
    formatNum: r,
    trim: u,
    splitWords: l,
    setOptions: c,
    getParamString: _,
    template: f,
    isArray: g,
    indexOf: v,
    emptyImageUrl: y,
    requestFn: b,
    cancelFn: T,
    requestAnimFrame: M,
    cancelAnimFrame: z,
  };
  function S() {}
  (S.extend = function (t) {
    function i() {
      this.initialize && this.initialize.apply(this, arguments),
        this.callInitHooks();
    }
    let e = (i.__super__ = this.prototype),
      n = s(e);
    for (let o in (((n.constructor = i).prototype = n), this)) {
      Object.prototype.hasOwnProperty.call(this, o) &&
        "prototype" !== o &&
        "__super__" !== o &&
        (i[o] = this[o]);
    }
    return (
      t.statics && (h(i, t.statics), delete t.statics),
      t.includes &&
        ((function (t) {
          if ("undefined" === typeof L || !L || !L.Mixin) {
            return;
          }
          t = g(t) ? t : [t];
          for (let i = 0; i < t.length; i++) {
            t[i] === L.Mixin.Events &&
              console.warn(
                "Deprecated include of L.Mixin.Events: this property will be removed in future releases, please inherit from L.Evented instead.",
                new Error().stack
              );
          }
        })(t.includes),
        h.apply(null, [n].concat(t.includes)),
        delete t.includes),
      n.options && (t.options = h(s(n.options), t.options)),
      h(n, t),
      (n._initHooks = []),
      (n.callInitHooks = function () {
        if (!this._initHooksCalled) {
          e.callInitHooks && e.callInitHooks.call(this),
            (this._initHooksCalled = !0);
          for (let t = 0, i = n._initHooks.length; t < i; t++) {
            n._initHooks[t].call(this);
          }
        }
      }),
      i
    );
  }),
    (S.include = function (t) {
      return h(this.prototype, t), this;
    }),
    (S.mergeOptions = function (t) {
      return h(this.prototype.options, t), this;
    }),
    (S.addInitHook = function (t) {
      let i = Array.prototype.slice.call(arguments, 1),
        e =
          "function" === typeof t
            ? t
            : function () {
                this[t].apply(this, i);
              };
      return (
        (this.prototype._initHooks = this.prototype._initHooks || []),
        this.prototype._initHooks.push(e),
        this
      );
    });
  let Z = {
    on: function (t, i, e) {
      if ("object" === typeof t) {
        for (let n in t) {
          this._on(n, t[n], i);
        }
      } else {
        for (let o = 0, s = (t = l(t)).length; o < s; o++) {
          this._on(t[o], i, e);
        }
      }
      return this;
    },
    off: function (t, i, e) {
      if (t) {
        if ("object" === typeof t) {
          for (let n in t) {
            this._off(n, t[n], i);
          }
        } else {
          for (let o = 0, s = (t = l(t)).length; o < s; o++) {
            this._off(t[o], i, e);
          }
        }
      } else {
        delete this._events;
      }
      return this;
    },
    _on: function (t, i, e) {
      this._events = this._events || {};
      let n = this._events[t];
      n || ((n = []), (this._events[t] = n)), e === this && (e = void 0);
      for (var o = { fn: i, ctx: e }, s = n, r = 0, a = s.length; r < a; r++) {
        if (s[r].fn === i && s[r].ctx === e) {
          return;
        }
      }
      s.push(o);
    },
    _off: function (t, i, e) {
      let n, o, s;
      if (this._events && (n = this._events[t])) {
        if (i) {
          if ((e === this && (e = void 0), n)) {
            for (o = 0, s = n.length; o < s; o++) {
              let r = n[o];
              if (r.ctx === e && r.fn === i) {
                return (
                  (r.fn = a),
                  this._firingCount && (this._events[t] = n = n.slice()),
                  void n.splice(o, 1)
                );
              }
            }
          }
        } else {
          for (o = 0, s = n.length; o < s; o++) {
            n[o].fn = a;
          }
          delete this._events[t];
        }
      }
    },
    fire: function (t, i, e) {
      if (!this.listens(t, e)) {
        return this;
      }
      let n = h({}, i, {
        type: t,
        target: this,
        sourceTarget: (i && i.sourceTarget) || this,
      });
      if (this._events) {
        let o = this._events[t];
        if (o) {
          this._firingCount = this._firingCount + 1 || 1;
          for (let s = 0, r = o.length; s < r; s++) {
            let a = o[s];
            a.fn.call(a.ctx || this, n);
          }
          this._firingCount--;
        }
      }
      return e && this._propagateEvent(n), this;
    },
    listens: function (t, i) {
      let e = this._events && this._events[t];
      if (e && e.length) {
        return !0;
      }
      if (i) {
        for (let n in this._eventParents) {
          if (this._eventParents[n].listens(t, i)) {
            return !0;
          }
        }
      }
      return !1;
    },
    once: function (t, i, e) {
      if ("object" === typeof t) {
        for (let n in t) {
          this.once(n, t[n], i);
        }
        return this;
      }
      var o = p(function () {
        this.off(t, i, e).off(t, o, e);
      }, this);
      return this.on(t, i, e).on(t, o, e);
    },
    addEventParent: function (t) {
      return (
        (this._eventParents = this._eventParents || {}),
        (this._eventParents[m(t)] = t),
        this
      );
    },
    removeEventParent: function (t) {
      return this._eventParents && delete this._eventParents[m(t)], this;
    },
    _propagateEvent: function (t) {
      for (let i in this._eventParents) {
        this._eventParents[i].fire(
          t.type,
          h({ layer: t.target, propagatedFrom: t.target }, t),
          !0
        );
      }
    },
  };
  (Z.addEventListener = Z.on),
    (Z.removeEventListener = Z.clearAllEventListeners = Z.off),
    (Z.addOneTimeEventListener = Z.once),
    (Z.fireEvent = Z.fire),
    (Z.hasEventListeners = Z.listens);
  let E = S.extend(Z);
  function k(t, i, e) {
    (this.x = e ? Math.round(t) : t), (this.y = e ? Math.round(i) : i);
  }
  let B =
    Math.trunc ||
    function (t) {
      return 0 < t ? Math.floor(t) : Math.ceil(t);
    };
  function A(t, i, e) {
    return t instanceof k
      ? t
      : g(t)
      ? new k(t[0], t[1])
      : null == t
      ? t
      : "object" === typeof t && "x" in t && "y" in t
      ? new k(t.x, t.y)
      : new k(t, i, e);
  }
  function I(t, i) {
    if (t) {
      for (let e = i ? [t, i] : t, n = 0, o = e.length; n < o; n++) {
        this.extend(e[n]);
      }
    }
  }
  function O(t, i) {
    return !t || t instanceof I ? t : new I(t, i);
  }
  function R(t, i) {
    if (t) {
      for (let e = i ? [t, i] : t, n = 0, o = e.length; n < o; n++) {
        this.extend(e[n]);
      }
    }
  }
  function N(t, i) {
    return t instanceof R ? t : new R(t, i);
  }
  function D(t, i, e) {
    if (isNaN(t) || isNaN(i)) {
      throw new Error("Invalid LatLng object: (" + t + ", " + i + ")");
    }
    (this.lat = +t), (this.lng = +i), void 0 !== e && (this.alt = +e);
  }
  function j(t, i, e) {
    return t instanceof D
      ? t
      : g(t) && "object" !== typeof t[0]
      ? 3 === t.length
        ? new D(t[0], t[1], t[2])
        : 2 === t.length
        ? new D(t[0], t[1])
        : null
      : null == t
      ? t
      : "object" === typeof t && "lat" in t
      ? new D(t.lat, "lng" in t ? t.lng : t.lon, t.alt)
      : void 0 === i
      ? null
      : new D(t, i, e);
  }
  (k.prototype = {
    clone: function () {
      return new k(this.x, this.y);
    },
    add: function (t) {
      return this.clone()._add(A(t));
    },
    _add: function (t) {
      return (this.x += t.x), (this.y += t.y), this;
    },
    subtract: function (t) {
      return this.clone()._subtract(A(t));
    },
    _subtract: function (t) {
      return (this.x -= t.x), (this.y -= t.y), this;
    },
    divideBy: function (t) {
      return this.clone()._divideBy(t);
    },
    _divideBy: function (t) {
      return (this.x /= t), (this.y /= t), this;
    },
    multiplyBy: function (t) {
      return this.clone()._multiplyBy(t);
    },
    _multiplyBy: function (t) {
      return (this.x *= t), (this.y *= t), this;
    },
    scaleBy: function (t) {
      return new k(this.x * t.x, this.y * t.y);
    },
    unscaleBy: function (t) {
      return new k(this.x / t.x, this.y / t.y);
    },
    round: function () {
      return this.clone()._round();
    },
    _round: function () {
      return (this.x = Math.round(this.x)), (this.y = Math.round(this.y)), this;
    },
    floor: function () {
      return this.clone()._floor();
    },
    _floor: function () {
      return (this.x = Math.floor(this.x)), (this.y = Math.floor(this.y)), this;
    },
    ceil: function () {
      return this.clone()._ceil();
    },
    _ceil: function () {
      return (this.x = Math.ceil(this.x)), (this.y = Math.ceil(this.y)), this;
    },
    trunc: function () {
      return this.clone()._trunc();
    },
    _trunc: function () {
      return (this.x = B(this.x)), (this.y = B(this.y)), this;
    },
    distanceTo: function (t) {
      let i = (t = A(t)).x - this.x,
        e = t.y - this.y;
      return Math.sqrt(i * i + e * e);
    },
    equals: function (t) {
      return (t = A(t)).x === this.x && t.y === this.y;
    },
    contains: function (t) {
      return (
        (t = A(t)),
        Math.abs(t.x) <= Math.abs(this.x) && Math.abs(t.y) <= Math.abs(this.y)
      );
    },
    toString: function () {
      return "Point(" + r(this.x) + ", " + r(this.y) + ")";
    },
  }),
    (I.prototype = {
      extend: function (t) {
        return (
          (t = A(t)),
          this.min || this.max
            ? ((this.min.x = Math.min(t.x, this.min.x)),
              (this.max.x = Math.max(t.x, this.max.x)),
              (this.min.y = Math.min(t.y, this.min.y)),
              (this.max.y = Math.max(t.y, this.max.y)))
            : ((this.min = t.clone()), (this.max = t.clone())),
          this
        );
      },
      getCenter: function (t) {
        return new k(
          (this.min.x + this.max.x) / 2,
          (this.min.y + this.max.y) / 2,
          t
        );
      },
      getBottomLeft: function () {
        return new k(this.min.x, this.max.y);
      },
      getTopRight: function () {
        return new k(this.max.x, this.min.y);
      },
      getTopLeft: function () {
        return this.min;
      },
      getBottomRight: function () {
        return this.max;
      },
      getSize: function () {
        return this.max.subtract(this.min);
      },
      contains: function (t) {
        let i, e;
        return (
          (t = ("number" === typeof t[0] || t instanceof k ? A : O)(
            t
          )) instanceof I
            ? ((i = t.min), (e = t.max))
            : (i = e = t),
          i.x >= this.min.x &&
            e.x <= this.max.x &&
            i.y >= this.min.y &&
            e.y <= this.max.y
        );
      },
      intersects: function (t) {
        t = O(t);
        let i = this.min,
          e = this.max,
          n = t.min,
          o = t.max,
          s = o.x >= i.x && n.x <= e.x,
          r = o.y >= i.y && n.y <= e.y;
        return s && r;
      },
      overlaps: function (t) {
        t = O(t);
        let i = this.min,
          e = this.max,
          n = t.min,
          o = t.max,
          s = o.x > i.x && n.x < e.x,
          r = o.y > i.y && n.y < e.y;
        return s && r;
      },
      isValid: function () {
        return !(!this.min || !this.max);
      },
    }),
    (R.prototype = {
      extend: function (t) {
        let i,
          e,
          n = this._southWest,
          o = this._northEast;
        if (t instanceof D) {
          e = i = t;
        } else {
          if (!(t instanceof R)) {
            return t ? this.extend(j(t) || N(t)) : this;
          }
          if (((i = t._southWest), (e = t._northEast), !i || !e)) {
            return this;
          }
        }
        return (
          n || o
            ? ((n.lat = Math.min(i.lat, n.lat)),
              (n.lng = Math.min(i.lng, n.lng)),
              (o.lat = Math.max(e.lat, o.lat)),
              (o.lng = Math.max(e.lng, o.lng)))
            : ((this._southWest = new D(i.lat, i.lng)),
              (this._northEast = new D(e.lat, e.lng))),
          this
        );
      },
      pad: function (t) {
        let i = this._southWest,
          e = this._northEast,
          n = Math.abs(i.lat - e.lat) * t,
          o = Math.abs(i.lng - e.lng) * t;
        return new R(new D(i.lat - n, i.lng - o), new D(e.lat + n, e.lng + o));
      },
      getCenter: function () {
        return new D(
          (this._southWest.lat + this._northEast.lat) / 2,
          (this._southWest.lng + this._northEast.lng) / 2
        );
      },
      getSouthWest: function () {
        return this._southWest;
      },
      getNorthEast: function () {
        return this._northEast;
      },
      getNorthWest: function () {
        return new D(this.getNorth(), this.getWest());
      },
      getSouthEast: function () {
        return new D(this.getSouth(), this.getEast());
      },
      getWest: function () {
        return this._southWest.lng;
      },
      getSouth: function () {
        return this._southWest.lat;
      },
      getEast: function () {
        return this._northEast.lng;
      },
      getNorth: function () {
        return this._northEast.lat;
      },
      contains: function (t) {
        t = ("number" === typeof t[0] || t instanceof D || "lat" in t ? j : N)(
          t
        );
        let i,
          e,
          n = this._southWest,
          o = this._northEast;
        return (
          t instanceof R
            ? ((i = t.getSouthWest()), (e = t.getNorthEast()))
            : (i = e = t),
          i.lat >= n.lat && e.lat <= o.lat && i.lng >= n.lng && e.lng <= o.lng
        );
      },
      intersects: function (t) {
        t = N(t);
        let i = this._southWest,
          e = this._northEast,
          n = t.getSouthWest(),
          o = t.getNorthEast(),
          s = o.lat >= i.lat && n.lat <= e.lat,
          r = o.lng >= i.lng && n.lng <= e.lng;
        return s && r;
      },
      overlaps: function (t) {
        t = N(t);
        let i = this._southWest,
          e = this._northEast,
          n = t.getSouthWest(),
          o = t.getNorthEast(),
          s = o.lat > i.lat && n.lat < e.lat,
          r = o.lng > i.lng && n.lng < e.lng;
        return s && r;
      },
      toBBoxString: function () {
        return [
          this.getWest(),
          this.getSouth(),
          this.getEast(),
          this.getNorth(),
        ].join(",");
      },
      equals: function (t, i) {
        return (
          !!t &&
          ((t = N(t)),
          this._southWest.equals(t.getSouthWest(), i) &&
            this._northEast.equals(t.getNorthEast(), i))
        );
      },
      isValid: function () {
        return !(!this._southWest || !this._northEast);
      },
    });
  var W,
    H = {
      latLngToPoint: function (t, i) {
        let e = this.projection.project(t),
          n = this.scale(i);
        return this.transformation._transform(e, n);
      },
      pointToLatLng: function (t, i) {
        let e = this.scale(i),
          n = this.transformation.untransform(t, e);
        return this.projection.unproject(n);
      },
      project: function (t) {
        return this.projection.project(t);
      },
      unproject: function (t) {
        return this.projection.unproject(t);
      },
      scale: function (t) {
        return 256 * Math.pow(2, t);
      },
      zoom: function (t) {
        return Math.log(t / 256) / Math.LN2;
      },
      getProjectedBounds: function (t) {
        if (this.infinite) {
          return null;
        }
        let i = this.projection.bounds,
          e = this.scale(t);
        return new I(
          this.transformation.transform(i.min, e),
          this.transformation.transform(i.max, e)
        );
      },
      infinite: !(D.prototype = {
        equals: function (t, i) {
          return (
            !!t &&
            ((t = j(t)),
            Math.max(Math.abs(this.lat - t.lat), Math.abs(this.lng - t.lng)) <=
              (void 0 === i ? 1e-9 : i))
          );
        },
        toString: function (t) {
          return "LatLng(" + r(this.lat, t) + ", " + r(this.lng, t) + ")";
        },
        distanceTo: function (t) {
          return F.distance(this, j(t));
        },
        wrap: function () {
          return F.wrapLatLng(this);
        },
        toBounds: function (t) {
          let i = (180 * t) / 40075017,
            e = i / Math.cos((Math.PI / 180) * this.lat);
          return N([this.lat - i, this.lng - e], [this.lat + i, this.lng + e]);
        },
        clone: function () {
          return new D(this.lat, this.lng, this.alt);
        },
      }),
      wrapLatLng: function (t) {
        let i = this.wrapLng ? o(t.lng, this.wrapLng, !0) : t.lng;
        return new D(
          this.wrapLat ? o(t.lat, this.wrapLat, !0) : t.lat,
          i,
          t.alt
        );
      },
      wrapLatLngBounds: function (t) {
        let i = t.getCenter(),
          e = this.wrapLatLng(i),
          n = i.lat - e.lat,
          o = i.lng - e.lng;
        if (0 == n && 0 == o) {
          return t;
        }
        let s = t.getSouthWest(),
          r = t.getNorthEast();
        return new R(new D(s.lat - n, s.lng - o), new D(r.lat - n, r.lng - o));
      },
    },
    F = h({}, H, {
      wrapLng: [-180, 180],
      R: 6371e3,
      distance: function (t, i) {
        let e = Math.PI / 180,
          n = t.lat * e,
          o = i.lat * e,
          s = Math.sin(((i.lat - t.lat) * e) / 2),
          r = Math.sin(((i.lng - t.lng) * e) / 2),
          a = s * s + Math.cos(n) * Math.cos(o) * r * r,
          h = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return this.R * h;
      },
    }),
    U = 6378137,
    V = {
      R: U,
      MAX_LATITUDE: 85.0511287798,
      project: function (t) {
        let i = Math.PI / 180,
          e = this.MAX_LATITUDE,
          n = Math.max(Math.min(e, t.lat), -e),
          o = Math.sin(n * i);
        return new k(
          this.R * t.lng * i,
          (this.R * Math.log((1 + o) / (1 - o))) / 2
        );
      },
      unproject: function (t) {
        let i = 180 / Math.PI;
        return new D(
          (2 * Math.atan(Math.exp(t.y / this.R)) - Math.PI / 2) * i,
          (t.x * i) / this.R
        );
      },
      bounds: new I([-(W = U * Math.PI), -W], [W, W]),
    };
  function q(t, i, e, n) {
    if (g(t)) {
      return (
        (this._a = t[0]),
        (this._b = t[1]),
        (this._c = t[2]),
        void (this._d = t[3])
      );
    }
    (this._a = t), (this._b = i), (this._c = e), (this._d = n);
  }
  function G(t, i, e, n) {
    return new q(t, i, e, n);
  }
  q.prototype = {
    transform: function (t, i) {
      return this._transform(t.clone(), i);
    },
    _transform: function (t, i) {
      return (
        (i = i || 1),
        (t.x = i * (this._a * t.x + this._b)),
        (t.y = i * (this._c * t.y + this._d)),
        t
      );
    },
    untransform: function (t, i) {
      return (
        (i = i || 1),
        new k((t.x / i - this._b) / this._a, (t.y / i - this._d) / this._c)
      );
    },
  };
  let K,
    Y = h({}, F, {
      code: "EPSG:3857",
      projection: V,
      transformation: G((K = 0.5 / (Math.PI * V.R)), 0.5, -K, 0.5),
    }),
    X = h({}, Y, { code: "EPSG:900913" });
  function J(t) {
    return document.createElementNS("http://www.w3.org/2000/svg", t);
  }
  function $(t, i) {
    for (var e, n, o, s, r = "", a = 0, h = t.length; a < h; a++) {
      for (e = 0, n = (o = t[a]).length; e < n; e++) {
        r += (e ? "L" : "M") + (s = o[e]).x + " " + s.y;
      }
      r += i ? (Zt ? "z" : "x") : "";
    }
    return r || "M0 0";
  }
  var Q = document.documentElement.style,
    tt = "ActiveXObject" in window,
    it = tt && !document.addEventListener,
    et = "msLaunchUri" in navigator && !("documentMode" in document),
    nt = kt("webkit"),
    ot = kt("android"),
    st = kt("android 2") || kt("android 3"),
    rt = parseInt(/WebKit\/([0-9]+)|$/.exec(navigator.userAgent)[1], 10),
    at = ot && kt("Google") && rt < 537 && !("AudioNode" in window),
    ht = !!window.opera,
    ut = !et && kt("chrome"),
    lt = kt("gecko") && !nt && !ht && !tt,
    ct = !ut && kt("safari"),
    _t = kt("phantom"),
    dt = "OTransition" in Q,
    pt = 0 === navigator.platform.indexOf("Win"),
    mt = tt && "transition" in Q,
    ft =
      "WebKitCSSMatrix" in window &&
      "m11" in new window.WebKitCSSMatrix() &&
      !st,
    gt = "MozPerspective" in Q,
    vt = !window.L_DISABLE_3D && (mt || ft || gt) && !dt && !_t,
    yt = "undefined" !== typeof orientation || kt("mobile"),
    xt = yt && nt,
    wt = yt && ft,
    Pt = !window.PointerEvent && window.MSPointerEvent,
    Lt = !(!window.PointerEvent && !Pt),
    bt =
      !window.L_NO_TOUCH &&
      (Lt ||
        "ontouchstart" in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch)),
    Tt = yt && ht,
    Mt = yt && lt,
    zt =
      1 <
      (window.devicePixelRatio ||
        window.screen.deviceXDPI / window.screen.logicalXDPI),
    Ct = (function () {
      let t = !1;
      try {
        let i = Object.defineProperty({}, "passive", {
          get: function () {
            t = !0;
          },
        });
        window.addEventListener("testPassiveEventSupport", a, i),
          window.removeEventListener("testPassiveEventSupport", a, i);
      } catch (t) {}
      return t;
    })(),
    St = !!document.createElement("canvas").getContext,
    Zt = !(!document.createElementNS || !J("svg").createSVGRect),
    Et =
      !Zt &&
      (function () {
        try {
          let t = document.createElement("div");
          t.innerHTML = '<v:shape adj="1"/>';
          let i = t.firstChild;
          return (
            (i.style.behavior = "url(#default#VML)"),
            i && "object" === typeof i.adj
          );
        } catch (t) {
          return !1;
        }
      })();
  function kt(t) {
    return 0 <= navigator.userAgent.toLowerCase().indexOf(t);
  }
  let Bt = {
      ie: tt,
      ielt9: it,
      edge: et,
      webkit: nt,
      android: ot,
      android23: st,
      androidStock: at,
      opera: ht,
      chrome: ut,
      gecko: lt,
      safari: ct,
      phantom: _t,
      opera12: dt,
      win: pt,
      ie3d: mt,
      webkit3d: ft,
      gecko3d: gt,
      any3d: vt,
      mobile: yt,
      mobileWebkit: xt,
      mobileWebkit3d: wt,
      msPointer: Pt,
      pointer: Lt,
      touch: bt,
      mobileOpera: Tt,
      mobileGecko: Mt,
      retina: zt,
      passiveEvents: Ct,
      canvas: St,
      svg: Zt,
      vml: Et,
    },
    At = Pt ? "MSPointerDown" : "pointerdown",
    It = Pt ? "MSPointerMove" : "pointermove",
    Ot = Pt ? "MSPointerUp" : "pointerup",
    Rt = Pt ? "MSPointerCancel" : "pointercancel",
    Nt = {},
    Dt = !1;
  function jt(t, i, e, n) {
    function o(t) {
      Ut(t, r);
    }
    let s, r, a, h, u, l, c, _;
    function d(t) {
      (t.pointerType === (t.MSPOINTER_TYPE_MOUSE || "mouse") &&
        0 === t.buttons) ||
        Ut(t, h);
    }
    return (
      "touchstart" === i
        ? ((u = t),
          (l = e),
          (c = n),
          (_ = p(function (t) {
            t.MSPOINTER_TYPE_TOUCH &&
              t.pointerType === t.MSPOINTER_TYPE_TOUCH &&
              Ri(t),
              Ut(t, l);
          })),
          (u["_leaflet_touchstart" + c] = _),
          u.addEventListener(At, _, !1),
          Dt ||
            (document.addEventListener(At, Wt, !0),
            document.addEventListener(It, Ht, !0),
            document.addEventListener(Ot, Ft, !0),
            document.addEventListener(Rt, Ft, !0),
            (Dt = !0)))
        : "touchmove" === i
        ? ((h = e),
          ((a = t)["_leaflet_touchmove" + n] = d),
          a.addEventListener(It, d, !1))
        : "touchend" === i &&
          ((r = e),
          ((s = t)["_leaflet_touchend" + n] = o),
          s.addEventListener(Ot, o, !1),
          s.addEventListener(Rt, o, !1)),
      this
    );
  }
  function Wt(t) {
    Nt[t.pointerId] = t;
  }
  function Ht(t) {
    Nt[t.pointerId] && (Nt[t.pointerId] = t);
  }
  function Ft(t) {
    delete Nt[t.pointerId];
  }
  function Ut(t, i) {
    for (let e in ((t.touches = []), Nt)) {
      t.touches.push(Nt[e]);
    }
    (t.changedTouches = [t]), i(t);
  }
  let Vt = Pt ? "MSPointerDown" : Lt ? "pointerdown" : "touchstart",
    qt = Pt ? "MSPointerUp" : Lt ? "pointerup" : "touchend",
    Gt = "_leaflet_";
  let Kt,
    Yt,
    Xt,
    Jt,
    $t,
    Qt,
    ti = fi([
      "transform",
      "webkitTransform",
      "OTransform",
      "MozTransform",
      "msTransform",
    ]),
    ii = fi([
      "webkitTransition",
      "transition",
      "OTransition",
      "MozTransition",
      "msTransition",
    ]),
    ei =
      "webkitTransition" === ii || "OTransition" === ii
        ? ii + "End"
        : "transitionend";
  function ni(t) {
    return "string" === typeof t ? document.getElementById(t) : t;
  }
  function oi(t, i) {
    let e,
      n = t.style[i] || (t.currentStyle && t.currentStyle[i]);
    return (
      (n && "auto" !== n) ||
        !document.defaultView ||
        (n = (e = document.defaultView.getComputedStyle(t, null))
          ? e[i]
          : null),
      "auto" === n ? null : n
    );
  }
  function si(t, i, e) {
    let n = document.createElement(t);
    return (n.className = i || ""), e && e.appendChild(n), n;
  }
  function ri(t) {
    let i = t.parentNode;
    i && i.removeChild(t);
  }
  function ai(t) {
    for (; t.firstChild; ) {
      t.removeChild(t.firstChild);
    }
  }
  function hi(t) {
    let i = t.parentNode;
    i && i.lastChild !== t && i.appendChild(t);
  }
  function ui(t) {
    let i = t.parentNode;
    i && i.firstChild !== t && i.insertBefore(t, i.firstChild);
  }
  function li(t, i) {
    if (void 0 !== t.classList) {
      return t.classList.contains(i);
    }
    let e = pi(t);
    return 0 < e.length && new RegExp("(^|\\s)" + i + "(\\s|$)").test(e);
  }
  function ci(t, i) {
    let e;
    if (void 0 !== t.classList) {
      for (let n = l(i), o = 0, s = n.length; o < s; o++) {
        t.classList.add(n[o]);
      }
    } else {
      li(t, i) || di(t, ((e = pi(t)) ? e + " " : "") + i);
    }
  }
  function _i(t, i) {
    void 0 !== t.classList
      ? t.classList.remove(i)
      : di(t, u((" " + pi(t) + " ").replace(" " + i + " ", " ")));
  }
  function di(t, i) {
    void 0 === t.className.baseVal
      ? (t.className = i)
      : (t.className.baseVal = i);
  }
  function pi(t) {
    return (
      t.correspondingElement && (t = t.correspondingElement),
      void 0 === t.className.baseVal ? t.className : t.className.baseVal
    );
  }
  function mi(t, i) {
    "opacity" in t.style
      ? (t.style.opacity = i)
      : "filter" in t.style &&
        (function (t, i) {
          let e = !1,
            n = "DXImageTransform.Microsoft.Alpha";
          try {
            e = t.filters.item(n);
          } catch (t) {
            if (1 === i) {
              return;
            }
          }
          (i = Math.round(100 * i)),
            e
              ? ((e.Enabled = 100 !== i), (e.Opacity = i))
              : (t.style.filter += " progid:" + n + "(opacity=" + i + ")");
        })(t, i);
  }
  function fi(t) {
    for (let i = document.documentElement.style, e = 0; e < t.length; e++) {
      if (t[e] in i) {
        return t[e];
      }
    }
    return !1;
  }
  function gi(t, i, e) {
    let n = i || new k(0, 0);
    t.style[ti] =
      (mt
        ? "translate(" + n.x + "px," + n.y + "px)"
        : "translate3d(" + n.x + "px," + n.y + "px,0)") +
      (e ? " scale(" + e + ")" : "");
  }
  function vi(t, i) {
    (t._leaflet_pos = i),
      vt ? gi(t, i) : ((t.style.left = i.x + "px"), (t.style.top = i.y + "px"));
  }
  function yi(t) {
    return t._leaflet_pos || new k(0, 0);
  }
  function xi() {
    zi(window, "dragstart", Ri);
  }
  function wi() {
    Si(window, "dragstart", Ri);
  }
  function Pi(t) {
    for (; -1 === t.tabIndex; ) {
      t = t.parentNode;
    }
    t.style &&
      (Li(),
      (Qt = ($t = t).style.outline),
      (t.style.outline = "none"),
      zi(window, "keydown", Li));
  }
  function Li() {
    $t &&
      (($t.style.outline = Qt), (Qt = $t = void 0), Si(window, "keydown", Li));
  }
  function bi(t) {
    for (
      ;
      !(
        ((t = t.parentNode).offsetWidth && t.offsetHeight) ||
        t === document.body
      );

    ) {}
    return t;
  }
  function Ti(t) {
    let i = t.getBoundingClientRect();
    return {
      x: i.width / t.offsetWidth || 1,
      y: i.height / t.offsetHeight || 1,
      boundingClientRect: i,
    };
  }
  Jt =
    "onselectstart" in document
      ? ((Xt = function () {
          zi(window, "selectstart", Ri);
        }),
        function () {
          Si(window, "selectstart", Ri);
        })
      : ((Yt = fi([
          "userSelect",
          "WebkitUserSelect",
          "OUserSelect",
          "MozUserSelect",
          "msUserSelect",
        ])),
        (Xt = function () {
          let t;
          Yt &&
            ((t = document.documentElement.style),
            (Kt = t[Yt]),
            (t[Yt] = "none"));
        }),
        function () {
          Yt && ((document.documentElement.style[Yt] = Kt), (Kt = void 0));
        });
  let Mi = {
    TRANSFORM: ti,
    TRANSITION: ii,
    TRANSITION_END: ei,
    get: ni,
    getStyle: oi,
    create: si,
    remove: ri,
    empty: ai,
    toFront: hi,
    toBack: ui,
    hasClass: li,
    addClass: ci,
    removeClass: _i,
    setClass: di,
    getClass: pi,
    setOpacity: mi,
    testProp: fi,
    setTransform: gi,
    setPosition: vi,
    getPosition: yi,
    disableTextSelection: Xt,
    enableTextSelection: Jt,
    disableImageDrag: xi,
    enableImageDrag: wi,
    preventOutline: Pi,
    restoreOutline: Li,
    getSizedParentNode: bi,
    getScale: Ti,
  };
  function zi(t, i, e, n) {
    if ("object" === typeof i) {
      for (let o in i) {
        ki(t, o, i[o], e);
      }
    } else {
      for (let s = 0, r = (i = l(i)).length; s < r; s++) {
        ki(t, i[s], e, n);
      }
    }
    return this;
  }
  let Ci = "_leaflet_events";
  function Si(t, i, e, n) {
    if ("object" === typeof i) {
      for (let o in i) {
        Bi(t, o, i[o], e);
      }
    } else if (i) {
      for (let s = 0, r = (i = l(i)).length; s < r; s++) {
        Bi(t, i[s], e, n);
      }
    } else {
      for (let a in t[Ci]) {
        Bi(t, a, t[Ci][a]);
      }
      delete t[Ci];
    }
    return this;
  }
  function Zi() {
    return Lt && !et && !ct;
  }
  let Ei = {
    mouseenter: "mouseover",
    mouseleave: "mouseout",
    wheel: !("onwheel" in window) && "mousewheel",
  };
  function ki(i, t, e, n) {
    let o = t + m(e) + (n ? "_" + m(n) : "");
    if (i[Ci] && i[Ci][o]) {
      return this;
    }
    let s,
      r,
      a,
      h,
      u,
      l,
      c = function (t) {
        return e.call(n || i, t || window.event);
      },
      _ = c;
    function d(t) {
      if (Lt) {
        if (!t.isPrimary) {
          return;
        }
        if ("mouse" === t.pointerType) {
          return;
        }
      } else if (1 < t.touches.length) {
        return;
      }
      let i = Date.now(),
        e = i - (h || i);
      (u = t.touches ? t.touches[0] : t), (l = 0 < e && e <= 250), (h = i);
    }
    function p(t) {
      if (l && !u.cancelBubble) {
        if (Lt) {
          if ("mouse" === t.pointerType) {
            return;
          }
          let i,
            e,
            n = {};
          for (e in u) {
            (i = u[e]), (n[e] = i && i.bind ? i.bind(u) : i);
          }
          u = n;
        }
        (u.type = "dblclick"), (u.button = 0), r(u), (h = null);
      }
    }
    Lt && 0 === t.indexOf("touch")
      ? jt(i, t, c, o)
      : bt && "dblclick" === t && !Zi()
      ? ((r = c),
        (l = !1),
        ((s = i)[Gt + Vt + (a = o)] = d),
        (s[Gt + qt + a] = p),
        (s[Gt + "dblclick" + a] = r),
        s.addEventListener(Vt, d, !!Ct && { passive: !1 }),
        s.addEventListener(qt, p, !!Ct && { passive: !1 }),
        s.addEventListener("dblclick", r, !1))
      : "addEventListener" in i
      ? "touchstart" === t ||
        "touchmove" === t ||
        "wheel" === t ||
        "mousewheel" === t
        ? i.addEventListener(Ei[t] || t, c, !!Ct && { passive: !1 })
        : "mouseenter" === t || "mouseleave" === t
        ? ((c = function (t) {
            (t = t || window.event), Vi(i, t) && _(t);
          }),
          i.addEventListener(Ei[t], c, !1))
        : i.addEventListener(t, _, !1)
      : "attachEvent" in i && i.attachEvent("on" + t, c),
      (i[Ci] = i[Ci] || {}),
      (i[Ci][o] = c);
  }
  function Bi(t, i, e, n) {
    let o,
      s,
      r,
      a,
      h,
      u,
      l,
      c,
      _ = i + m(e) + (n ? "_" + m(n) : ""),
      d = t[Ci] && t[Ci][_];
    if (!d) {
      return this;
    }
    Lt && 0 === i.indexOf("touch")
      ? ((c = (u = t)["_leaflet_" + (l = i) + _]),
        "touchstart" === l
          ? u.removeEventListener(At, c, !1)
          : "touchmove" === l
          ? u.removeEventListener(It, c, !1)
          : "touchend" === l &&
            (u.removeEventListener(Ot, c, !1),
            u.removeEventListener(Rt, c, !1)))
      : bt && "dblclick" === i && !Zi()
      ? ((r = (o = t)[Gt + Vt + (s = _)]),
        (a = o[Gt + qt + s]),
        (h = o[Gt + "dblclick" + s]),
        o.removeEventListener(Vt, r, !!Ct && { passive: !1 }),
        o.removeEventListener(qt, a, !!Ct && { passive: !1 }),
        o.removeEventListener("dblclick", h, !1))
      : "removeEventListener" in t
      ? t.removeEventListener(Ei[i] || i, d, !1)
      : "detachEvent" in t && t.detachEvent("on" + i, d),
      (t[Ci][_] = null);
  }
  function Ai(t) {
    return (
      t.stopPropagation
        ? t.stopPropagation()
        : t.originalEvent
        ? (t.originalEvent._stopped = !0)
        : (t.cancelBubble = !0),
      Ui(t),
      this
    );
  }
  function Ii(t) {
    return ki(t, "wheel", Ai), this;
  }
  function Oi(t) {
    return zi(t, "mousedown touchstart dblclick", Ai), ki(t, "click", Fi), this;
  }
  function Ri(t) {
    return t.preventDefault ? t.preventDefault() : (t.returnValue = !1), this;
  }
  function Ni(t) {
    return Ri(t), Ai(t), this;
  }
  function Di(t, i) {
    if (!i) {
      return new k(t.clientX, t.clientY);
    }
    let e = Ti(i),
      n = e.boundingClientRect;
    return new k(
      (t.clientX - n.left) / e.x - i.clientLeft,
      (t.clientY - n.top) / e.y - i.clientTop
    );
  }
  let ji =
    pt && ut ? 2 * window.devicePixelRatio : lt ? window.devicePixelRatio : 1;
  function Wi(t) {
    return et
      ? t.wheelDeltaY / 2
      : t.deltaY && 0 === t.deltaMode
      ? -t.deltaY / ji
      : t.deltaY && 1 === t.deltaMode
      ? 20 * -t.deltaY
      : t.deltaY && 2 === t.deltaMode
      ? 60 * -t.deltaY
      : t.deltaX || t.deltaZ
      ? 0
      : t.wheelDelta
      ? (t.wheelDeltaY || t.wheelDelta) / 2
      : t.detail && Math.abs(t.detail) < 32765
      ? 20 * -t.detail
      : t.detail
      ? (t.detail / -32765) * 60
      : 0;
  }
  let Hi = {};
  function Fi(t) {
    Hi[t.type] = !0;
  }
  function Ui(t) {
    let i = Hi[t.type];
    return (Hi[t.type] = !1), i;
  }
  function Vi(t, i) {
    let e = i.relatedTarget;
    if (!e) {
      return !0;
    }
    try {
      for (; e && e !== t; ) {
        e = e.parentNode;
      }
    } catch (t) {
      return !1;
    }
    return e !== t;
  }
  let qi = {
      on: zi,
      off: Si,
      stopPropagation: Ai,
      disableScrollPropagation: Ii,
      disableClickPropagation: Oi,
      preventDefault: Ri,
      stop: Ni,
      getMousePosition: Di,
      getWheelDelta: Wi,
      fakeStop: Fi,
      skipped: Ui,
      isExternalTarget: Vi,
      addListener: zi,
      removeListener: Si,
    },
    Gi = E.extend({
      run: function (t, i, e, n) {
        this.stop(),
          (this._el = t),
          (this._inProgress = !0),
          (this._duration = e || 0.25),
          (this._easeOutPower = 1 / Math.max(n || 0.5, 0.2)),
          (this._startPos = yi(t)),
          (this._offset = i.subtract(this._startPos)),
          (this._startTime = +new Date()),
          this.fire("start"),
          this._animate();
      },
      stop: function () {
        this._inProgress && (this._step(!0), this._complete());
      },
      _animate: function () {
        (this._animId = M(this._animate, this)), this._step();
      },
      _step: function (t) {
        let i = new Date() - this._startTime,
          e = 1e3 * this._duration;
        i < e
          ? this._runFrame(this._easeOut(i / e), t)
          : (this._runFrame(1), this._complete());
      },
      _runFrame: function (t, i) {
        let e = this._startPos.add(this._offset.multiplyBy(t));
        i && e._round(), vi(this._el, e), this.fire("step");
      },
      _complete: function () {
        z(this._animId), (this._inProgress = !1), this.fire("end");
      },
      _easeOut: function (t) {
        return 1 - Math.pow(1 - t, this._easeOutPower);
      },
    }),
    Ki = E.extend({
      options: {
        crs: Y,
        center: void 0,
        zoom: void 0,
        minZoom: void 0,
        maxZoom: void 0,
        layers: [],
        maxBounds: void 0,
        renderer: void 0,
        zoomAnimation: !0,
        zoomAnimationThreshold: 4,
        fadeAnimation: !0,
        markerZoomAnimation: !0,
        transform3DLimit: 8388608,
        zoomSnap: 1,
        zoomDelta: 1,
        trackResize: !0,
      },
      initialize: function (t, i) {
        (i = c(this, i)),
          (this._handlers = []),
          (this._layers = {}),
          (this._zoomBoundLayers = {}),
          (this._sizeChanged = !0),
          this._initContainer(t),
          this._initLayout(),
          (this._onResize = p(this._onResize, this)),
          this._initEvents(),
          i.maxBounds && this.setMaxBounds(i.maxBounds),
          void 0 !== i.zoom && (this._zoom = this._limitZoom(i.zoom)),
          i.center &&
            void 0 !== i.zoom &&
            this.setView(j(i.center), i.zoom, { reset: !0 }),
          this.callInitHooks(),
          (this._zoomAnimated = ii && vt && !Tt && this.options.zoomAnimation),
          this._zoomAnimated &&
            (this._createAnimProxy(),
            zi(this._proxy, ei, this._catchTransitionEnd, this)),
          this._addLayers(this.options.layers);
      },
      setView: function (t, i, e) {
        if (
          ((i = void 0 === i ? this._zoom : this._limitZoom(i)),
          (t = this._limitCenter(j(t), i, this.options.maxBounds)),
          (e = e || {}),
          this._stop(),
          this._loaded && !e.reset && !0 !== e) &&
          (void 0 !== e.animate &&
            ((e.zoom = h({ animate: e.animate }, e.zoom)),
            (e.pan = h({ animate: e.animate, duration: e.duration }, e.pan))),
          this._zoom !== i
            ? this._tryAnimatedZoom && this._tryAnimatedZoom(t, i, e.zoom)
            : this._tryAnimatedPan(t, e.pan))
        ) {
          return clearTimeout(this._sizeTimer), this;
        }
        return this._resetView(t, i), this;
      },
      setZoom: function (t, i) {
        return this._loaded
          ? this.setView(this.getCenter(), t, { zoom: i })
          : ((this._zoom = t), this);
      },
      zoomIn: function (t, i) {
        return (
          (t = t || (vt ? this.options.zoomDelta : 1)),
          this.setZoom(this._zoom + t, i)
        );
      },
      zoomOut: function (t, i) {
        return (
          (t = t || (vt ? this.options.zoomDelta : 1)),
          this.setZoom(this._zoom - t, i)
        );
      },
      setZoomAround: function (t, i, e) {
        let n = this.getZoomScale(i),
          o = this.getSize().divideBy(2),
          s = (t instanceof k ? t : this.latLngToContainerPoint(t))
            .subtract(o)
            .multiplyBy(1 - 1 / n),
          r = this.containerPointToLatLng(o.add(s));
        return this.setView(r, i, { zoom: e });
      },
      _getBoundsCenterZoom: function (t, i) {
        (i = i || {}), (t = t.getBounds ? t.getBounds() : N(t));
        let e = A(i.paddingTopLeft || i.padding || [0, 0]),
          n = A(i.paddingBottomRight || i.padding || [0, 0]),
          o = this.getBoundsZoom(t, !1, e.add(n));
        if (
          (o = "number" === typeof i.maxZoom ? Math.min(i.maxZoom, o) : o) ===
          1 / 0
        ) {
          return { center: t.getCenter(), zoom: o };
        }
        let s = n.subtract(e).divideBy(2),
          r = this.project(t.getSouthWest(), o),
          a = this.project(t.getNorthEast(), o);
        return {
          center: this.unproject(r.add(a).divideBy(2).add(s), o),
          zoom: o,
        };
      },
      fitBounds: function (t, i) {
        if (!(t = N(t)).isValid()) {
          throw new Error("Bounds are not valid.");
        }
        let e = this._getBoundsCenterZoom(t, i);
        return this.setView(e.center, e.zoom, i);
      },
      fitWorld: function (t) {
        return this.fitBounds(
          [
            [-90, -180],
            [90, 180],
          ],
          t
        );
      },
      panTo: function (t, i) {
        return this.setView(t, this._zoom, { pan: i });
      },
      panBy: function (t, i) {
        return (
          (i = i || {}),
          (t = A(t).round()).x || t.y
            ? (!0 === i.animate || this.getSize().contains(t)
                ? (this._panAnim ||
                    ((this._panAnim = new Gi()),
                    this._panAnim.on(
                      {
                        step: this._onPanTransitionStep,
                        end: this._onPanTransitionEnd,
                      },
                      this
                    )),
                  i.noMoveStart || this.fire("movestart"),
                  !1 !== i.animate
                    ? (ci(this._mapPane, "leaflet-pan-anim"),
                      (e = this._getMapPanePos().subtract(t).round()),
                      this._panAnim.run(
                        this._mapPane,
                        e,
                        i.duration || 0.25,
                        i.easeLinearity
                      ))
                    : (this._rawPanBy(t), this.fire("move").fire("moveend")))
                : this._resetView(
                    this.unproject(this.project(this.getCenter()).add(t)),
                    this.getZoom()
                  ),
              this)
            : this.fire("moveend")
        );
        let e;
      },
      flyTo: function (s, r, t) {
        if (!1 === (t = t || {}).animate || !vt) {
          return this.setView(s, r, t);
        }
        this._stop();
        let a = this.project(this.getCenter()),
          h = this.project(s),
          i = this.getSize(),
          u = this._zoom;
        (s = j(s)), (r = void 0 === r ? u : r);
        let l = Math.max(i.x, i.y),
          n = l * this.getZoomScale(u, r),
          c = h.distanceTo(a) || 1,
          _ = 1.42,
          o = _ * _;
        function e(t) {
          let i =
              (n * n - l * l + (t ? -1 : 1) * o * o * c * c) /
              (2 * (t ? n : l) * o * c),
            e = Math.sqrt(i * i + 1) - i;
          return e < 1e-9 ? -18 : Math.log(e);
        }
        function d(t) {
          return (Math.exp(t) - Math.exp(-t)) / 2;
        }
        function p(t) {
          return (Math.exp(t) + Math.exp(-t)) / 2;
        }
        let m = e(0);
        function f(t) {
          return (l * (p(m) * (d((i = m + _ * t)) / p(i)) - d(m))) / o;
          let i;
        }
        let g = Date.now(),
          v = (e(1) - m) / _,
          y = t.duration ? 1e3 * t.duration : 1e3 * v * 0.8;
        return (
          this._moveStart(!0, t.noMoveStart),
          function t() {
            let i,
              e,
              n = (Date.now() - g) / y,
              o = ((i = n), (1 - Math.pow(1 - i, 1.5)) * v);
            n <= 1
              ? ((this._flyToFrame = M(t, this)),
                this._move(
                  this.unproject(a.add(h.subtract(a).multiplyBy(f(o) / c)), u),
                  this.getScaleZoom(
                    l / ((e = o), l * (p(m) / p(m + _ * e))),
                    u
                  ),
                  { flyTo: !0 }
                ))
              : this._move(s, r)._moveEnd(!0);
          }.call(this),
          this
        );
      },
      flyToBounds: function (t, i) {
        let e = this._getBoundsCenterZoom(t, i);
        return this.flyTo(e.center, e.zoom, i);
      },
      setMaxBounds: function (t) {
        return (t = N(t)).isValid()
          ? (this.options.maxBounds &&
              this.off("moveend", this._panInsideMaxBounds),
            (this.options.maxBounds = t),
            this._loaded && this._panInsideMaxBounds(),
            this.on("moveend", this._panInsideMaxBounds))
          : ((this.options.maxBounds = null),
            this.off("moveend", this._panInsideMaxBounds));
      },
      setMinZoom: function (t) {
        let i = this.options.minZoom;
        return (
          (this.options.minZoom = t),
          this._loaded &&
          i !== t &&
          (this.fire("zoomlevelschange"), this.getZoom() < this.options.minZoom)
            ? this.setZoom(t)
            : this
        );
      },
      setMaxZoom: function (t) {
        let i = this.options.maxZoom;
        return (
          (this.options.maxZoom = t),
          this._loaded &&
          i !== t &&
          (this.fire("zoomlevelschange"), this.getZoom() > this.options.maxZoom)
            ? this.setZoom(t)
            : this
        );
      },
      panInsideBounds: function (t, i) {
        this._enforcingBounds = !0;
        let e = this.getCenter(),
          n = this._limitCenter(e, this._zoom, N(t));
        return (
          e.equals(n) || this.panTo(n, i), (this._enforcingBounds = !1), this
        );
      },
      panInside: function (t, i) {
        let e,
          n,
          o = A((i = i || {}).paddingTopLeft || i.padding || [0, 0]),
          s = A(i.paddingBottomRight || i.padding || [0, 0]),
          r = this.getCenter(),
          a = this.project(r),
          h = this.project(t),
          u = this.getPixelBounds(),
          l = u.getSize().divideBy(2),
          c = O([u.min.add(o), u.max.subtract(s)]);
        return (
          c.contains(h) ||
            ((this._enforcingBounds = !0),
            (e = a.subtract(h)),
            (n = A(h.x + e.x, h.y + e.y)),
            (h.x < c.min.x || h.x > c.max.x) &&
              ((n.x = a.x - e.x),
              0 < e.x ? (n.x += l.x - o.x) : (n.x -= l.x - s.x)),
            (h.y < c.min.y || h.y > c.max.y) &&
              ((n.y = a.y - e.y),
              0 < e.y ? (n.y += l.y - o.y) : (n.y -= l.y - s.y)),
            this.panTo(this.unproject(n), i),
            (this._enforcingBounds = !1)),
          this
        );
      },
      invalidateSize: function (t) {
        if (!this._loaded) {
          return this;
        }
        t = h({ animate: !1, pan: !0 }, !0 === t ? { animate: !0 } : t);
        let i = this.getSize();
        (this._sizeChanged = !0), (this._lastCenter = null);
        let e = this.getSize(),
          n = i.divideBy(2).round(),
          o = e.divideBy(2).round(),
          s = n.subtract(o);
        return s.x || s.y
          ? (t.animate && t.pan
              ? this.panBy(s)
              : (t.pan && this._rawPanBy(s),
                this.fire("move"),
                t.debounceMoveend
                  ? (clearTimeout(this._sizeTimer),
                    (this._sizeTimer = setTimeout(
                      p(this.fire, this, "moveend"),
                      200
                    )))
                  : this.fire("moveend")),
            this.fire("resize", { oldSize: i, newSize: e }))
          : this;
      },
      stop: function () {
        return (
          this.setZoom(this._limitZoom(this._zoom)),
          this.options.zoomSnap || this.fire("viewreset"),
          this._stop()
        );
      },
      locate: function (t) {
        if (
          ((t = this._locateOptions = h({ timeout: 1e4, watch: !1 }, t)),
          !("geolocation" in navigator))
        ) {
          return (
            this._handleGeolocationError({
              code: 0,
              message: "Geolocation not supported.",
            }),
            this
          );
        }
        let i = p(this._handleGeolocationResponse, this),
          e = p(this._handleGeolocationError, this);
        return (
          t.watch
            ? (this._locationWatchId = navigator.geolocation.watchPosition(
                i,
                e,
                t
              ))
            : navigator.geolocation.getCurrentPosition(i, e, t),
          this
        );
      },
      stopLocate: function () {
        return (
          navigator.geolocation &&
            navigator.geolocation.clearWatch &&
            navigator.geolocation.clearWatch(this._locationWatchId),
          this._locateOptions && (this._locateOptions.setView = !1),
          this
        );
      },
      _handleGeolocationError: function (t) {
        let i = t.code,
          e =
            t.message ||
            (1 === i
              ? "permission denied"
              : 2 === i
              ? "position unavailable"
              : "timeout");
        this._locateOptions.setView && !this._loaded && this.fitWorld(),
          this.fire("locationerror", {
            code: i,
            message: "Geolocation error: " + e + ".",
          });
      },
      _handleGeolocationResponse: function (t) {
        let i,
          e = new D(t.coords.latitude, t.coords.longitude),
          n = e.toBounds(2 * t.coords.accuracy),
          o = this._locateOptions;
        o.setView &&
          ((i = this.getBoundsZoom(n)),
          this.setView(e, o.maxZoom ? Math.min(i, o.maxZoom) : i));
        let s = { latlng: e, bounds: n, timestamp: t.timestamp };
        for (let r in t.coords) {
          "number" === typeof t.coords[r] && (s[r] = t.coords[r]);
        }
        this.fire("locationfound", s);
      },
      addHandler: function (t, i) {
        if (!i) {
          return this;
        }
        let e = (this[t] = new i(this));
        return this._handlers.push(e), this.options[t] && e.enable(), this;
      },
      remove: function () {
        if (
          (this._initEvents(!0),
          this.off("moveend", this._panInsideMaxBounds),
          this._containerId !== this._container._leaflet_id)
        ) {
          throw new Error("Map container is being reused by another instance");
        }
        try {
          delete this._container._leaflet_id, delete this._containerId;
        } catch (t) {
          (this._container._leaflet_id = void 0), (this._containerId = void 0);
        }
        let t;
        for (t in (void 0 !== this._locationWatchId && this.stopLocate(),
        this._stop(),
        ri(this._mapPane),
        this._clearControlPos && this._clearControlPos(),
        this._resizeRequest &&
          (z(this._resizeRequest), (this._resizeRequest = null)),
        this._clearHandlers(),
        this._loaded && this.fire("unload"),
        this._layers)) {
          this._layers[t].remove();
        }
        for (t in this._panes) {
          ri(this._panes[t]);
        }
        return (
          (this._layers = []),
          (this._panes = []),
          delete this._mapPane,
          delete this._renderer,
          this
        );
      },
      createPane: function (t, i) {
        let e = si(
          "div",
          "leaflet-pane" +
            (t ? " leaflet-" + t.replace("Pane", "") + "-pane" : ""),
          i || this._mapPane
        );
        return t && (this._panes[t] = e), e;
      },
      getCenter: function () {
        return (
          this._checkIfLoaded(),
          this._lastCenter && !this._moved()
            ? this._lastCenter
            : this.layerPointToLatLng(this._getCenterLayerPoint())
        );
      },
      getZoom: function () {
        return this._zoom;
      },
      getBounds: function () {
        let t = this.getPixelBounds();
        return new R(
          this.unproject(t.getBottomLeft()),
          this.unproject(t.getTopRight())
        );
      },
      getMinZoom: function () {
        return void 0 === this.options.minZoom
          ? this._layersMinZoom || 0
          : this.options.minZoom;
      },
      getMaxZoom: function () {
        return void 0 === this.options.maxZoom
          ? void 0 === this._layersMaxZoom
            ? 1 / 0
            : this._layersMaxZoom
          : this.options.maxZoom;
      },
      getBoundsZoom: function (t, i, e) {
        (t = N(t)), (e = A(e || [0, 0]));
        var n = this.getZoom() || 0,
          o = this.getMinZoom(),
          s = this.getMaxZoom(),
          r = t.getNorthWest(),
          a = t.getSouthEast(),
          h = this.getSize().subtract(e),
          u = O(this.project(a, n), this.project(r, n)).getSize(),
          l = vt ? this.options.zoomSnap : 1,
          c = h.x / u.x,
          _ = h.y / u.y,
          d = i ? Math.max(c, _) : Math.min(c, _),
          n = this.getScaleZoom(d, n);
        return (
          l &&
            ((n = Math.round(n / (l / 100)) * (l / 100)),
            (n = i ? Math.ceil(n / l) * l : Math.floor(n / l) * l)),
          Math.max(o, Math.min(s, n))
        );
      },
      getSize: function () {
        return (
          (this._size && !this._sizeChanged) ||
            ((this._size = new k(
              this._container.clientWidth || 0,
              this._container.clientHeight || 0
            )),
            (this._sizeChanged = !1)),
          this._size.clone()
        );
      },
      getPixelBounds: function (t, i) {
        let e = this._getTopLeftPoint(t, i);
        return new I(e, e.add(this.getSize()));
      },
      getPixelOrigin: function () {
        return this._checkIfLoaded(), this._pixelOrigin;
      },
      getPixelWorldBounds: function (t) {
        return this.options.crs.getProjectedBounds(
          void 0 === t ? this.getZoom() : t
        );
      },
      getPane: function (t) {
        return "string" === typeof t ? this._panes[t] : t;
      },
      getPanes: function () {
        return this._panes;
      },
      getContainer: function () {
        return this._container;
      },
      getZoomScale: function (t, i) {
        let e = this.options.crs;
        return (i = void 0 === i ? this._zoom : i), e.scale(t) / e.scale(i);
      },
      getScaleZoom: function (t, i) {
        let e = this.options.crs;
        i = void 0 === i ? this._zoom : i;
        let n = e.zoom(t * e.scale(i));
        return isNaN(n) ? 1 / 0 : n;
      },
      project: function (t, i) {
        return (
          (i = void 0 === i ? this._zoom : i),
          this.options.crs.latLngToPoint(j(t), i)
        );
      },
      unproject: function (t, i) {
        return (
          (i = void 0 === i ? this._zoom : i),
          this.options.crs.pointToLatLng(A(t), i)
        );
      },
      layerPointToLatLng: function (t) {
        let i = A(t).add(this.getPixelOrigin());
        return this.unproject(i);
      },
      latLngToLayerPoint: function (t) {
        return this.project(j(t))._round()._subtract(this.getPixelOrigin());
      },
      wrapLatLng: function (t) {
        return this.options.crs.wrapLatLng(j(t));
      },
      wrapLatLngBounds: function (t) {
        return this.options.crs.wrapLatLngBounds(N(t));
      },
      distance: function (t, i) {
        return this.options.crs.distance(j(t), j(i));
      },
      containerPointToLayerPoint: function (t) {
        return A(t).subtract(this._getMapPanePos());
      },
      layerPointToContainerPoint: function (t) {
        return A(t).add(this._getMapPanePos());
      },
      containerPointToLatLng: function (t) {
        let i = this.containerPointToLayerPoint(A(t));
        return this.layerPointToLatLng(i);
      },
      latLngToContainerPoint: function (t) {
        return this.layerPointToContainerPoint(this.latLngToLayerPoint(j(t)));
      },
      mouseEventToContainerPoint: function (t) {
        return Di(t, this._container);
      },
      mouseEventToLayerPoint: function (t) {
        return this.containerPointToLayerPoint(
          this.mouseEventToContainerPoint(t)
        );
      },
      mouseEventToLatLng: function (t) {
        return this.layerPointToLatLng(this.mouseEventToLayerPoint(t));
      },
      _initContainer: function (t) {
        let i = (this._container = ni(t));
        if (!i) {
          throw new Error("Map container not found.");
        }
        if (i._leaflet_id) {
          throw new Error("Map container is already initialized.");
        }
        zi(i, "scroll", this._onScroll, this), (this._containerId = m(i));
      },
      _initLayout: function () {
        let t = this._container;
        (this._fadeAnimated = this.options.fadeAnimation && vt),
          ci(
            t,
            "leaflet-container" +
              (bt ? " leaflet-touch" : "") +
              (zt ? " leaflet-retina" : "") +
              (it ? " leaflet-oldie" : "") +
              (ct ? " leaflet-safari" : "") +
              (this._fadeAnimated ? " leaflet-fade-anim" : "")
          );
        let i = oi(t, "position");
        "absolute" !== i &&
          "relative" !== i &&
          "fixed" !== i &&
          (t.style.position = "relative"),
          this._initPanes(),
          this._initControlPos && this._initControlPos();
      },
      _initPanes: function () {
        let t = (this._panes = {});
        (this._paneRenderers = {}),
          (this._mapPane = this.createPane("mapPane", this._container)),
          vi(this._mapPane, new k(0, 0)),
          this.createPane("tilePane"),
          this.createPane("shadowPane"),
          this.createPane("overlayPane"),
          this.createPane("markerPane"),
          this.createPane("tooltipPane"),
          this.createPane("popupPane"),
          this.options.markerZoomAnimation ||
            (ci(t.markerPane, "leaflet-zoom-hide"),
            ci(t.shadowPane, "leaflet-zoom-hide"));
      },
      _resetView: function (t, i) {
        vi(this._mapPane, new k(0, 0));
        let e = !this._loaded;
        (this._loaded = !0),
          (i = this._limitZoom(i)),
          this.fire("viewprereset");
        let n = this._zoom !== i;
        this._moveStart(n, !1)._move(t, i)._moveEnd(n),
          this.fire("viewreset"),
          e && this.fire("load");
      },
      _moveStart: function (t, i) {
        return t && this.fire("zoomstart"), i || this.fire("movestart"), this;
      },
      _move: function (t, i, e) {
        void 0 === i && (i = this._zoom);
        let n = this._zoom !== i;
        return (
          (this._zoom = i),
          (this._lastCenter = t),
          (this._pixelOrigin = this._getNewPixelOrigin(t)),
          (n || (e && e.pinch)) && this.fire("zoom", e),
          this.fire("move", e)
        );
      },
      _moveEnd: function (t) {
        return t && this.fire("zoomend"), this.fire("moveend");
      },
      _stop: function () {
        return z(this._flyToFrame), this._panAnim && this._panAnim.stop(), this;
      },
      _rawPanBy: function (t) {
        vi(this._mapPane, this._getMapPanePos().subtract(t));
      },
      _getZoomSpan: function () {
        return this.getMaxZoom() - this.getMinZoom();
      },
      _panInsideMaxBounds: function () {
        this._enforcingBounds || this.panInsideBounds(this.options.maxBounds);
      },
      _checkIfLoaded: function () {
        if (!this._loaded) {
          throw new Error("Set map center and zoom first.");
        }
      },
      _initEvents: function (t) {
        this._targets = {};
        let i = t ? Si : zi;
        i(
          (this._targets[m(this._container)] = this)._container,
          "click dblclick mousedown mouseup mouseover mouseout mousemove contextmenu keypress keydown keyup",
          this._handleDOMEvent,
          this
        ),
          this.options.trackResize && i(window, "resize", this._onResize, this),
          vt &&
            this.options.transform3DLimit &&
            (t ? this.off : this.on).call(this, "moveend", this._onMoveEnd);
      },
      _onResize: function () {
        z(this._resizeRequest),
          (this._resizeRequest = M(function () {
            this.invalidateSize({ debounceMoveend: !0 });
          }, this));
      },
      _onScroll: function () {
        (this._container.scrollTop = 0), (this._container.scrollLeft = 0);
      },
      _onMoveEnd: function () {
        let t = this._getMapPanePos();
        Math.max(Math.abs(t.x), Math.abs(t.y)) >=
          this.options.transform3DLimit &&
          this._resetView(this.getCenter(), this.getZoom());
      },
      _findEventTargets: function (t, i) {
        for (
          var e,
            n = [],
            o = "mouseout" === i || "mouseover" === i,
            s = t.target || t.srcElement,
            r = !1;
          s;

        ) {
          if (
            (e = this._targets[m(s)]) &&
            ("click" === i || "preclick" === i) &&
            !t._simulated &&
            this._draggableMoved(e)
          ) {
            r = !0;
            break;
          }
          if (e && e.listens(i, !0)) {
            if (o && !Vi(s, t)) {
              break;
            }
            if ((n.push(e), o)) {
              break;
            }
          }
          if (s === this._container) {
            break;
          }
          s = s.parentNode;
        }
        return n.length || r || o || !Vi(s, t) || (n = [this]), n;
      },
      _handleDOMEvent: function (t) {
        let i;
        this._loaded &&
          !Ui(t) &&
          (("mousedown" !== (i = t.type) &&
            "keypress" !== i &&
            "keyup" !== i &&
            "keydown" !== i) ||
            Pi(t.target || t.srcElement),
          this._fireDOMEvent(t, i));
      },
      _mouseEvents: [
        "click",
        "dblclick",
        "mouseover",
        "mouseout",
        "contextmenu",
      ],
      _fireDOMEvent: function (t, i, e) {
        let n;
        if (
          ("click" === t.type &&
            (((n = h({}, t)).type = "preclick"),
            this._fireDOMEvent(n, n.type, e)),
          !t._stopped &&
            (e = (e || []).concat(this._findEventTargets(t, i))).length)
        ) {
          let o = e[0];
          "contextmenu" === i && o.listens(i, !0) && Ri(t);
          let s,
            r = { originalEvent: t };
          "keypress" !== t.type &&
            "keydown" !== t.type &&
            "keyup" !== t.type &&
            ((s = o.getLatLng && (!o._radius || o._radius <= 10)),
            (r.containerPoint = s
              ? this.latLngToContainerPoint(o.getLatLng())
              : this.mouseEventToContainerPoint(t)),
            (r.layerPoint = this.containerPointToLayerPoint(r.containerPoint)),
            (r.latlng = s
              ? o.getLatLng()
              : this.layerPointToLatLng(r.layerPoint)));
          for (let a = 0; a < e.length; a++) {
            if (
              (e[a].fire(i, r, !0),
              r.originalEvent._stopped ||
                (!1 === e[a].options.bubblingMouseEvents &&
                  -1 !== v(this._mouseEvents, i)))
            ) {
              return;
            }
          }
        }
      },
      _draggableMoved: function (t) {
        return (
          ((t = t.dragging && t.dragging.enabled() ? t : this).dragging &&
            t.dragging.moved()) ||
          (this.boxZoom && this.boxZoom.moved())
        );
      },
      _clearHandlers: function () {
        for (let t = 0, i = this._handlers.length; t < i; t++) {
          this._handlers[t].disable();
        }
      },
      whenReady: function (t, i) {
        return (
          this._loaded
            ? t.call(i || this, { target: this })
            : this.on("load", t, i),
          this
        );
      },
      _getMapPanePos: function () {
        return yi(this._mapPane) || new k(0, 0);
      },
      _moved: function () {
        let t = this._getMapPanePos();
        return t && !t.equals([0, 0]);
      },
      _getTopLeftPoint: function (t, i) {
        return (t && void 0 !== i
          ? this._getNewPixelOrigin(t, i)
          : this.getPixelOrigin()
        ).subtract(this._getMapPanePos());
      },
      _getNewPixelOrigin: function (t, i) {
        let e = this.getSize()._divideBy(2);
        return this.project(t, i)
          ._subtract(e)
          ._add(this._getMapPanePos())
          ._round();
      },
      _latLngToNewLayerPoint: function (t, i, e) {
        let n = this._getNewPixelOrigin(e, i);
        return this.project(t, i)._subtract(n);
      },
      _latLngBoundsToNewLayerBounds: function (t, i, e) {
        let n = this._getNewPixelOrigin(e, i);
        return O([
          this.project(t.getSouthWest(), i)._subtract(n),
          this.project(t.getNorthWest(), i)._subtract(n),
          this.project(t.getSouthEast(), i)._subtract(n),
          this.project(t.getNorthEast(), i)._subtract(n),
        ]);
      },
      _getCenterLayerPoint: function () {
        return this.containerPointToLayerPoint(this.getSize()._divideBy(2));
      },
      _getCenterOffset: function (t) {
        return this.latLngToLayerPoint(t).subtract(this._getCenterLayerPoint());
      },
      _limitCenter: function (t, i, e) {
        if (!e) {
          return t;
        }
        let n = this.project(t, i),
          o = this.getSize().divideBy(2),
          s = new I(n.subtract(o), n.add(o)),
          r = this._getBoundsOffset(s, e, i);
        return r.round().equals([0, 0]) ? t : this.unproject(n.add(r), i);
      },
      _limitOffset: function (t, i) {
        if (!i) {
          return t;
        }
        let e = this.getPixelBounds(),
          n = new I(e.min.add(t), e.max.add(t));
        return t.add(this._getBoundsOffset(n, i));
      },
      _getBoundsOffset: function (t, i, e) {
        let n = O(
            this.project(i.getNorthEast(), e),
            this.project(i.getSouthWest(), e)
          ),
          o = n.min.subtract(t.min),
          s = n.max.subtract(t.max);
        return new k(this._rebound(o.x, -s.x), this._rebound(o.y, -s.y));
      },
      _rebound: function (t, i) {
        return 0 < t + i
          ? Math.round(t - i) / 2
          : Math.max(0, Math.ceil(t)) - Math.max(0, Math.floor(i));
      },
      _limitZoom: function (t) {
        let i = this.getMinZoom(),
          e = this.getMaxZoom(),
          n = vt ? this.options.zoomSnap : 1;
        return n && (t = Math.round(t / n) * n), Math.max(i, Math.min(e, t));
      },
      _onPanTransitionStep: function () {
        this.fire("move");
      },
      _onPanTransitionEnd: function () {
        _i(this._mapPane, "leaflet-pan-anim"), this.fire("moveend");
      },
      _tryAnimatedPan: function (t, i) {
        let e = this._getCenterOffset(t)._trunc();
        return (
          !(!0 !== (i && i.animate) && !this.getSize().contains(e)) &&
          (this.panBy(e, i), !0)
        );
      },
      _createAnimProxy: function () {
        let t = (this._proxy = si(
          "div",
          "leaflet-proxy leaflet-zoom-animated"
        ));
        this._panes.mapPane.appendChild(t),
          this.on(
            "zoomanim",
            function (t) {
              let i = ti,
                e = this._proxy.style[i];
              gi(
                this._proxy,
                this.project(t.center, t.zoom),
                this.getZoomScale(t.zoom, 1)
              ),
                e === this._proxy.style[i] &&
                  this._animatingZoom &&
                  this._onZoomTransitionEnd();
            },
            this
          ),
          this.on("load moveend", this._animMoveEnd, this),
          this._on("unload", this._destroyAnimProxy, this);
      },
      _destroyAnimProxy: function () {
        ri(this._proxy),
          this.off("load moveend", this._animMoveEnd, this),
          delete this._proxy;
      },
      _animMoveEnd: function () {
        let t = this.getCenter(),
          i = this.getZoom();
        gi(this._proxy, this.project(t, i), this.getZoomScale(i, 1));
      },
      _catchTransitionEnd: function (t) {
        this._animatingZoom &&
          0 <= t.propertyName.indexOf("transform") &&
          this._onZoomTransitionEnd();
      },
      _nothingToAnimate: function () {
        return !this._container.getElementsByClassName("leaflet-zoom-animated")
          .length;
      },
      _tryAnimatedZoom: function (t, i, e) {
        if (this._animatingZoom) {
          return !0;
        }
        if (
          ((e = e || {}),
          !this._zoomAnimated ||
            !1 === e.animate ||
            this._nothingToAnimate() ||
            Math.abs(i - this._zoom) > this.options.zoomAnimationThreshold)
        ) {
          return !1;
        }
        let n = this.getZoomScale(i),
          o = this._getCenterOffset(t)._divideBy(1 - 1 / n);
        return (
          !(!0 !== e.animate && !this.getSize().contains(o)) &&
          (M(function () {
            this._moveStart(!0, !1)._animateZoom(t, i, !0);
          }, this),
          !0)
        );
      },
      _animateZoom: function (t, i, e, n) {
        this._mapPane &&
          (e &&
            ((this._animatingZoom = !0),
            (this._animateToCenter = t),
            (this._animateToZoom = i),
            ci(this._mapPane, "leaflet-zoom-anim")),
          this.fire("zoomanim", { center: t, zoom: i, noUpdate: n }),
          setTimeout(p(this._onZoomTransitionEnd, this), 250));
      },
      _onZoomTransitionEnd: function () {
        this._animatingZoom &&
          (this._mapPane && _i(this._mapPane, "leaflet-zoom-anim"),
          (this._animatingZoom = !1),
          this._move(this._animateToCenter, this._animateToZoom),
          M(function () {
            this._moveEnd(!0);
          }, this));
      },
    });
  function Yi(t) {
    return new Xi(t);
  }
  var Xi = S.extend({
    options: { position: "topright" },
    initialize: function (t) {
      c(this, t);
    },
    getPosition: function () {
      return this.options.position;
    },
    setPosition: function (t) {
      let i = this._map;
      return (
        i && i.removeControl(this),
        (this.options.position = t),
        i && i.addControl(this),
        this
      );
    },
    getContainer: function () {
      return this._container;
    },
    addTo: function (t) {
      this.remove(), (this._map = t);
      let i = (this._container = this.onAdd(t)),
        e = this.getPosition(),
        n = t._controlCorners[e];
      return (
        ci(i, "leaflet-control"),
        -1 !== e.indexOf("bottom")
          ? n.insertBefore(i, n.firstChild)
          : n.appendChild(i),
        this._map.on("unload", this.remove, this),
        this
      );
    },
    remove: function () {
      return (
        this._map &&
          (ri(this._container),
          this.onRemove && this.onRemove(this._map),
          this._map.off("unload", this.remove, this),
          (this._map = null)),
        this
      );
    },
    _refocusOnMap: function (t) {
      this._map &&
        t &&
        0 < t.screenX &&
        0 < t.screenY &&
        this._map.getContainer().focus();
    },
  });
  Ki.include({
    addControl: function (t) {
      return t.addTo(this), this;
    },
    removeControl: function (t) {
      return t.remove(), this;
    },
    _initControlPos: function () {
      let n = (this._controlCorners = {}),
        o = "leaflet-",
        s = (this._controlContainer = si(
          "div",
          o + "control-container",
          this._container
        ));
      function t(t, i) {
        let e = o + t + " " + o + i;
        n[t + i] = si("div", e, s);
      }
      t("top", "left"),
        t("top", "right"),
        t("bottom", "left"),
        t("bottom", "right");
    },
    _clearControlPos: function () {
      for (let t in this._controlCorners) {
        ri(this._controlCorners[t]);
      }
      ri(this._controlContainer),
        delete this._controlCorners,
        delete this._controlContainer;
    },
  });
  let Ji = Xi.extend({
      options: {
        collapsed: !0,
        position: "topright",
        autoZIndex: !0,
        hideSingleBase: !1,
        sortLayers: !1,
        sortFunction: function (t, i, e, n) {
          return e < n ? -1 : n < e ? 1 : 0;
        },
      },
      initialize: function (t, i, e) {
        for (var n in (c(this, e),
        (this._layerControlInputs = []),
        (this._layers = []),
        (this._lastZIndex = 0),
        (this._handlingClick = !1),
        t)) {
          this._addLayer(t[n], n);
        }
        for (n in i) {
          this._addLayer(i[n], n, !0);
        }
      },
      onAdd: function (t) {
        this._initLayout(),
          this._update(),
          (this._map = t).on("zoomend", this._checkDisabledLayers, this);
        for (let i = 0; i < this._layers.length; i++) {
          this._layers[i].layer.on("add remove", this._onLayerChange, this);
        }
        return this._container;
      },
      addTo: function (t) {
        return Xi.prototype.addTo.call(this, t), this._expandIfNotCollapsed();
      },
      onRemove: function () {
        this._map.off("zoomend", this._checkDisabledLayers, this);
        for (let t = 0; t < this._layers.length; t++) {
          this._layers[t].layer.off("add remove", this._onLayerChange, this);
        }
      },
      addBaseLayer: function (t, i) {
        return this._addLayer(t, i), this._map ? this._update() : this;
      },
      addOverlay: function (t, i) {
        return this._addLayer(t, i, !0), this._map ? this._update() : this;
      },
      removeLayer: function (t) {
        t.off("add remove", this._onLayerChange, this);
        let i = this._getLayer(m(t));
        return (
          i && this._layers.splice(this._layers.indexOf(i), 1),
          this._map ? this._update() : this
        );
      },
      expand: function () {
        ci(this._container, "leaflet-control-layers-expanded"),
          (this._section.style.height = null);
        let t = this._map.getSize().y - (this._container.offsetTop + 50);
        return (
          t < this._section.clientHeight
            ? (ci(this._section, "leaflet-control-layers-scrollbar"),
              (this._section.style.height = t + "px"))
            : _i(this._section, "leaflet-control-layers-scrollbar"),
          this._checkDisabledLayers(),
          this
        );
      },
      collapse: function () {
        return _i(this._container, "leaflet-control-layers-expanded"), this;
      },
      _initLayout: function () {
        let t = "leaflet-control-layers",
          i = (this._container = si("div", t)),
          e = this.options.collapsed;
        i.setAttribute("aria-haspopup", !0), Oi(i), Ii(i);
        let n = (this._section = si("section", t + "-list"));
        e &&
          (this._map.on("click", this.collapse, this),
          ot ||
            zi(
              i,
              { mouseenter: this.expand, mouseleave: this.collapse },
              this
            ));
        let o = (this._layersLink = si("a", t + "-toggle", i));
        (o.href = "#"),
          (o.title = "Layers"),
          bt
            ? (zi(o, "click", Ni), zi(o, "click", this.expand, this))
            : zi(o, "focus", this.expand, this),
          e || this.expand(),
          (this._baseLayersList = si("div", t + "-base", n)),
          (this._separator = si("div", t + "-separator", n)),
          (this._overlaysList = si("div", t + "-overlays", n)),
          i.appendChild(n);
      },
      _getLayer: function (t) {
        for (let i = 0; i < this._layers.length; i++) {
          if (this._layers[i] && m(this._layers[i].layer) === t) {
            return this._layers[i];
          }
        }
      },
      _addLayer: function (t, i, e) {
        this._map && t.on("add remove", this._onLayerChange, this),
          this._layers.push({ layer: t, name: i, overlay: e }),
          this.options.sortLayers &&
            this._layers.sort(
              p(function (t, i) {
                return this.options.sortFunction(
                  t.layer,
                  i.layer,
                  t.name,
                  i.name
                );
              }, this)
            ),
          this.options.autoZIndex &&
            t.setZIndex &&
            (this._lastZIndex++, t.setZIndex(this._lastZIndex)),
          this._expandIfNotCollapsed();
      },
      _update: function () {
        if (!this._container) {
          return this;
        }
        ai(this._baseLayersList),
          ai(this._overlaysList),
          (this._layerControlInputs = []);
        for (var t, i, e, n = 0, o = 0; o < this._layers.length; o++) {
          (e = this._layers[o]),
            this._addItem(e),
            (i = i || e.overlay),
            (t = t || !e.overlay),
            (n += e.overlay ? 0 : 1);
        }
        return (
          this.options.hideSingleBase &&
            ((t = t && 1 < n),
            (this._baseLayersList.style.display = t ? "" : "none")),
          (this._separator.style.display = i && t ? "" : "none"),
          this
        );
      },
      _onLayerChange: function (t) {
        this._handlingClick || this._update();
        let i = this._getLayer(m(t.target)),
          e = i.overlay
            ? "add" === t.type
              ? "overlayadd"
              : "overlayremove"
            : "add" === t.type
            ? "baselayerchange"
            : null;
        e && this._map.fire(e, i);
      },
      _createRadioElement: function (t, i) {
        let e =
            '<input type="radio" class="leaflet-control-layers-selector" name="' +
            t +
            '"' +
            (i ? ' checked="checked"' : "") +
            "/>",
          n = document.createElement("div");
        return (n.innerHTML = e), n.firstChild;
      },
      _addItem: function (t) {
        let i,
          e = document.createElement("label"),
          n = this._map.hasLayer(t.layer);
        t.overlay
          ? (((i = document.createElement("input")).type = "checkbox"),
            (i.className = "leaflet-control-layers-selector"),
            (i.defaultChecked = n))
          : (i = this._createRadioElement("leaflet-base-layers_" + m(this), n)),
          this._layerControlInputs.push(i),
          (i.layerId = m(t.layer)),
          zi(i, "click", this._onInputClick, this);
        let o = document.createElement("span");
        o.innerHTML = " " + t.name;
        let s = document.createElement("div");
        return (
          e.appendChild(s),
          s.appendChild(i),
          s.appendChild(o),
          (t.overlay ? this._overlaysList : this._baseLayersList).appendChild(
            e
          ),
          this._checkDisabledLayers(),
          e
        );
      },
      _onInputClick: function () {
        let t,
          i,
          e = this._layerControlInputs,
          n = [],
          o = [];
        this._handlingClick = !0;
        for (var s = e.length - 1; 0 <= s; s--) {
          (t = e[s]),
            (i = this._getLayer(t.layerId).layer),
            t.checked ? n.push(i) : t.checked || o.push(i);
        }
        for (s = 0; s < o.length; s++) {
          this._map.hasLayer(o[s]) && this._map.removeLayer(o[s]);
        }
        for (s = 0; s < n.length; s++) {
          this._map.hasLayer(n[s]) || this._map.addLayer(n[s]);
        }
        (this._handlingClick = !1), this._refocusOnMap();
      },
      _checkDisabledLayers: function () {
        for (
          var t,
            i,
            e = this._layerControlInputs,
            n = this._map.getZoom(),
            o = e.length - 1;
          0 <= o;
          o--
        ) {
          (t = e[o]),
            (i = this._getLayer(t.layerId).layer),
            (t.disabled =
              (void 0 !== i.options.minZoom && n < i.options.minZoom) ||
              (void 0 !== i.options.maxZoom && n > i.options.maxZoom));
        }
      },
      _expandIfNotCollapsed: function () {
        return this._map && !this.options.collapsed && this.expand(), this;
      },
      _expand: function () {
        return this.expand();
      },
      _collapse: function () {
        return this.collapse();
      },
    }),
    $i = Xi.extend({
      options: {
        position: "topleft",
        zoomInText: "+",
        zoomInTitle: "Zoom in",
        zoomOutText: "&#x2212;",
        zoomOutTitle: "Zoom out",
      },
      onAdd: function (t) {
        let i = "leaflet-control-zoom",
          e = si("div", i + " leaflet-bar"),
          n = this.options;
        return (
          (this._zoomInButton = this._createButton(
            n.zoomInText,
            n.zoomInTitle,
            i + "-in",
            e,
            this._zoomIn
          )),
          (this._zoomOutButton = this._createButton(
            n.zoomOutText,
            n.zoomOutTitle,
            i + "-out",
            e,
            this._zoomOut
          )),
          this._updateDisabled(),
          t.on("zoomend zoomlevelschange", this._updateDisabled, this),
          e
        );
      },
      onRemove: function (t) {
        t.off("zoomend zoomlevelschange", this._updateDisabled, this);
      },
      disable: function () {
        return (this._disabled = !0), this._updateDisabled(), this;
      },
      enable: function () {
        return (this._disabled = !1), this._updateDisabled(), this;
      },
      _zoomIn: function (t) {
        !this._disabled &&
          this._map._zoom < this._map.getMaxZoom() &&
          this._map.zoomIn(this._map.options.zoomDelta * (t.shiftKey ? 3 : 1));
      },
      _zoomOut: function (t) {
        !this._disabled &&
          this._map._zoom > this._map.getMinZoom() &&
          this._map.zoomOut(this._map.options.zoomDelta * (t.shiftKey ? 3 : 1));
      },
      _createButton: function (t, i, e, n, o) {
        let s = si("a", e, n);
        return (
          (s.innerHTML = t),
          (s.href = "#"),
          (s.title = i),
          s.setAttribute("role", "button"),
          s.setAttribute("aria-label", i),
          Oi(s),
          zi(s, "click", Ni),
          zi(s, "click", o, this),
          zi(s, "click", this._refocusOnMap, this),
          s
        );
      },
      _updateDisabled: function () {
        let t = this._map,
          i = "leaflet-disabled";
        _i(this._zoomInButton, i),
          _i(this._zoomOutButton, i),
          (!this._disabled && t._zoom !== t.getMinZoom()) ||
            ci(this._zoomOutButton, i),
          (!this._disabled && t._zoom !== t.getMaxZoom()) ||
            ci(this._zoomInButton, i);
      },
    });
  Ki.mergeOptions({ zoomControl: !0 }),
    Ki.addInitHook(function () {
      this.options.zoomControl &&
        ((this.zoomControl = new $i()), this.addControl(this.zoomControl));
    });
  let Qi = Xi.extend({
      options: {
        position: "bottomleft",
        maxWidth: 100,
        metric: !0,
        imperial: !0,
      },
      onAdd: function (t) {
        let i = "leaflet-control-scale",
          e = si("div", i),
          n = this.options;
        return (
          this._addScales(n, i + "-line", e),
          t.on(n.updateWhenIdle ? "moveend" : "move", this._update, this),
          t.whenReady(this._update, this),
          e
        );
      },
      onRemove: function (t) {
        t.off(
          this.options.updateWhenIdle ? "moveend" : "move",
          this._update,
          this
        );
      },
      _addScales: function (t, i, e) {
        t.metric && (this._mScale = si("div", i, e)),
          t.imperial && (this._iScale = si("div", i, e));
      },
      _update: function () {
        let t = this._map,
          i = t.getSize().y / 2,
          e = t.distance(
            t.containerPointToLatLng([0, i]),
            t.containerPointToLatLng([this.options.maxWidth, i])
          );
        this._updateScales(e);
      },
      _updateScales: function (t) {
        this.options.metric && t && this._updateMetric(t),
          this.options.imperial && t && this._updateImperial(t);
      },
      _updateMetric: function (t) {
        let i = this._getRoundNum(t),
          e = i < 1e3 ? i + " m" : i / 1e3 + " km";
        this._updateScale(this._mScale, e, i / t);
      },
      _updateImperial: function (t) {
        let i,
          e,
          n,
          o = 3.2808399 * t;
        5280 < o
          ? ((i = o / 5280),
            (e = this._getRoundNum(i)),
            this._updateScale(this._iScale, e + " mi", e / i))
          : ((n = this._getRoundNum(o)),
            this._updateScale(this._iScale, n + " ft", n / o));
      },
      _updateScale: function (t, i, e) {
        (t.style.width = Math.round(this.options.maxWidth * e) + "px"),
          (t.innerHTML = i);
      },
      _getRoundNum: function (t) {
        let i = Math.pow(10, (Math.floor(t) + "").length - 1),
          e = t / i;
        return (
          i * (e = 10 <= e ? 10 : 5 <= e ? 5 : 3 <= e ? 3 : 2 <= e ? 2 : 1)
        );
      },
    }),
    te = Xi.extend({
      options: {
        position: "bottomright",
        prefix:
          '<a href="https://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>',
      },
      initialize: function (t) {
        c(this, t), (this._attributions = {});
      },
      onAdd: function (t) {
        for (let i in (((t.attributionControl = this)._container = si(
          "div",
          "leaflet-control-attribution"
        )),
        Oi(this._container),
        t._layers)) {
          t._layers[i].getAttribution &&
            this.addAttribution(t._layers[i].getAttribution());
        }
        return this._update(), this._container;
      },
      setPrefix: function (t) {
        return (this.options.prefix = t), this._update(), this;
      },
      addAttribution: function (t) {
        return (
          t &&
            (this._attributions[t] || (this._attributions[t] = 0),
            this._attributions[t]++,
            this._update()),
          this
        );
      },
      removeAttribution: function (t) {
        return (
          t &&
            this._attributions[t] &&
            (this._attributions[t]--, this._update()),
          this
        );
      },
      _update: function () {
        if (this._map) {
          let t = [];
          for (let i in this._attributions) {
            this._attributions[i] && t.push(i);
          }
          let e = [];
          this.options.prefix && e.push(this.options.prefix),
            t.length && e.push(t.join(", ")),
            (this._container.innerHTML = e.join(" | "));
        }
      },
    });
  Ki.mergeOptions({ attributionControl: !0 }),
    Ki.addInitHook(function () {
      this.options.attributionControl && new te().addTo(this);
    });
  (Xi.Layers = Ji),
    (Xi.Zoom = $i),
    (Xi.Scale = Qi),
    (Xi.Attribution = te),
    (Yi.layers = function (t, i, e) {
      return new Ji(t, i, e);
    }),
    (Yi.zoom = function (t) {
      return new $i(t);
    }),
    (Yi.scale = function (t) {
      return new Qi(t);
    }),
    (Yi.attribution = function (t) {
      return new te(t);
    });
  let ie = S.extend({
    initialize: function (t) {
      this._map = t;
    },
    enable: function () {
      return this._enabled || ((this._enabled = !0), this.addHooks()), this;
    },
    disable: function () {
      return this._enabled && ((this._enabled = !1), this.removeHooks()), this;
    },
    enabled: function () {
      return !!this._enabled;
    },
  });
  ie.addTo = function (t, i) {
    return t.addHandler(i, this), this;
  };
  var ee,
    ne = { Events: Z },
    oe = bt ? "touchstart mousedown" : "mousedown",
    se = {
      mousedown: "mouseup",
      touchstart: "touchend",
      pointerdown: "touchend",
      MSPointerDown: "touchend",
    },
    re = {
      mousedown: "mousemove",
      touchstart: "touchmove",
      pointerdown: "touchmove",
      MSPointerDown: "touchmove",
    },
    ae = E.extend({
      options: { clickTolerance: 3 },
      initialize: function (t, i, e, n) {
        c(this, n),
          (this._element = t),
          (this._dragStartTarget = i || t),
          (this._preventOutline = e);
      },
      enable: function () {
        this._enabled ||
          (zi(this._dragStartTarget, oe, this._onDown, this),
          (this._enabled = !0));
      },
      disable: function () {
        this._enabled &&
          (ae._dragging === this && this.finishDrag(),
          Si(this._dragStartTarget, oe, this._onDown, this),
          (this._enabled = !1),
          (this._moved = !1));
      },
      _onDown: function (t) {
        let i, e;
        !t._simulated &&
          this._enabled &&
          ((this._moved = !1),
          li(this._element, "leaflet-zoom-anim") ||
            ae._dragging ||
            t.shiftKey ||
            (1 !== t.which && 1 !== t.button && !t.touches) ||
            ((ae._dragging = this)._preventOutline && Pi(this._element),
            xi(),
            Xt(),
            this._moving ||
              (this.fire("down"),
              (i = t.touches ? t.touches[0] : t),
              (e = bi(this._element)),
              (this._startPoint = new k(i.clientX, i.clientY)),
              (this._parentScale = Ti(e)),
              zi(document, re[t.type], this._onMove, this),
              zi(document, se[t.type], this._onUp, this))));
      },
      _onMove: function (t) {
        let i, e;
        !t._simulated &&
          this._enabled &&
          (t.touches && 1 < t.touches.length
            ? (this._moved = !0)
            : ((e = new k(
                (i =
                  t.touches && 1 === t.touches.length
                    ? t.touches[0]
                    : t).clientX,
                i.clientY
              )._subtract(this._startPoint)).x ||
                e.y) &&
              (Math.abs(e.x) + Math.abs(e.y) < this.options.clickTolerance ||
                ((e.x /= this._parentScale.x),
                (e.y /= this._parentScale.y),
                Ri(t),
                this._moved ||
                  (this.fire("dragstart"),
                  (this._moved = !0),
                  (this._startPos = yi(this._element).subtract(e)),
                  ci(document.body, "leaflet-dragging"),
                  (this._lastTarget = t.target || t.srcElement),
                  window.SVGElementInstance &&
                    this._lastTarget instanceof window.SVGElementInstance &&
                    (this._lastTarget = this._lastTarget.correspondingUseElement),
                  ci(this._lastTarget, "leaflet-drag-target")),
                (this._newPos = this._startPos.add(e)),
                (this._moving = !0),
                z(this._animRequest),
                (this._lastEvent = t),
                (this._animRequest = M(this._updatePosition, this, !0)))));
      },
      _updatePosition: function () {
        let t = { originalEvent: this._lastEvent };
        this.fire("predrag", t),
          vi(this._element, this._newPos),
          this.fire("drag", t);
      },
      _onUp: function (t) {
        !t._simulated && this._enabled && this.finishDrag();
      },
      finishDrag: function () {
        for (let t in (_i(document.body, "leaflet-dragging"),
        this._lastTarget &&
          (_i(this._lastTarget, "leaflet-drag-target"),
          (this._lastTarget = null)),
        re)) {
          Si(document, re[t], this._onMove, this),
            Si(document, se[t], this._onUp, this);
        }
        wi(),
          Jt(),
          this._moved &&
            this._moving &&
            (z(this._animRequest),
            this.fire("dragend", {
              distance: this._newPos.distanceTo(this._startPos),
            })),
          (this._moving = !1),
          (ae._dragging = !1);
      },
    });
  function he(t, i) {
    if (!i || !t.length) {
      return t.slice();
    }
    let e = i * i;
    return (t = (function (t, i) {
      let e = t.length,
        n = new (typeof Uint8Array !== void 0 + "" ? Uint8Array : Array)(e);
      (n[0] = n[e - 1] = 1),
        (function t(i, e, n, o, s) {
          let r,
            a,
            h,
            u = 0;
          for (a = o + 1; a <= s - 1; a++) {
            (h = de(i[a], i[o], i[s], !0)), u < h && ((r = a), (u = h));
          }
          n < u && ((e[r] = 1), t(i, e, n, o, r), t(i, e, n, r, s));
        })(t, n, i, 0, e - 1);
      let o,
        s = [];
      for (o = 0; o < e; o++) {
        n[o] && s.push(t[o]);
      }
      return s;
    })(
      (t = (function (t, i) {
        for (var e = [t[0]], n = 1, o = 0, s = t.length; n < s; n++) {
          (function (t, i) {
            let e = i.x - t.x,
              n = i.y - t.y;
            return e * e + n * n;
          })(t[n], t[o]) > i && (e.push(t[n]), (o = n));
        }
        o < s - 1 && e.push(t[s - 1]);
        return e;
      })(t, e)),
      e
    ));
  }
  function ue(t, i, e) {
    return Math.sqrt(de(t, i, e, !0));
  }
  function le(t, i, e, n, o) {
    let s,
      r,
      a,
      h = n ? ee : _e(t, e),
      u = _e(i, e);
    for (ee = u; ; ) {
      if (!(h | u)) {
        return [t, i];
      }
      if (h & u) {
        return !1;
      }
      (a = _e((r = ce(t, i, (s = h || u), e, o)), e)),
        s === h ? ((t = r), (h = a)) : ((i = r), (u = a));
    }
  }
  function ce(t, i, e, n, o) {
    let s,
      r,
      a = i.x - t.x,
      h = i.y - t.y,
      u = n.min,
      l = n.max;
    return (
      8 & e
        ? ((s = t.x + (a * (l.y - t.y)) / h), (r = l.y))
        : 4 & e
        ? ((s = t.x + (a * (u.y - t.y)) / h), (r = u.y))
        : 2 & e
        ? ((s = l.x), (r = t.y + (h * (l.x - t.x)) / a))
        : 1 & e && ((s = u.x), (r = t.y + (h * (u.x - t.x)) / a)),
      new k(s, r, o)
    );
  }
  function _e(t, i) {
    let e = 0;
    return (
      t.x < i.min.x ? (e |= 1) : t.x > i.max.x && (e |= 2),
      t.y < i.min.y ? (e |= 4) : t.y > i.max.y && (e |= 8),
      e
    );
  }
  function de(t, i, e, n) {
    let o,
      s = i.x,
      r = i.y,
      a = e.x - s,
      h = e.y - r,
      u = a * a + h * h;
    return (
      0 < u &&
        (1 < (o = ((t.x - s) * a + (t.y - r) * h) / u)
          ? ((s = e.x), (r = e.y))
          : 0 < o && ((s += a * o), (r += h * o))),
      (a = t.x - s),
      (h = t.y - r),
      n ? a * a + h * h : new k(s, r)
    );
  }
  function pe(t) {
    return !g(t[0]) || ("object" !== typeof t[0][0] && void 0 !== t[0][0]);
  }
  function me(t) {
    return (
      console.warn(
        "Deprecated use of _flat, please use L.LineUtil.isFlat instead."
      ),
      pe(t)
    );
  }
  let fe = {
    simplify: he,
    pointToSegmentDistance: ue,
    closestPointOnSegment: function (t, i, e) {
      return de(t, i, e);
    },
    clipSegment: le,
    _getEdgeIntersection: ce,
    _getBitCode: _e,
    _sqClosestPointOnSegment: de,
    isFlat: pe,
    _flat: me,
  };
  function ge(t, i, e) {
    for (
      var n, o, s, r, a, h, u, l = [1, 4, 2, 8], c = 0, _ = t.length;
      c < _;
      c++
    ) {
      t[c]._code = _e(t[c], i);
    }
    for (s = 0; s < 4; s++) {
      for (h = l[s], n = [], c = 0, o = (_ = t.length) - 1; c < _; o = c++) {
        (r = t[c]),
          (a = t[o]),
          r._code & h
            ? a._code & h ||
              (((u = ce(a, r, h, i, e))._code = _e(u, i)), n.push(u))
            : (a._code & h &&
                (((u = ce(a, r, h, i, e))._code = _e(u, i)), n.push(u)),
              n.push(r));
      }
      t = n;
    }
    return t;
  }
  let ve,
    ye = { clipPolygon: ge },
    xe = {
      project: function (t) {
        return new k(t.lng, t.lat);
      },
      unproject: function (t) {
        return new D(t.y, t.x);
      },
      bounds: new I([-180, -90], [180, 90]),
    },
    we = {
      R: 6378137,
      R_MINOR: 6356752.314245179,
      bounds: new I(
        [-20037508.34279, -15496570.73972],
        [20037508.34279, 18764656.23138]
      ),
      project: function (t) {
        var i = Math.PI / 180,
          e = this.R,
          n = t.lat * i,
          o = this.R_MINOR / e,
          s = Math.sqrt(1 - o * o),
          r = s * Math.sin(n),
          a =
            Math.tan(Math.PI / 4 - n / 2) / Math.pow((1 - r) / (1 + r), s / 2),
          n = -e * Math.log(Math.max(a, 1e-10));
        return new k(t.lng * i * e, n);
      },
      unproject: function (t) {
        for (
          var i,
            e = 180 / Math.PI,
            n = this.R,
            o = this.R_MINOR / n,
            s = Math.sqrt(1 - o * o),
            r = Math.exp(-t.y / n),
            a = Math.PI / 2 - 2 * Math.atan(r),
            h = 0,
            u = 0.1;
          h < 15 && 1e-7 < Math.abs(u);
          h++
        ) {
          (i = s * Math.sin(a)),
            (i = Math.pow((1 - i) / (1 + i), s / 2)),
            (a += u = Math.PI / 2 - 2 * Math.atan(r * i) - a);
        }
        return new D(a * e, (t.x * e) / n);
      },
    },
    Pe = { LonLat: xe, Mercator: we, SphericalMercator: V },
    Le = h({}, F, {
      code: "EPSG:3395",
      projection: we,
      transformation: G((ve = 0.5 / (Math.PI * we.R)), 0.5, -ve, 0.5),
    }),
    be = h({}, F, {
      code: "EPSG:4326",
      projection: xe,
      transformation: G(1 / 180, 1, -1 / 180, 0.5),
    }),
    Te = h({}, H, {
      projection: xe,
      transformation: G(1, 0, -1, 0),
      scale: function (t) {
        return Math.pow(2, t);
      },
      zoom: function (t) {
        return Math.log(t) / Math.LN2;
      },
      distance: function (t, i) {
        let e = i.lng - t.lng,
          n = i.lat - t.lat;
        return Math.sqrt(e * e + n * n);
      },
      infinite: !0,
    });
  (H.Earth = F),
    (H.EPSG3395 = Le),
    (H.EPSG3857 = Y),
    (H.EPSG900913 = X),
    (H.EPSG4326 = be),
    (H.Simple = Te);
  let Me = E.extend({
    options: {
      pane: "overlayPane",
      attribution: null,
      bubblingMouseEvents: !0,
    },
    addTo: function (t) {
      return t.addLayer(this), this;
    },
    remove: function () {
      return this.removeFrom(this._map || this._mapToAdd);
    },
    removeFrom: function (t) {
      return t && t.removeLayer(this), this;
    },
    getPane: function (t) {
      return this._map.getPane(t ? this.options[t] || t : this.options.pane);
    },
    addInteractiveTarget: function (t) {
      return (this._map._targets[m(t)] = this);
    },
    removeInteractiveTarget: function (t) {
      return delete this._map._targets[m(t)], this;
    },
    getAttribution: function () {
      return this.options.attribution;
    },
    _layerAdd: function (t) {
      let i,
        e = t.target;
      e.hasLayer(this) &&
        ((this._map = e),
        (this._zoomAnimated = e._zoomAnimated),
        this.getEvents &&
          ((i = this.getEvents()),
          e.on(i, this),
          this.once(
            "remove",
            function () {
              e.off(i, this);
            },
            this
          )),
        this.onAdd(e),
        this.getAttribution &&
          e.attributionControl &&
          e.attributionControl.addAttribution(this.getAttribution()),
        this.fire("add"),
        e.fire("layeradd", { layer: this }));
    },
  });
  Ki.include({
    addLayer: function (t) {
      if (!t._layerAdd) {
        throw new Error("The provided object is not a Layer.");
      }
      let i = m(t);
      return (
        this._layers[i] ||
          (((this._layers[i] = t)._mapToAdd = this),
          t.beforeAdd && t.beforeAdd(this),
          this.whenReady(t._layerAdd, t)),
        this
      );
    },
    removeLayer: function (t) {
      let i = m(t);
      return (
        this._layers[i] &&
          (this._loaded && t.onRemove(this),
          t.getAttribution &&
            this.attributionControl &&
            this.attributionControl.removeAttribution(t.getAttribution()),
          delete this._layers[i],
          this._loaded &&
            (this.fire("layerremove", { layer: t }), t.fire("remove")),
          (t._map = t._mapToAdd = null)),
        this
      );
    },
    hasLayer: function (t) {
      return !!t && m(t) in this._layers;
    },
    eachLayer: function (t, i) {
      for (let e in this._layers) {
        t.call(i, this._layers[e]);
      }
      return this;
    },
    _addLayers: function (t) {
      for (let i = 0, e = (t = t ? (g(t) ? t : [t]) : []).length; i < e; i++) {
        this.addLayer(t[i]);
      }
    },
    _addZoomLimit: function (t) {
      (!isNaN(t.options.maxZoom) && isNaN(t.options.minZoom)) ||
        ((this._zoomBoundLayers[m(t)] = t), this._updateZoomLevels());
    },
    _removeZoomLimit: function (t) {
      let i = m(t);
      this._zoomBoundLayers[i] &&
        (delete this._zoomBoundLayers[i], this._updateZoomLevels());
    },
    _updateZoomLevels: function () {
      var t = 1 / 0,
        i = -1 / 0,
        e = this._getZoomSpan();
      for (let n in this._zoomBoundLayers) {
        var o = this._zoomBoundLayers[n].options,
          t = void 0 === o.minZoom ? t : Math.min(t, o.minZoom),
          i = void 0 === o.maxZoom ? i : Math.max(i, o.maxZoom);
      }
      (this._layersMaxZoom = i === -1 / 0 ? void 0 : i),
        (this._layersMinZoom = t === 1 / 0 ? void 0 : t),
        e !== this._getZoomSpan() && this.fire("zoomlevelschange"),
        void 0 === this.options.maxZoom &&
          this._layersMaxZoom &&
          this.getZoom() > this._layersMaxZoom &&
          this.setZoom(this._layersMaxZoom),
        void 0 === this.options.minZoom &&
          this._layersMinZoom &&
          this.getZoom() < this._layersMinZoom &&
          this.setZoom(this._layersMinZoom);
    },
  });
  let ze = Me.extend({
      initialize: function (t, i) {
        let e, n;
        if ((c(this, i), (this._layers = {}), t)) {
          for (e = 0, n = t.length; e < n; e++) {
            this.addLayer(t[e]);
          }
        }
      },
      addLayer: function (t) {
        let i = this.getLayerId(t);
        return (this._layers[i] = t), this._map && this._map.addLayer(t), this;
      },
      removeLayer: function (t) {
        let i = t in this._layers ? t : this.getLayerId(t);
        return (
          this._map &&
            this._layers[i] &&
            this._map.removeLayer(this._layers[i]),
          delete this._layers[i],
          this
        );
      },
      hasLayer: function (t) {
        return (
          !!t &&
          ("number" === typeof t ? t : this.getLayerId(t)) in this._layers
        );
      },
      clearLayers: function () {
        return this.eachLayer(this.removeLayer, this);
      },
      invoke: function (t) {
        let i,
          e,
          n = Array.prototype.slice.call(arguments, 1);
        for (i in this._layers) {
          (e = this._layers[i])[t] && e[t].apply(e, n);
        }
        return this;
      },
      onAdd: function (t) {
        this.eachLayer(t.addLayer, t);
      },
      onRemove: function (t) {
        this.eachLayer(t.removeLayer, t);
      },
      eachLayer: function (t, i) {
        for (let e in this._layers) {
          t.call(i, this._layers[e]);
        }
        return this;
      },
      getLayer: function (t) {
        return this._layers[t];
      },
      getLayers: function () {
        let t = [];
        return this.eachLayer(t.push, t), t;
      },
      setZIndex: function (t) {
        return this.invoke("setZIndex", t);
      },
      getLayerId: m,
    }),
    Ce = ze.extend({
      addLayer: function (t) {
        return this.hasLayer(t)
          ? this
          : (t.addEventParent(this),
            ze.prototype.addLayer.call(this, t),
            this.fire("layeradd", { layer: t }));
      },
      removeLayer: function (t) {
        return this.hasLayer(t)
          ? (t in this._layers && (t = this._layers[t]),
            t.removeEventParent(this),
            ze.prototype.removeLayer.call(this, t),
            this.fire("layerremove", { layer: t }))
          : this;
      },
      setStyle: function (t) {
        return this.invoke("setStyle", t);
      },
      bringToFront: function () {
        return this.invoke("bringToFront");
      },
      bringToBack: function () {
        return this.invoke("bringToBack");
      },
      getBounds: function () {
        let t = new R();
        for (let i in this._layers) {
          let e = this._layers[i];
          t.extend(e.getBounds ? e.getBounds() : e.getLatLng());
        }
        return t;
      },
    }),
    Se = S.extend({
      options: { popupAnchor: [0, 0], tooltipAnchor: [0, 0] },
      initialize: function (t) {
        c(this, t);
      },
      createIcon: function (t) {
        return this._createIcon("icon", t);
      },
      createShadow: function (t) {
        return this._createIcon("shadow", t);
      },
      _createIcon: function (t, i) {
        let e = this._getIconUrl(t);
        if (!e) {
          if ("icon" === t) {
            throw new Error("iconUrl not set in Icon options (see the docs).");
          }
          return null;
        }
        let n = this._createImg(e, i && "IMG" === i.tagName ? i : null);
        return this._setIconStyles(n, t), n;
      },
      _setIconStyles: function (t, i) {
        let e = this.options,
          n = e[i + "Size"];
        "number" === typeof n && (n = [n, n]);
        let o = A(n),
          s = A(
            ("shadow" === i && e.shadowAnchor) ||
              e.iconAnchor ||
              (o && o.divideBy(2, !0))
          );
        (t.className = "leaflet-marker-" + i + " " + (e.className || "")),
          s &&
            ((t.style.marginLeft = -s.x + "px"),
            (t.style.marginTop = -s.y + "px")),
          o && ((t.style.width = o.x + "px"), (t.style.height = o.y + "px"));
      },
      _createImg: function (t, i) {
        return ((i = i || document.createElement("img")).src = t), i;
      },
      _getIconUrl: function (t) {
        return (zt && this.options[t + "RetinaUrl"]) || this.options[t + "Url"];
      },
    });
  var Ze = Se.extend({
      options: {
        iconUrl: "marker-icon.png",
        iconRetinaUrl: "marker-icon-2x.png",
        shadowUrl: "marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
      },
      _getIconUrl: function (t) {
        return (
          Ze.imagePath || (Ze.imagePath = this._detectIconPath()),
          (this.options.imagePath || Ze.imagePath) +
            Se.prototype._getIconUrl.call(this, t)
        );
      },
      _detectIconPath: function () {
        let t = si("div", "leaflet-default-icon-path", document.body),
          i = oi(t, "background-image") || oi(t, "backgroundImage");
        return (
          document.body.removeChild(t),
          (i =
            null === i || 0 !== i.indexOf("url")
              ? ""
              : i
                  .replace(/^url\(["']?/, "")
                  .replace(/marker-icon\.png["']?\)$/, ""))
        );
      },
    }),
    Ee = ie.extend({
      initialize: function (t) {
        this._marker = t;
      },
      addHooks: function () {
        let t = this._marker._icon;
        this._draggable || (this._draggable = new ae(t, t, !0)),
          this._draggable
            .on(
              {
                dragstart: this._onDragStart,
                predrag: this._onPreDrag,
                drag: this._onDrag,
                dragend: this._onDragEnd,
              },
              this
            )
            .enable(),
          ci(t, "leaflet-marker-draggable");
      },
      removeHooks: function () {
        this._draggable
          .off(
            {
              dragstart: this._onDragStart,
              predrag: this._onPreDrag,
              drag: this._onDrag,
              dragend: this._onDragEnd,
            },
            this
          )
          .disable(),
          this._marker._icon &&
            _i(this._marker._icon, "leaflet-marker-draggable");
      },
      moved: function () {
        return this._draggable && this._draggable._moved;
      },
      _adjustPan: function (t) {
        let i,
          e = this._marker,
          n = e._map,
          o = this._marker.options.autoPanSpeed,
          s = this._marker.options.autoPanPadding,
          r = yi(e._icon),
          a = n.getPixelBounds(),
          h = n.getPixelOrigin(),
          u = O(a.min._subtract(h).add(s), a.max._subtract(h).subtract(s));
        u.contains(r) ||
          ((i = A(
            (Math.max(u.max.x, r.x) - u.max.x) / (a.max.x - u.max.x) -
              (Math.min(u.min.x, r.x) - u.min.x) / (a.min.x - u.min.x),
            (Math.max(u.max.y, r.y) - u.max.y) / (a.max.y - u.max.y) -
              (Math.min(u.min.y, r.y) - u.min.y) / (a.min.y - u.min.y)
          ).multiplyBy(o)),
          n.panBy(i, { animate: !1 }),
          this._draggable._newPos._add(i),
          this._draggable._startPos._add(i),
          vi(e._icon, this._draggable._newPos),
          this._onDrag(t),
          (this._panRequest = M(this._adjustPan.bind(this, t))));
      },
      _onDragStart: function () {
        (this._oldLatLng = this._marker.getLatLng()),
          this._marker.closePopup && this._marker.closePopup(),
          this._marker.fire("movestart").fire("dragstart");
      },
      _onPreDrag: function (t) {
        this._marker.options.autoPan &&
          (z(this._panRequest),
          (this._panRequest = M(this._adjustPan.bind(this, t))));
      },
      _onDrag: function (t) {
        let i = this._marker,
          e = i._shadow,
          n = yi(i._icon),
          o = i._map.layerPointToLatLng(n);
        e && vi(e, n),
          (i._latlng = o),
          (t.latlng = o),
          (t.oldLatLng = this._oldLatLng),
          i.fire("move", t).fire("drag", t);
      },
      _onDragEnd: function (t) {
        z(this._panRequest),
          delete this._oldLatLng,
          this._marker.fire("moveend").fire("dragend", t);
      },
    }),
    ke = Me.extend({
      options: {
        icon: new Ze(),
        interactive: !0,
        keyboard: !0,
        title: "",
        alt: "",
        zIndexOffset: 0,
        opacity: 1,
        riseOnHover: !1,
        riseOffset: 250,
        pane: "markerPane",
        shadowPane: "shadowPane",
        bubblingMouseEvents: !1,
        draggable: !1,
        autoPan: !1,
        autoPanPadding: [50, 50],
        autoPanSpeed: 10,
      },
      initialize: function (t, i) {
        c(this, i), (this._latlng = j(t));
      },
      onAdd: function (t) {
        (this._zoomAnimated =
          this._zoomAnimated && t.options.markerZoomAnimation),
          this._zoomAnimated && t.on("zoomanim", this._animateZoom, this),
          this._initIcon(),
          this.update();
      },
      onRemove: function (t) {
        this.dragging &&
          this.dragging.enabled() &&
          ((this.options.draggable = !0), this.dragging.removeHooks()),
          delete this.dragging,
          this._zoomAnimated && t.off("zoomanim", this._animateZoom, this),
          this._removeIcon(),
          this._removeShadow();
      },
      getEvents: function () {
        return { zoom: this.update, viewreset: this.update };
      },
      getLatLng: function () {
        return this._latlng;
      },
      setLatLng: function (t) {
        let i = this._latlng;
        return (
          (this._latlng = j(t)),
          this.update(),
          this.fire("move", { oldLatLng: i, latlng: this._latlng })
        );
      },
      setZIndexOffset: function (t) {
        return (this.options.zIndexOffset = t), this.update();
      },
      getIcon: function () {
        return this.options.icon;
      },
      setIcon: function (t) {
        return (
          (this.options.icon = t),
          this._map && (this._initIcon(), this.update()),
          this._popup && this.bindPopup(this._popup, this._popup.options),
          this
        );
      },
      getElement: function () {
        return this._icon;
      },
      update: function () {
        let t;
        return (
          this._icon &&
            this._map &&
            ((t = this._map.latLngToLayerPoint(this._latlng).round()),
            this._setPos(t)),
          this
        );
      },
      _initIcon: function () {
        let t = this.options,
          i = "leaflet-zoom-" + (this._zoomAnimated ? "animated" : "hide"),
          e = t.icon.createIcon(this._icon),
          n = !1;
        e !== this._icon &&
          (this._icon && this._removeIcon(),
          (n = !0),
          t.title && (e.title = t.title),
          "IMG" === e.tagName && (e.alt = t.alt || "")),
          ci(e, i),
          t.keyboard && (e.tabIndex = "0"),
          (this._icon = e),
          t.riseOnHover &&
            this.on({
              mouseover: this._bringToFront,
              mouseout: this._resetZIndex,
            });
        let o = t.icon.createShadow(this._shadow),
          s = !1;
        o !== this._shadow && (this._removeShadow(), (s = !0)),
          o && (ci(o, i), (o.alt = "")),
          (this._shadow = o),
          t.opacity < 1 && this._updateOpacity(),
          n && this.getPane().appendChild(this._icon),
          this._initInteraction(),
          o && s && this.getPane(t.shadowPane).appendChild(this._shadow);
      },
      _removeIcon: function () {
        this.options.riseOnHover &&
          this.off({
            mouseover: this._bringToFront,
            mouseout: this._resetZIndex,
          }),
          ri(this._icon),
          this.removeInteractiveTarget(this._icon),
          (this._icon = null);
      },
      _removeShadow: function () {
        this._shadow && ri(this._shadow), (this._shadow = null);
      },
      _setPos: function (t) {
        this._icon && vi(this._icon, t),
          this._shadow && vi(this._shadow, t),
          (this._zIndex = t.y + this.options.zIndexOffset),
          this._resetZIndex();
      },
      _updateZIndex: function (t) {
        this._icon && (this._icon.style.zIndex = this._zIndex + t);
      },
      _animateZoom: function (t) {
        let i = this._map
          ._latLngToNewLayerPoint(this._latlng, t.zoom, t.center)
          .round();
        this._setPos(i);
      },
      _initInteraction: function () {
        let t;
        this.options.interactive &&
          (ci(this._icon, "leaflet-interactive"),
          this.addInteractiveTarget(this._icon),
          Ee &&
            ((t = this.options.draggable),
            this.dragging &&
              ((t = this.dragging.enabled()), this.dragging.disable()),
            (this.dragging = new Ee(this)),
            t && this.dragging.enable()));
      },
      setOpacity: function (t) {
        return (
          (this.options.opacity = t), this._map && this._updateOpacity(), this
        );
      },
      _updateOpacity: function () {
        let t = this.options.opacity;
        this._icon && mi(this._icon, t), this._shadow && mi(this._shadow, t);
      },
      _bringToFront: function () {
        this._updateZIndex(this.options.riseOffset);
      },
      _resetZIndex: function () {
        this._updateZIndex(0);
      },
      _getPopupAnchor: function () {
        return this.options.icon.options.popupAnchor;
      },
      _getTooltipAnchor: function () {
        return this.options.icon.options.tooltipAnchor;
      },
    });
  let Be = Me.extend({
      options: {
        stroke: !0,
        color: "#3388ff",
        weight: 3,
        opacity: 1,
        lineCap: "round",
        lineJoin: "round",
        dashArray: null,
        dashOffset: null,
        fill: !1,
        fillColor: null,
        fillOpacity: 0.2,
        fillRule: "evenodd",
        interactive: !0,
        bubblingMouseEvents: !0,
      },
      beforeAdd: function (t) {
        this._renderer = t.getRenderer(this);
      },
      onAdd: function () {
        this._renderer._initPath(this),
          this._reset(),
          this._renderer._addPath(this);
      },
      onRemove: function () {
        this._renderer._removePath(this);
      },
      redraw: function () {
        return this._map && this._renderer._updatePath(this), this;
      },
      setStyle: function (t) {
        return (
          c(this, t),
          this._renderer &&
            (this._renderer._updateStyle(this),
            this.options.stroke &&
              t &&
              Object.prototype.hasOwnProperty.call(t, "weight") &&
              this._updateBounds()),
          this
        );
      },
      bringToFront: function () {
        return this._renderer && this._renderer._bringToFront(this), this;
      },
      bringToBack: function () {
        return this._renderer && this._renderer._bringToBack(this), this;
      },
      getElement: function () {
        return this._path;
      },
      _reset: function () {
        this._project(), this._update();
      },
      _clickTolerance: function () {
        return (
          (this.options.stroke ? this.options.weight / 2 : 0) +
          this._renderer.options.tolerance
        );
      },
    }),
    Ae = Be.extend({
      options: { fill: !0, radius: 10 },
      initialize: function (t, i) {
        c(this, i), (this._latlng = j(t)), (this._radius = this.options.radius);
      },
      setLatLng: function (t) {
        let i = this._latlng;
        return (
          (this._latlng = j(t)),
          this.redraw(),
          this.fire("move", { oldLatLng: i, latlng: this._latlng })
        );
      },
      getLatLng: function () {
        return this._latlng;
      },
      setRadius: function (t) {
        return (this.options.radius = this._radius = t), this.redraw();
      },
      getRadius: function () {
        return this._radius;
      },
      setStyle: function (t) {
        let i = (t && t.radius) || this._radius;
        return Be.prototype.setStyle.call(this, t), this.setRadius(i), this;
      },
      _project: function () {
        (this._point = this._map.latLngToLayerPoint(this._latlng)),
          this._updateBounds();
      },
      _updateBounds: function () {
        let t = this._radius,
          i = this._radiusY || t,
          e = this._clickTolerance(),
          n = [t + e, i + e];
        this._pxBounds = new I(this._point.subtract(n), this._point.add(n));
      },
      _update: function () {
        this._map && this._updatePath();
      },
      _updatePath: function () {
        this._renderer._updateCircle(this);
      },
      _empty: function () {
        return (
          this._radius && !this._renderer._bounds.intersects(this._pxBounds)
        );
      },
      _containsPoint: function (t) {
        return (
          t.distanceTo(this._point) <= this._radius + this._clickTolerance()
        );
      },
    });
  let Ie = Ae.extend({
    initialize: function (t, i, e) {
      if (
        ("number" === typeof i && (i = h({}, e, { radius: i })),
        c(this, i),
        (this._latlng = j(t)),
        isNaN(this.options.radius))
      ) {
        throw new Error("Circle radius cannot be NaN");
      }
      this._mRadius = this.options.radius;
    },
    setRadius: function (t) {
      return (this._mRadius = t), this.redraw();
    },
    getRadius: function () {
      return this._mRadius;
    },
    getBounds: function () {
      let t = [this._radius, this._radiusY || this._radius];
      return new R(
        this._map.layerPointToLatLng(this._point.subtract(t)),
        this._map.layerPointToLatLng(this._point.add(t))
      );
    },
    setStyle: Be.prototype.setStyle,
    _project: function () {
      let t,
        i,
        e,
        n,
        o,
        s,
        r,
        a,
        h = this._latlng.lng,
        u = this._latlng.lat,
        l = this._map,
        c = l.options.crs;
      c.distance === F.distance
        ? ((t = Math.PI / 180),
          (i = this._mRadius / F.R / t),
          (e = l.project([u + i, h])),
          (n = l.project([u - i, h])),
          (o = e.add(n).divideBy(2)),
          (s = l.unproject(o).lat),
          (r =
            Math.acos(
              (Math.cos(i * t) - Math.sin(u * t) * Math.sin(s * t)) /
                (Math.cos(u * t) * Math.cos(s * t))
            ) / t),
          (!isNaN(r) && 0 !== r) || (r = i / Math.cos((Math.PI / 180) * u)),
          (this._point = o.subtract(l.getPixelOrigin())),
          (this._radius = isNaN(r) ? 0 : o.x - l.project([s, h - r]).x),
          (this._radiusY = o.y - e.y))
        : ((a = c.unproject(
            c.project(this._latlng).subtract([this._mRadius, 0])
          )),
          (this._point = l.latLngToLayerPoint(this._latlng)),
          (this._radius = this._point.x - l.latLngToLayerPoint(a).x)),
        this._updateBounds();
    },
  });
  let Oe = Be.extend({
    options: { smoothFactor: 1, noClip: !1 },
    initialize: function (t, i) {
      c(this, i), this._setLatLngs(t);
    },
    getLatLngs: function () {
      return this._latlngs;
    },
    setLatLngs: function (t) {
      return this._setLatLngs(t), this.redraw();
    },
    isEmpty: function () {
      return !this._latlngs.length;
    },
    closestLayerPoint: function (t) {
      for (
        var i, e, n = 1 / 0, o = null, s = de, r = 0, a = this._parts.length;
        r < a;
        r++
      ) {
        for (let h = this._parts[r], u = 1, l = h.length; u < l; u++) {
          let c = s(t, (i = h[u - 1]), (e = h[u]), !0);
          c < n && ((n = c), (o = s(t, i, e)));
        }
      }
      return o && (o.distance = Math.sqrt(n)), o;
    },
    getCenter: function () {
      if (!this._map) {
        throw new Error("Must add layer to map before using getCenter()");
      }
      let t,
        i,
        e,
        n,
        o,
        s,
        r,
        a = this._rings[0],
        h = a.length;
      if (!h) {
        return null;
      }
      for (i = t = 0; t < h - 1; t++) {
        i += a[t].distanceTo(a[t + 1]) / 2;
      }
      if (0 === i) {
        return this._map.layerPointToLatLng(a[0]);
      }
      for (n = t = 0; t < h - 1; t++) {
        if (((o = a[t]), (s = a[t + 1]), i < (n += e = o.distanceTo(s)))) {
          return (
            (r = (n - i) / e),
            this._map.layerPointToLatLng([
              s.x - r * (s.x - o.x),
              s.y - r * (s.y - o.y),
            ])
          );
        }
      }
    },
    getBounds: function () {
      return this._bounds;
    },
    addLatLng: function (t, i) {
      return (
        (i = i || this._defaultShape()),
        (t = j(t)),
        i.push(t),
        this._bounds.extend(t),
        this.redraw()
      );
    },
    _setLatLngs: function (t) {
      (this._bounds = new R()), (this._latlngs = this._convertLatLngs(t));
    },
    _defaultShape: function () {
      return pe(this._latlngs) ? this._latlngs : this._latlngs[0];
    },
    _convertLatLngs: function (t) {
      for (var i = [], e = pe(t), n = 0, o = t.length; n < o; n++) {
        e
          ? ((i[n] = j(t[n])), this._bounds.extend(i[n]))
          : (i[n] = this._convertLatLngs(t[n]));
      }
      return i;
    },
    _project: function () {
      let t = new I();
      (this._rings = []),
        this._projectLatlngs(this._latlngs, this._rings, t),
        this._bounds.isValid() &&
          t.isValid() &&
          ((this._rawPxBounds = t), this._updateBounds());
    },
    _updateBounds: function () {
      let t = this._clickTolerance(),
        i = new k(t, t);
      this._pxBounds = new I([
        this._rawPxBounds.min.subtract(i),
        this._rawPxBounds.max.add(i),
      ]);
    },
    _projectLatlngs: function (t, i, e) {
      let n,
        o,
        s = t[0] instanceof D,
        r = t.length;
      if (s) {
        for (o = [], n = 0; n < r; n++) {
          (o[n] = this._map.latLngToLayerPoint(t[n])), e.extend(o[n]);
        }
        i.push(o);
      } else {
        for (n = 0; n < r; n++) {
          this._projectLatlngs(t[n], i, e);
        }
      }
    },
    _clipPoints: function () {
      let t = this._renderer._bounds;
      if (
        ((this._parts = []), this._pxBounds && this._pxBounds.intersects(t))
      ) {
        if (this.options.noClip) {
          this._parts = this._rings;
        } else {
          for (
            var i,
              e,
              n,
              o,
              s = this._parts,
              r = 0,
              a = 0,
              h = this._rings.length;
            r < h;
            r++
          ) {
            for (i = 0, e = (o = this._rings[r]).length; i < e - 1; i++) {
              (n = le(o[i], o[i + 1], t, i, !0)) &&
                ((s[a] = s[a] || []),
                s[a].push(n[0]),
                (n[1] === o[i + 1] && i !== e - 2) || (s[a].push(n[1]), a++));
            }
          }
        }
      }
    },
    _simplifyPoints: function () {
      for (
        let t = this._parts, i = this.options.smoothFactor, e = 0, n = t.length;
        e < n;
        e++
      ) {
        t[e] = he(t[e], i);
      }
    },
    _update: function () {
      this._map &&
        (this._clipPoints(), this._simplifyPoints(), this._updatePath());
    },
    _updatePath: function () {
      this._renderer._updatePoly(this);
    },
    _containsPoint: function (t, i) {
      let e,
        n,
        o,
        s,
        r,
        a,
        h = this._clickTolerance();
      if (!this._pxBounds || !this._pxBounds.contains(t)) {
        return !1;
      }
      for (e = 0, s = this._parts.length; e < s; e++) {
        for (n = 0, o = (r = (a = this._parts[e]).length) - 1; n < r; o = n++) {
          if ((i || 0 !== n) && ue(t, a[o], a[n]) <= h) {
            return !0;
          }
        }
      }
      return !1;
    },
  });
  Oe._flat = me;
  let Re = Oe.extend({
    options: { fill: !0 },
    isEmpty: function () {
      return !this._latlngs.length || !this._latlngs[0].length;
    },
    getCenter: function () {
      if (!this._map) {
        throw new Error("Must add layer to map before using getCenter()");
      }
      let t,
        i,
        e,
        n,
        o,
        s,
        r,
        a,
        h,
        u = this._rings[0],
        l = u.length;
      if (!l) {
        return null;
      }
      for (t = s = r = a = 0, i = l - 1; t < l; i = t++) {
        (e = u[t]),
          (n = u[i]),
          (o = e.y * n.x - n.y * e.x),
          (r += (e.x + n.x) * o),
          (a += (e.y + n.y) * o),
          (s += 3 * o);
      }
      return (
        (h = 0 === s ? u[0] : [r / s, a / s]), this._map.layerPointToLatLng(h)
      );
    },
    _convertLatLngs: function (t) {
      let i = Oe.prototype._convertLatLngs.call(this, t),
        e = i.length;
      return 2 <= e && i[0] instanceof D && i[0].equals(i[e - 1]) && i.pop(), i;
    },
    _setLatLngs: function (t) {
      Oe.prototype._setLatLngs.call(this, t),
        pe(this._latlngs) && (this._latlngs = [this._latlngs]);
    },
    _defaultShape: function () {
      return pe(this._latlngs[0]) ? this._latlngs[0] : this._latlngs[0][0];
    },
    _clipPoints: function () {
      var t = this._renderer._bounds,
        i = this.options.weight,
        e = new k(i, i),
        t = new I(t.min.subtract(e), t.max.add(e));
      if (
        ((this._parts = []), this._pxBounds && this._pxBounds.intersects(t))
      ) {
        if (this.options.noClip) {
          this._parts = this._rings;
        } else {
          for (var n, o = 0, s = this._rings.length; o < s; o++) {
            (n = ge(this._rings[o], t, !0)).length && this._parts.push(n);
          }
        }
      }
    },
    _updatePath: function () {
      this._renderer._updatePoly(this, !0);
    },
    _containsPoint: function (t) {
      let i,
        e,
        n,
        o,
        s,
        r,
        a,
        h,
        u = !1;
      if (!this._pxBounds || !this._pxBounds.contains(t)) {
        return !1;
      }
      for (o = 0, a = this._parts.length; o < a; o++) {
        for (s = 0, r = (h = (i = this._parts[o]).length) - 1; s < h; r = s++) {
          (e = i[s]),
            (n = i[r]),
            e.y > t.y != n.y > t.y &&
              t.x < ((n.x - e.x) * (t.y - e.y)) / (n.y - e.y) + e.x &&
              (u = !u);
        }
      }
      return u || Oe.prototype._containsPoint.call(this, t, !0);
    },
  });
  let Ne = Ce.extend({
    initialize: function (t, i) {
      c(this, i), (this._layers = {}), t && this.addData(t);
    },
    addData: function (t) {
      let i,
        e,
        n,
        o = g(t) ? t : t.features;
      if (o) {
        for (i = 0, e = o.length; i < e; i++) {
          ((n = o[i]).geometries ||
            n.geometry ||
            n.features ||
            n.coordinates) &&
            this.addData(n);
        }
        return this;
      }
      let s = this.options;
      if (s.filter && !s.filter(t)) {
        return this;
      }
      let r = De(t, s);
      return r
        ? ((r.feature = qe(t)),
          (r.defaultOptions = r.options),
          this.resetStyle(r),
          s.onEachFeature && s.onEachFeature(t, r),
          this.addLayer(r))
        : this;
    },
    resetStyle: function (t) {
      return void 0 === t
        ? this.eachLayer(this.resetStyle, this)
        : ((t.options = h({}, t.defaultOptions)),
          this._setLayerStyle(t, this.options.style),
          this);
    },
    setStyle: function (i) {
      return this.eachLayer(function (t) {
        this._setLayerStyle(t, i);
      }, this);
    },
    _setLayerStyle: function (t, i) {
      t.setStyle &&
        ("function" === typeof i && (i = i(t.feature)), t.setStyle(i));
    },
  });
  function De(t, i) {
    let e,
      n,
      o,
      s,
      r = "Feature" === t.type ? t.geometry : t,
      a = r ? r.coordinates : null,
      h = [],
      u = i && i.pointToLayer,
      l = (i && i.coordsToLatLng) || We;
    if (!a && !r) {
      return null;
    }
    switch (r.type) {
      case "Point":
        return je(u, t, (e = l(a)), i);
      case "MultiPoint":
        for (o = 0, s = a.length; o < s; o++) {
          (e = l(a[o])), h.push(je(u, t, e, i));
        }
        return new Ce(h);
      case "LineString":
      case "MultiLineString":
        return (n = He(a, "LineString" === r.type ? 0 : 1, l)), new Oe(n, i);
      case "Polygon":
      case "MultiPolygon":
        return (n = He(a, "Polygon" === r.type ? 1 : 2, l)), new Re(n, i);
      case "GeometryCollection":
        for (o = 0, s = r.geometries.length; o < s; o++) {
          let c = De(
            {
              geometry: r.geometries[o],
              type: "Feature",
              properties: t.properties,
            },
            i
          );
          c && h.push(c);
        }
        return new Ce(h);
      default:
        throw new Error("Invalid GeoJSON object.");
    }
  }
  function je(t, i, e, n) {
    return t ? t(i, e) : new ke(e, n && n.markersInheritOptions && n);
  }
  function We(t) {
    return new D(t[1], t[0], t[2]);
  }
  function He(t, i, e) {
    for (var n, o = [], s = 0, r = t.length; s < r; s++) {
      (n = i ? He(t[s], i - 1, e) : (e || We)(t[s])), o.push(n);
    }
    return o;
  }
  function Fe(t, i) {
    return (
      (i = "number" === typeof i ? i : 6),
      void 0 !== t.alt
        ? [r(t.lng, i), r(t.lat, i), r(t.alt, i)]
        : [r(t.lng, i), r(t.lat, i)]
    );
  }
  function Ue(t, i, e, n) {
    for (var o = [], s = 0, r = t.length; s < r; s++) {
      o.push(i ? Ue(t[s], i - 1, e, n) : Fe(t[s], n));
    }
    return !i && e && o.push(o[0]), o;
  }
  function Ve(t, i) {
    return t.feature ? h({}, t.feature, { geometry: i }) : qe(i);
  }
  function qe(t) {
    return "Feature" === t.type || "FeatureCollection" === t.type
      ? t
      : { type: "Feature", properties: {}, geometry: t };
  }
  let Ge = {
    toGeoJSON: function (t) {
      return Ve(this, { type: "Point", coordinates: Fe(this.getLatLng(), t) });
    },
  };
  function Ke(t, i) {
    return new Ne(t, i);
  }
  ke.include(Ge),
    Ie.include(Ge),
    Ae.include(Ge),
    Oe.include({
      toGeoJSON: function (t) {
        let i = !pe(this._latlngs);
        return Ve(this, {
          type: (i ? "Multi" : "") + "LineString",
          coordinates: Ue(this._latlngs, i ? 1 : 0, !1, t),
        });
      },
    }),
    Re.include({
      toGeoJSON: function (t) {
        let i = !pe(this._latlngs),
          e = i && !pe(this._latlngs[0]),
          n = Ue(this._latlngs, e ? 2 : i ? 1 : 0, !0, t);
        return (
          i || (n = [n]),
          Ve(this, { type: (e ? "Multi" : "") + "Polygon", coordinates: n })
        );
      },
    }),
    ze.include({
      toMultiPoint: function (i) {
        let e = [];
        return (
          this.eachLayer(function (t) {
            e.push(t.toGeoJSON(i).geometry.coordinates);
          }),
          Ve(this, { type: "MultiPoint", coordinates: e })
        );
      },
      toGeoJSON: function (n) {
        let t =
          this.feature && this.feature.geometry && this.feature.geometry.type;
        if ("MultiPoint" === t) {
          return this.toMultiPoint(n);
        }
        let o = "GeometryCollection" === t,
          s = [];
        return (
          this.eachLayer(function (t) {
            let i, e;
            t.toGeoJSON &&
              ((i = t.toGeoJSON(n)),
              o
                ? s.push(i.geometry)
                : "FeatureCollection" === (e = qe(i)).type
                ? s.push.apply(s, e.features)
                : s.push(e));
          }),
          o
            ? Ve(this, { geometries: s, type: "GeometryCollection" })
            : { type: "FeatureCollection", features: s }
        );
      },
    });
  let Ye = Ke,
    Xe = Me.extend({
      options: {
        opacity: 1,
        alt: "",
        interactive: !1,
        crossOrigin: !1,
        errorOverlayUrl: "",
        zIndex: 1,
        className: "",
      },
      initialize: function (t, i, e) {
        (this._url = t), (this._bounds = N(i)), c(this, e);
      },
      onAdd: function () {
        this._image ||
          (this._initImage(),
          this.options.opacity < 1 && this._updateOpacity()),
          this.options.interactive &&
            (ci(this._image, "leaflet-interactive"),
            this.addInteractiveTarget(this._image)),
          this.getPane().appendChild(this._image),
          this._reset();
      },
      onRemove: function () {
        ri(this._image),
          this.options.interactive && this.removeInteractiveTarget(this._image);
      },
      setOpacity: function (t) {
        return (
          (this.options.opacity = t), this._image && this._updateOpacity(), this
        );
      },
      setStyle: function (t) {
        return t.opacity && this.setOpacity(t.opacity), this;
      },
      bringToFront: function () {
        return this._map && hi(this._image), this;
      },
      bringToBack: function () {
        return this._map && ui(this._image), this;
      },
      setUrl: function (t) {
        return (this._url = t), this._image && (this._image.src = t), this;
      },
      setBounds: function (t) {
        return (this._bounds = N(t)), this._map && this._reset(), this;
      },
      getEvents: function () {
        let t = { zoom: this._reset, viewreset: this._reset };
        return this._zoomAnimated && (t.zoomanim = this._animateZoom), t;
      },
      setZIndex: function (t) {
        return (this.options.zIndex = t), this._updateZIndex(), this;
      },
      getBounds: function () {
        return this._bounds;
      },
      getElement: function () {
        return this._image;
      },
      _initImage: function () {
        let t = "IMG" === this._url.tagName,
          i = (this._image = t ? this._url : si("img"));
        ci(i, "leaflet-image-layer"),
          this._zoomAnimated && ci(i, "leaflet-zoom-animated"),
          this.options.className && ci(i, this.options.className),
          (i.onselectstart = a),
          (i.onmousemove = a),
          (i.onload = p(this.fire, this, "load")),
          (i.onerror = p(this._overlayOnError, this, "error")),
          (!this.options.crossOrigin && "" !== this.options.crossOrigin) ||
            (i.crossOrigin =
              !0 === this.options.crossOrigin ? "" : this.options.crossOrigin),
          this.options.zIndex && this._updateZIndex(),
          t
            ? (this._url = i.src)
            : ((i.src = this._url), (i.alt = this.options.alt));
      },
      _animateZoom: function (t) {
        let i = this._map.getZoomScale(t.zoom),
          e = this._map._latLngBoundsToNewLayerBounds(
            this._bounds,
            t.zoom,
            t.center
          ).min;
        gi(this._image, e, i);
      },
      _reset: function () {
        let t = this._image,
          i = new I(
            this._map.latLngToLayerPoint(this._bounds.getNorthWest()),
            this._map.latLngToLayerPoint(this._bounds.getSouthEast())
          ),
          e = i.getSize();
        vi(t, i.min),
          (t.style.width = e.x + "px"),
          (t.style.height = e.y + "px");
      },
      _updateOpacity: function () {
        mi(this._image, this.options.opacity);
      },
      _updateZIndex: function () {
        this._image &&
          void 0 !== this.options.zIndex &&
          null !== this.options.zIndex &&
          (this._image.style.zIndex = this.options.zIndex);
      },
      _overlayOnError: function () {
        this.fire("error");
        let t = this.options.errorOverlayUrl;
        t && this._url !== t && ((this._url = t), (this._image.src = t));
      },
    }),
    Je = Xe.extend({
      options: { autoplay: !0, loop: !0, keepAspectRatio: !0, muted: !1 },
      _initImage: function () {
        let t = "VIDEO" === this._url.tagName,
          i = (this._image = t ? this._url : si("video"));
        if (
          (ci(i, "leaflet-image-layer"),
          this._zoomAnimated && ci(i, "leaflet-zoom-animated"),
          this.options.className && ci(i, this.options.className),
          (i.onselectstart = a),
          (i.onmousemove = a),
          (i.onloadeddata = p(this.fire, this, "load")),
          t)
        ) {
          for (
            var e = i.getElementsByTagName("source"), n = [], o = 0;
            o < e.length;
            o++
          ) {
            n.push(e[o].src);
          }
          this._url = 0 < e.length ? n : [i.src];
        } else {
          g(this._url) || (this._url = [this._url]),
            !this.options.keepAspectRatio &&
              Object.prototype.hasOwnProperty.call(i.style, "objectFit") &&
              (i.style.objectFit = "fill"),
            (i.autoplay = !!this.options.autoplay),
            (i.loop = !!this.options.loop),
            (i.muted = !!this.options.muted);
          for (let s = 0; s < this._url.length; s++) {
            let r = si("source");
            (r.src = this._url[s]), i.appendChild(r);
          }
        }
      },
    });
  let $e = Xe.extend({
    _initImage: function () {
      let t = (this._image = this._url);
      ci(t, "leaflet-image-layer"),
        this._zoomAnimated && ci(t, "leaflet-zoom-animated"),
        this.options.className && ci(t, this.options.className),
        (t.onselectstart = a),
        (t.onmousemove = a);
    },
  });
  let Qe = Me.extend({
      options: { offset: [0, 7], className: "", pane: "popupPane" },
      initialize: function (t, i) {
        c(this, t), (this._source = i);
      },
      onAdd: function (t) {
        (this._zoomAnimated = t._zoomAnimated),
          this._container || this._initLayout(),
          t._fadeAnimated && mi(this._container, 0),
          clearTimeout(this._removeTimeout),
          this.getPane().appendChild(this._container),
          this.update(),
          t._fadeAnimated && mi(this._container, 1),
          this.bringToFront();
      },
      onRemove: function (t) {
        t._fadeAnimated
          ? (mi(this._container, 0),
            (this._removeTimeout = setTimeout(
              p(ri, void 0, this._container),
              200
            )))
          : ri(this._container);
      },
      getLatLng: function () {
        return this._latlng;
      },
      setLatLng: function (t) {
        return (
          (this._latlng = j(t)),
          this._map && (this._updatePosition(), this._adjustPan()),
          this
        );
      },
      getContent: function () {
        return this._content;
      },
      setContent: function (t) {
        return (this._content = t), this.update(), this;
      },
      getElement: function () {
        return this._container;
      },
      update: function () {
        this._map &&
          ((this._container.style.visibility = "hidden"),
          this._updateContent(),
          this._updateLayout(),
          this._updatePosition(),
          (this._container.style.visibility = ""),
          this._adjustPan());
      },
      getEvents: function () {
        let t = { zoom: this._updatePosition, viewreset: this._updatePosition };
        return this._zoomAnimated && (t.zoomanim = this._animateZoom), t;
      },
      isOpen: function () {
        return !!this._map && this._map.hasLayer(this);
      },
      bringToFront: function () {
        return this._map && hi(this._container), this;
      },
      bringToBack: function () {
        return this._map && ui(this._container), this;
      },
      _prepareOpen: function (t, i, e) {
        if ((i instanceof Me || ((e = i), (i = t)), i instanceof Ce)) {
          for (let n in t._layers) {
            i = t._layers[n];
            break;
          }
        }
        if (!e) {
          if (i.getCenter) {
            e = i.getCenter();
          } else {
            if (!i.getLatLng) {
              throw new Error("Unable to get source layer LatLng.");
            }
            e = i.getLatLng();
          }
        }
        return (this._source = i), this.update(), e;
      },
      _updateContent: function () {
        if (this._content) {
          let t = this._contentNode,
            i =
              "function" === typeof this._content
                ? this._content(this._source || this)
                : this._content;
          if ("string" === typeof i) {
            t.innerHTML = i;
          } else {
            for (; t.hasChildNodes(); ) {
              t.removeChild(t.firstChild);
            }
            t.appendChild(i);
          }
          this.fire("contentupdate");
        }
      },
      _updatePosition: function () {
        let t, i, e, n, o;
        this._map &&
          ((t = this._map.latLngToLayerPoint(this._latlng)),
          (i = A(this.options.offset)),
          (e = this._getAnchor()),
          this._zoomAnimated
            ? vi(this._container, t.add(e))
            : (i = i.add(t).add(e)),
          (n = this._containerBottom = -i.y),
          (o = this._containerLeft =
            -Math.round(this._containerWidth / 2) + i.x),
          (this._container.style.bottom = n + "px"),
          (this._container.style.left = o + "px"));
      },
      _getAnchor: function () {
        return [0, 0];
      },
    }),
    tn = Qe.extend({
      options: {
        maxWidth: 300,
        minWidth: 50,
        maxHeight: null,
        autoPan: !0,
        autoPanPaddingTopLeft: null,
        autoPanPaddingBottomRight: null,
        autoPanPadding: [5, 5],
        keepInView: !1,
        closeButton: !0,
        autoClose: !0,
        closeOnEscapeKey: !0,
        className: "",
      },
      openOn: function (t) {
        return t.openPopup(this), this;
      },
      onAdd: function (t) {
        Qe.prototype.onAdd.call(this, t),
          t.fire("popupopen", { popup: this }),
          this._source &&
            (this._source.fire("popupopen", { popup: this }, !0),
            this._source instanceof Be || this._source.on("preclick", Ai));
      },
      onRemove: function (t) {
        Qe.prototype.onRemove.call(this, t),
          t.fire("popupclose", { popup: this }),
          this._source &&
            (this._source.fire("popupclose", { popup: this }, !0),
            this._source instanceof Be || this._source.off("preclick", Ai));
      },
      getEvents: function () {
        let t = Qe.prototype.getEvents.call(this);
        return (
          (void 0 !== this.options.closeOnClick
            ? this.options.closeOnClick
            : this._map.options.closePopupOnClick) &&
            (t.preclick = this._close),
          this.options.keepInView && (t.moveend = this._adjustPan),
          t
        );
      },
      _close: function () {
        this._map && this._map.closePopup(this);
      },
      _initLayout: function () {
        let t,
          i = "leaflet-popup",
          e = (this._container = si(
            "div",
            i + " " + (this.options.className || "") + " leaflet-zoom-animated"
          )),
          n = (this._wrapper = si("div", i + "-content-wrapper", e));
        (this._contentNode = si("div", i + "-content", n)),
          Oi(e),
          Ii(this._contentNode),
          zi(e, "contextmenu", Ai),
          (this._tipContainer = si("div", i + "-tip-container", e)),
          (this._tip = si("div", i + "-tip", this._tipContainer)),
          this.options.closeButton &&
            (((t = this._closeButton = si("a", i + "-close-button", e)).href =
              "#close"),
            (t.innerHTML = "&#215;"),
            zi(t, "click", this._onCloseButtonClick, this));
      },
      _updateLayout: function () {
        let t = this._contentNode,
          i = t.style;
        (i.width = ""), (i.whiteSpace = "nowrap");
        var e = t.offsetWidth,
          e = Math.min(e, this.options.maxWidth);
        (e = Math.max(e, this.options.minWidth)),
          (i.width = e + 1 + "px"),
          (i.whiteSpace = ""),
          (i.height = "");
        let n = t.offsetHeight,
          o = this.options.maxHeight,
          s = "leaflet-popup-scrolled";
        o && o < n ? ((i.height = o + "px"), ci(t, s)) : _i(t, s),
          (this._containerWidth = this._container.offsetWidth);
      },
      _animateZoom: function (t) {
        let i = this._map._latLngToNewLayerPoint(
            this._latlng,
            t.zoom,
            t.center
          ),
          e = this._getAnchor();
        vi(this._container, i.add(e));
      },
      _adjustPan: function () {
        let t, i, e, n, o, s, r, a, h, u, l, c;
        this.options.autoPan &&
          (this._map._panAnim && this._map._panAnim.stop(),
          (t = this._map),
          (i = parseInt(oi(this._container, "marginBottom"), 10) || 0),
          (e = this._container.offsetHeight + i),
          (n = this._containerWidth),
          (o = new k(this._containerLeft, -e - this._containerBottom))._add(
            yi(this._container)
          ),
          (s = t.layerPointToContainerPoint(o)),
          (r = A(this.options.autoPanPadding)),
          (a = A(this.options.autoPanPaddingTopLeft || r)),
          (h = A(this.options.autoPanPaddingBottomRight || r)),
          (u = t.getSize()),
          (c = l = 0),
          s.x + n + h.x > u.x && (l = s.x + n - u.x + h.x),
          s.x - l - a.x < 0 && (l = s.x - a.x),
          s.y + e + h.y > u.y && (c = s.y + e - u.y + h.y),
          s.y - c - a.y < 0 && (c = s.y - a.y),
          (l || c) && t.fire("autopanstart").panBy([l, c]));
      },
      _onCloseButtonClick: function (t) {
        this._close(), Ni(t);
      },
      _getAnchor: function () {
        return A(
          this._source && this._source._getPopupAnchor
            ? this._source._getPopupAnchor()
            : [0, 0]
        );
      },
    });
  Ki.mergeOptions({ closePopupOnClick: !0 }),
    Ki.include({
      openPopup: function (t, i, e) {
        return (
          t instanceof tn || (t = new tn(e).setContent(t)),
          i && t.setLatLng(i),
          this.hasLayer(t)
            ? this
            : (this._popup &&
                this._popup.options.autoClose &&
                this.closePopup(),
              (this._popup = t),
              this.addLayer(t))
        );
      },
      closePopup: function (t) {
        return (
          (t && t !== this._popup) || ((t = this._popup), (this._popup = null)),
          t && this.removeLayer(t),
          this
        );
      },
    }),
    Me.include({
      bindPopup: function (t, i) {
        return (
          t instanceof tn
            ? (c(t, i), ((this._popup = t)._source = this))
            : ((this._popup && !i) || (this._popup = new tn(i, this)),
              this._popup.setContent(t)),
          this._popupHandlersAdded ||
            (this.on({
              click: this._openPopup,
              keypress: this._onKeyPress,
              remove: this.closePopup,
              move: this._movePopup,
            }),
            (this._popupHandlersAdded = !0)),
          this
        );
      },
      unbindPopup: function () {
        return (
          this._popup &&
            (this.off({
              click: this._openPopup,
              keypress: this._onKeyPress,
              remove: this.closePopup,
              move: this._movePopup,
            }),
            (this._popupHandlersAdded = !1),
            (this._popup = null)),
          this
        );
      },
      openPopup: function (t, i) {
        return (
          this._popup &&
            this._map &&
            ((i = this._popup._prepareOpen(this, t, i)),
            this._map.openPopup(this._popup, i)),
          this
        );
      },
      closePopup: function () {
        return this._popup && this._popup._close(), this;
      },
      togglePopup: function (t) {
        return (
          this._popup &&
            (this._popup._map ? this.closePopup() : this.openPopup(t)),
          this
        );
      },
      isPopupOpen: function () {
        return !!this._popup && this._popup.isOpen();
      },
      setPopupContent: function (t) {
        return this._popup && this._popup.setContent(t), this;
      },
      getPopup: function () {
        return this._popup;
      },
      _openPopup: function (t) {
        let i = t.layer || t.target;
        this._popup &&
          this._map &&
          (Ni(t),
          i instanceof Be
            ? this.openPopup(t.layer || t.target, t.latlng)
            : this._map.hasLayer(this._popup) && this._popup._source === i
            ? this.closePopup()
            : this.openPopup(i, t.latlng));
      },
      _movePopup: function (t) {
        this._popup.setLatLng(t.latlng);
      },
      _onKeyPress: function (t) {
        13 === t.originalEvent.keyCode && this._openPopup(t);
      },
    });
  let en = Qe.extend({
    options: {
      pane: "tooltipPane",
      offset: [0, 0],
      direction: "auto",
      permanent: !1,
      sticky: !1,
      interactive: !1,
      opacity: 0.9,
    },
    onAdd: function (t) {
      Qe.prototype.onAdd.call(this, t),
        this.setOpacity(this.options.opacity),
        t.fire("tooltipopen", { tooltip: this }),
        this._source && this._source.fire("tooltipopen", { tooltip: this }, !0);
    },
    onRemove: function (t) {
      Qe.prototype.onRemove.call(this, t),
        t.fire("tooltipclose", { tooltip: this }),
        this._source &&
          this._source.fire("tooltipclose", { tooltip: this }, !0);
    },
    getEvents: function () {
      let t = Qe.prototype.getEvents.call(this);
      return bt && !this.options.permanent && (t.preclick = this._close), t;
    },
    _close: function () {
      this._map && this._map.closeTooltip(this);
    },
    _initLayout: function () {
      let t =
        "leaflet-tooltip " +
        (this.options.className || "") +
        " leaflet-zoom-" +
        (this._zoomAnimated ? "animated" : "hide");
      this._contentNode = this._container = si("div", t);
    },
    _updateLayout: function () {},
    _adjustPan: function () {},
    _setPosition: function (t) {
      let i,
        e = this._map,
        n = this._container,
        o = e.latLngToContainerPoint(e.getCenter()),
        s = e.layerPointToContainerPoint(t),
        r = this.options.direction,
        a = n.offsetWidth,
        h = n.offsetHeight,
        u = A(this.options.offset),
        l = this._getAnchor(),
        c =
          "top" === r
            ? ((i = a / 2), h)
            : "bottom" === r
            ? ((i = a / 2), 0)
            : ((i =
                "center" === r
                  ? a / 2
                  : "right" === r
                  ? 0
                  : "left" === r
                  ? a
                  : s.x < o.x
                  ? ((r = "right"), 0)
                  : ((r = "left"), a + 2 * (u.x + l.x))),
              h / 2);
      (t = t.subtract(A(i, c, !0)).add(u).add(l)),
        _i(n, "leaflet-tooltip-right"),
        _i(n, "leaflet-tooltip-left"),
        _i(n, "leaflet-tooltip-top"),
        _i(n, "leaflet-tooltip-bottom"),
        ci(n, "leaflet-tooltip-" + r),
        vi(n, t);
    },
    _updatePosition: function () {
      let t = this._map.latLngToLayerPoint(this._latlng);
      this._setPosition(t);
    },
    setOpacity: function (t) {
      (this.options.opacity = t), this._container && mi(this._container, t);
    },
    _animateZoom: function (t) {
      let i = this._map._latLngToNewLayerPoint(this._latlng, t.zoom, t.center);
      this._setPosition(i);
    },
    _getAnchor: function () {
      return A(
        this._source && this._source._getTooltipAnchor && !this.options.sticky
          ? this._source._getTooltipAnchor()
          : [0, 0]
      );
    },
  });
  Ki.include({
    openTooltip: function (t, i, e) {
      return (
        t instanceof en || (t = new en(e).setContent(t)),
        i && t.setLatLng(i),
        this.hasLayer(t) ? this : this.addLayer(t)
      );
    },
    closeTooltip: function (t) {
      return t && this.removeLayer(t), this;
    },
  }),
    Me.include({
      bindTooltip: function (t, i) {
        return (
          t instanceof en
            ? (c(t, i), ((this._tooltip = t)._source = this))
            : ((this._tooltip && !i) || (this._tooltip = new en(i, this)),
              this._tooltip.setContent(t)),
          this._initTooltipInteractions(),
          this._tooltip.options.permanent &&
            this._map &&
            this._map.hasLayer(this) &&
            this.openTooltip(),
          this
        );
      },
      unbindTooltip: function () {
        return (
          this._tooltip &&
            (this._initTooltipInteractions(!0),
            this.closeTooltip(),
            (this._tooltip = null)),
          this
        );
      },
      _initTooltipInteractions: function (t) {
        let i, e;
        (!t && this._tooltipHandlersAdded) ||
          ((i = t ? "off" : "on"),
          (e = { remove: this.closeTooltip, move: this._moveTooltip }),
          this._tooltip.options.permanent
            ? (e.add = this._openTooltip)
            : ((e.mouseover = this._openTooltip),
              (e.mouseout = this.closeTooltip),
              this._tooltip.options.sticky && (e.mousemove = this._moveTooltip),
              bt && (e.click = this._openTooltip)),
          this[i](e),
          (this._tooltipHandlersAdded = !t));
      },
      openTooltip: function (t, i) {
        return (
          this._tooltip &&
            this._map &&
            ((i = this._tooltip._prepareOpen(this, t, i)),
            this._map.openTooltip(this._tooltip, i),
            this._tooltip.options.interactive &&
              this._tooltip._container &&
              (ci(this._tooltip._container, "leaflet-clickable"),
              this.addInteractiveTarget(this._tooltip._container))),
          this
        );
      },
      closeTooltip: function () {
        return (
          this._tooltip &&
            (this._tooltip._close(),
            this._tooltip.options.interactive &&
              this._tooltip._container &&
              (_i(this._tooltip._container, "leaflet-clickable"),
              this.removeInteractiveTarget(this._tooltip._container))),
          this
        );
      },
      toggleTooltip: function (t) {
        return (
          this._tooltip &&
            (this._tooltip._map ? this.closeTooltip() : this.openTooltip(t)),
          this
        );
      },
      isTooltipOpen: function () {
        return this._tooltip.isOpen();
      },
      setTooltipContent: function (t) {
        return this._tooltip && this._tooltip.setContent(t), this;
      },
      getTooltip: function () {
        return this._tooltip;
      },
      _openTooltip: function (t) {
        let i = t.layer || t.target;
        this._tooltip &&
          this._map &&
          this.openTooltip(i, this._tooltip.options.sticky ? t.latlng : void 0);
      },
      _moveTooltip: function (t) {
        let i,
          e,
          n = t.latlng;
        this._tooltip.options.sticky &&
          t.originalEvent &&
          ((i = this._map.mouseEventToContainerPoint(t.originalEvent)),
          (e = this._map.containerPointToLayerPoint(i)),
          (n = this._map.layerPointToLatLng(e))),
          this._tooltip.setLatLng(n);
      },
    });
  let nn = Se.extend({
    options: {
      iconSize: [12, 12],
      html: !1,
      bgPos: null,
      className: "leaflet-div-icon",
    },
    createIcon: function (t) {
      let i,
        e = t && "DIV" === t.tagName ? t : document.createElement("div"),
        n = this.options;
      return (
        n.html instanceof Element
          ? (ai(e), e.appendChild(n.html))
          : (e.innerHTML = !1 !== n.html ? n.html : ""),
        n.bgPos &&
          ((i = A(n.bgPos)),
          (e.style.backgroundPosition = -i.x + "px " + -i.y + "px")),
        this._setIconStyles(e, "icon"),
        e
      );
    },
    createShadow: function () {
      return null;
    },
  });
  Se.Default = Ze;
  let on = Me.extend({
    options: {
      tileSize: 256,
      opacity: 1,
      updateWhenIdle: yt,
      updateWhenZooming: !0,
      updateInterval: 200,
      zIndex: 1,
      bounds: null,
      minZoom: 0,
      maxZoom: void 0,
      maxNativeZoom: void 0,
      minNativeZoom: void 0,
      noWrap: !1,
      pane: "tilePane",
      className: "",
      keepBuffer: 2,
    },
    initialize: function (t) {
      c(this, t);
    },
    onAdd: function () {
      this._initContainer(),
        (this._levels = {}),
        (this._tiles = {}),
        this._resetView(),
        this._update();
    },
    beforeAdd: function (t) {
      t._addZoomLimit(this);
    },
    onRemove: function (t) {
      this._removeAllTiles(),
        ri(this._container),
        t._removeZoomLimit(this),
        (this._container = null),
        (this._tileZoom = void 0);
    },
    bringToFront: function () {
      return (
        this._map && (hi(this._container), this._setAutoZIndex(Math.max)), this
      );
    },
    bringToBack: function () {
      return (
        this._map && (ui(this._container), this._setAutoZIndex(Math.min)), this
      );
    },
    getContainer: function () {
      return this._container;
    },
    setOpacity: function (t) {
      return (this.options.opacity = t), this._updateOpacity(), this;
    },
    setZIndex: function (t) {
      return (this.options.zIndex = t), this._updateZIndex(), this;
    },
    isLoading: function () {
      return this._loading;
    },
    redraw: function () {
      return this._map && (this._removeAllTiles(), this._update()), this;
    },
    getEvents: function () {
      let t = {
        viewprereset: this._invalidateAll,
        viewreset: this._resetView,
        zoom: this._resetView,
        moveend: this._onMoveEnd,
      };
      return (
        this.options.updateWhenIdle ||
          (this._onMove ||
            (this._onMove = n(
              this._onMoveEnd,
              this.options.updateInterval,
              this
            )),
          (t.move = this._onMove)),
        this._zoomAnimated && (t.zoomanim = this._animateZoom),
        t
      );
    },
    createTile: function () {
      return document.createElement("div");
    },
    getTileSize: function () {
      let t = this.options.tileSize;
      return t instanceof k ? t : new k(t, t);
    },
    _updateZIndex: function () {
      this._container &&
        void 0 !== this.options.zIndex &&
        null !== this.options.zIndex &&
        (this._container.style.zIndex = this.options.zIndex);
    },
    _setAutoZIndex: function (t) {
      for (
        var i,
          e = this.getPane().children,
          n = -t(-1 / 0, 1 / 0),
          o = 0,
          s = e.length;
        o < s;
        o++
      ) {
        (i = e[o].style.zIndex),
          e[o] !== this._container && i && (n = t(n, +i));
      }
      isFinite(n) &&
        ((this.options.zIndex = n + t(-1, 1)), this._updateZIndex());
    },
    _updateOpacity: function () {
      if (this._map && !it) {
        mi(this._container, this.options.opacity);
        let t = +new Date(),
          i = !1,
          e = !1;
        for (let n in this._tiles) {
          var o,
            s = this._tiles[n];
          s.current &&
            s.loaded &&
            ((o = Math.min(1, (t - s.loaded) / 200)),
            mi(s.el, o),
            o < 1
              ? (i = !0)
              : (s.active ? (e = !0) : this._onOpaqueTile(s), (s.active = !0)));
        }
        e && !this._noPrune && this._pruneTiles(),
          i &&
            (z(this._fadeFrame),
            (this._fadeFrame = M(this._updateOpacity, this)));
      }
    },
    _onOpaqueTile: a,
    _initContainer: function () {
      this._container ||
        ((this._container = si(
          "div",
          "leaflet-layer " + (this.options.className || "")
        )),
        this._updateZIndex(),
        this.options.opacity < 1 && this._updateOpacity(),
        this.getPane().appendChild(this._container));
    },
    _updateLevels: function () {
      let t = this._tileZoom,
        i = this.options.maxZoom;
      if (void 0 !== t) {
        for (let e in this._levels) {
          (e = Number(e)),
            this._levels[e].el.children.length || e === t
              ? ((this._levels[e].el.style.zIndex = i - Math.abs(t - e)),
                this._onUpdateLevel(e))
              : (ri(this._levels[e].el),
                this._removeTilesAtZoom(e),
                this._onRemoveLevel(e),
                delete this._levels[e]);
        }
        let n = this._levels[t],
          o = this._map;
        return (
          n ||
            (((n = this._levels[t] = {}).el = si(
              "div",
              "leaflet-tile-container leaflet-zoom-animated",
              this._container
            )),
            (n.el.style.zIndex = i),
            (n.origin = o.project(o.unproject(o.getPixelOrigin()), t).round()),
            (n.zoom = t),
            this._setZoomTransform(n, o.getCenter(), o.getZoom()),
            a(n.el.offsetWidth),
            this._onCreateLevel(n)),
          (this._level = n)
        );
      }
    },
    _onUpdateLevel: a,
    _onRemoveLevel: a,
    _onCreateLevel: a,
    _pruneTiles: function () {
      if (this._map) {
        let t,
          i,
          e,
          n = this._map.getZoom();
        if (n > this.options.maxZoom || n < this.options.minZoom) {
          this._removeAllTiles();
        } else {
          for (t in this._tiles) {
            (e = this._tiles[t]).retain = e.current;
          }
          for (t in this._tiles) {
            (e = this._tiles[t]).current &&
              !e.active &&
              ((i = e.coords),
              this._retainParent(i.x, i.y, i.z, i.z - 5) ||
                this._retainChildren(i.x, i.y, i.z, i.z + 2));
          }
          for (t in this._tiles) {
            this._tiles[t].retain || this._removeTile(t);
          }
        }
      }
    },
    _removeTilesAtZoom: function (t) {
      for (let i in this._tiles) {
        this._tiles[i].coords.z === t && this._removeTile(i);
      }
    },
    _removeAllTiles: function () {
      for (let t in this._tiles) {
        this._removeTile(t);
      }
    },
    _invalidateAll: function () {
      for (let t in this._levels) {
        ri(this._levels[t].el),
          this._onRemoveLevel(Number(t)),
          delete this._levels[t];
      }
      this._removeAllTiles(), (this._tileZoom = void 0);
    },
    _retainParent: function (t, i, e, n) {
      let o = Math.floor(t / 2),
        s = Math.floor(i / 2),
        r = e - 1,
        a = new k(+o, +s);
      a.z = +r;
      let h = this._tileCoordsToKey(a),
        u = this._tiles[h];
      return u && u.active
        ? (u.retain = !0)
        : (u && u.loaded && (u.retain = !0),
          n < r && this._retainParent(o, s, r, n));
    },
    _retainChildren: function (t, i, e, n) {
      for (let o = 2 * t; o < 2 * t + 2; o++) {
        for (let s = 2 * i; s < 2 * i + 2; s++) {
          let r = new k(o, s);
          r.z = e + 1;
          let a = this._tileCoordsToKey(r),
            h = this._tiles[a];
          h && h.active
            ? (h.retain = !0)
            : (h && h.loaded && (h.retain = !0),
              e + 1 < n && this._retainChildren(o, s, e + 1, n));
        }
      }
    },
    _resetView: function (t) {
      let i = t && (t.pinch || t.flyTo);
      this._setView(this._map.getCenter(), this._map.getZoom(), i, i);
    },
    _animateZoom: function (t) {
      this._setView(t.center, t.zoom, !0, t.noUpdate);
    },
    _clampZoom: function (t) {
      let i = this.options;
      return void 0 !== i.minNativeZoom && t < i.minNativeZoom
        ? i.minNativeZoom
        : void 0 !== i.maxNativeZoom && i.maxNativeZoom < t
        ? i.maxNativeZoom
        : t;
    },
    _setView: function (t, i, e, n) {
      var o = Math.round(i),
        o =
          (void 0 !== this.options.maxZoom && o > this.options.maxZoom) ||
          (void 0 !== this.options.minZoom && o < this.options.minZoom)
            ? void 0
            : this._clampZoom(o),
        s = this.options.updateWhenZooming && o !== this._tileZoom;
      (n && !s) ||
        ((this._tileZoom = o),
        this._abortLoading && this._abortLoading(),
        this._updateLevels(),
        this._resetGrid(),
        void 0 !== o && this._update(t),
        e || this._pruneTiles(),
        (this._noPrune = !!e)),
        this._setZoomTransforms(t, i);
    },
    _setZoomTransforms: function (t, i) {
      for (let e in this._levels) {
        this._setZoomTransform(this._levels[e], t, i);
      }
    },
    _setZoomTransform: function (t, i, e) {
      let n = this._map.getZoomScale(e, t.zoom),
        o = t.origin
          .multiplyBy(n)
          .subtract(this._map._getNewPixelOrigin(i, e))
          .round();
      vt ? gi(t.el, o, n) : vi(t.el, o);
    },
    _resetGrid: function () {
      let t = this._map,
        i = t.options.crs,
        e = (this._tileSize = this.getTileSize()),
        n = this._tileZoom,
        o = this._map.getPixelWorldBounds(this._tileZoom);
      o && (this._globalTileRange = this._pxBoundsToTileRange(o)),
        (this._wrapX = i.wrapLng &&
          !this.options.noWrap && [
            Math.floor(t.project([0, i.wrapLng[0]], n).x / e.x),
            Math.ceil(t.project([0, i.wrapLng[1]], n).x / e.y),
          ]),
        (this._wrapY = i.wrapLat &&
          !this.options.noWrap && [
            Math.floor(t.project([i.wrapLat[0], 0], n).y / e.x),
            Math.ceil(t.project([i.wrapLat[1], 0], n).y / e.y),
          ]);
    },
    _onMoveEnd: function () {
      this._map && !this._map._animatingZoom && this._update();
    },
    _getTiledPixelBounds: function (t) {
      let i = this._map,
        e = i._animatingZoom
          ? Math.max(i._animateToZoom, i.getZoom())
          : i.getZoom(),
        n = i.getZoomScale(e, this._tileZoom),
        o = i.project(t, this._tileZoom).floor(),
        s = i.getSize().divideBy(2 * n);
      return new I(o.subtract(s), o.add(s));
    },
    _update: function (t) {
      let i = this._map;
      if (i) {
        let e = this._clampZoom(i.getZoom());
        if ((void 0 === t && (t = i.getCenter()), void 0 !== this._tileZoom)) {
          let n = this._getTiledPixelBounds(t),
            o = this._pxBoundsToTileRange(n),
            s = o.getCenter(),
            r = [],
            a = this.options.keepBuffer,
            h = new I(
              o.getBottomLeft().subtract([a, -a]),
              o.getTopRight().add([a, -a])
            );
          if (
            !(
              isFinite(o.min.x) &&
              isFinite(o.min.y) &&
              isFinite(o.max.x) &&
              isFinite(o.max.y)
            )
          ) {
            throw new Error("Attempted to load an infinite number of tiles");
          }
          for (let u in this._tiles) {
            let l = this._tiles[u].coords;
            (l.z === this._tileZoom && h.contains(new k(l.x, l.y))) ||
              (this._tiles[u].current = !1);
          }
          if (1 < Math.abs(e - this._tileZoom)) {
            this._setView(t, e);
          } else {
            for (let c = o.min.y; c <= o.max.y; c++) {
              for (var _ = o.min.x; _ <= o.max.x; _++) {
                var d,
                  p = new k(_, c);
                (p.z = this._tileZoom),
                  this._isValidTile(p) &&
                    ((d = this._tiles[this._tileCoordsToKey(p)])
                      ? (d.current = !0)
                      : r.push(p));
              }
            }
            if (
              (r.sort(function (t, i) {
                return t.distanceTo(s) - i.distanceTo(s);
              }),
              0 !== r.length)
            ) {
              this._loading || ((this._loading = !0), this.fire("loading"));
              for (
                var m = document.createDocumentFragment(), _ = 0;
                _ < r.length;
                _++
              ) {
                this._addTile(r[_], m);
              }
              this._level.el.appendChild(m);
            }
          }
        }
      }
    },
    _isValidTile: function (t) {
      let i = this._map.options.crs;
      if (!i.infinite) {
        let e = this._globalTileRange;
        if (
          (!i.wrapLng && (t.x < e.min.x || t.x > e.max.x)) ||
          (!i.wrapLat && (t.y < e.min.y || t.y > e.max.y))
        ) {
          return !1;
        }
      }
      if (!this.options.bounds) {
        return !0;
      }
      let n = this._tileCoordsToBounds(t);
      return N(this.options.bounds).overlaps(n);
    },
    _keyToBounds: function (t) {
      return this._tileCoordsToBounds(this._keyToTileCoords(t));
    },
    _tileCoordsToNwSe: function (t) {
      let i = this._map,
        e = this.getTileSize(),
        n = t.scaleBy(e),
        o = n.add(e);
      return [i.unproject(n, t.z), i.unproject(o, t.z)];
    },
    _tileCoordsToBounds: function (t) {
      let i = this._tileCoordsToNwSe(t),
        e = new R(i[0], i[1]);
      return this.options.noWrap || (e = this._map.wrapLatLngBounds(e)), e;
    },
    _tileCoordsToKey: function (t) {
      return t.x + ":" + t.y + ":" + t.z;
    },
    _keyToTileCoords: function (t) {
      let i = t.split(":"),
        e = new k(+i[0], +i[1]);
      return (e.z = +i[2]), e;
    },
    _removeTile: function (t) {
      let i = this._tiles[t];
      i &&
        (ri(i.el),
        delete this._tiles[t],
        this.fire("tileunload", {
          tile: i.el,
          coords: this._keyToTileCoords(t),
        }));
    },
    _initTile: function (t) {
      ci(t, "leaflet-tile");
      let i = this.getTileSize();
      (t.style.width = i.x + "px"),
        (t.style.height = i.y + "px"),
        (t.onselectstart = a),
        (t.onmousemove = a),
        it && this.options.opacity < 1 && mi(t, this.options.opacity),
        ot && !st && (t.style.WebkitBackfaceVisibility = "hidden");
    },
    _addTile: function (t, i) {
      let e = this._getTilePos(t),
        n = this._tileCoordsToKey(t),
        o = this.createTile(this._wrapCoords(t), p(this._tileReady, this, t));
      this._initTile(o),
        this.createTile.length < 2 && M(p(this._tileReady, this, t, null, o)),
        vi(o, e),
        (this._tiles[n] = { el: o, coords: t, current: !0 }),
        i.appendChild(o),
        this.fire("tileloadstart", { tile: o, coords: t });
    },
    _tileReady: function (t, i, e) {
      i && this.fire("tileerror", { error: i, tile: e, coords: t });
      let n = this._tileCoordsToKey(t);
      (e = this._tiles[n]) &&
        ((e.loaded = +new Date()),
        this._map._fadeAnimated
          ? (mi(e.el, 0),
            z(this._fadeFrame),
            (this._fadeFrame = M(this._updateOpacity, this)))
          : ((e.active = !0), this._pruneTiles()),
        i ||
          (ci(e.el, "leaflet-tile-loaded"),
          this.fire("tileload", { tile: e.el, coords: t })),
        this._noTilesToLoad() &&
          ((this._loading = !1),
          this.fire("load"),
          it || !this._map._fadeAnimated
            ? M(this._pruneTiles, this)
            : setTimeout(p(this._pruneTiles, this), 250)));
    },
    _getTilePos: function (t) {
      return t.scaleBy(this.getTileSize()).subtract(this._level.origin);
    },
    _wrapCoords: function (t) {
      let i = new k(
        this._wrapX ? o(t.x, this._wrapX) : t.x,
        this._wrapY ? o(t.y, this._wrapY) : t.y
      );
      return (i.z = t.z), i;
    },
    _pxBoundsToTileRange: function (t) {
      let i = this.getTileSize();
      return new I(
        t.min.unscaleBy(i).floor(),
        t.max.unscaleBy(i).ceil().subtract([1, 1])
      );
    },
    _noTilesToLoad: function () {
      for (let t in this._tiles) {
        if (!this._tiles[t].loaded) {
          return !1;
        }
      }
      return !0;
    },
  });
  let sn = on.extend({
    options: {
      minZoom: 0,
      maxZoom: 18,
      subdomains: "abc",
      errorTileUrl: "",
      zoomOffset: 0,
      tms: !1,
      zoomReverse: !1,
      detectRetina: !1,
      crossOrigin: !1,
    },
    initialize: function (t, i) {
      (this._url = t),
        (i = c(this, i)).detectRetina &&
          zt &&
          0 < i.maxZoom &&
          ((i.tileSize = Math.floor(i.tileSize / 2)),
          i.zoomReverse
            ? (i.zoomOffset--, i.minZoom++)
            : (i.zoomOffset++, i.maxZoom--),
          (i.minZoom = Math.max(0, i.minZoom))),
        "string" === typeof i.subdomains &&
          (i.subdomains = i.subdomains.split("")),
        ot || this.on("tileunload", this._onTileRemove);
    },
    setUrl: function (t, i) {
      return (
        this._url === t && void 0 === i && (i = !0),
        (this._url = t),
        i || this.redraw(),
        this
      );
    },
    createTile: function (t, i) {
      let e = document.createElement("img");
      return (
        zi(e, "load", p(this._tileOnLoad, this, i, e)),
        zi(e, "error", p(this._tileOnError, this, i, e)),
        (!this.options.crossOrigin && "" !== this.options.crossOrigin) ||
          (e.crossOrigin =
            !0 === this.options.crossOrigin ? "" : this.options.crossOrigin),
        (e.alt = ""),
        e.setAttribute("role", "presentation"),
        (e.src = this.getTileUrl(t)),
        e
      );
    },
    getTileUrl: function (t) {
      let i,
        e = {
          r: zt ? "@2x" : "",
          s: this._getSubdomain(t),
          x: t.x,
          y: t.y,
          z: this._getZoomForUrl(),
        };
      return (
        this._map &&
          !this._map.options.crs.infinite &&
          ((i = this._globalTileRange.max.y - t.y),
          this.options.tms && (e.y = i),
          (e["-y"] = i)),
        f(this._url, h(e, this.options))
      );
    },
    _tileOnLoad: function (t, i) {
      it ? setTimeout(p(t, this, null, i), 0) : t(null, i);
    },
    _tileOnError: function (t, i, e) {
      let n = this.options.errorTileUrl;
      n && i.getAttribute("src") !== n && (i.src = n), t(e, i);
    },
    _onTileRemove: function (t) {
      t.tile.onload = null;
    },
    _getZoomForUrl: function () {
      let t = this._tileZoom,
        i = this.options.maxZoom;
      return (
        this.options.zoomReverse && (t = i - t), t + this.options.zoomOffset
      );
    },
    _getSubdomain: function (t) {
      let i = Math.abs(t.x + t.y) % this.options.subdomains.length;
      return this.options.subdomains[i];
    },
    _abortLoading: function () {
      let t, i;
      for (t in this._tiles) {
        this._tiles[t].coords.z !== this._tileZoom &&
          (((i = this._tiles[t].el).onload = a),
          (i.onerror = a),
          i.complete || ((i.src = y), ri(i), delete this._tiles[t]));
      }
    },
    _removeTile: function (t) {
      let i = this._tiles[t];
      if (i) {
        return (
          at || i.el.setAttribute("src", y),
          on.prototype._removeTile.call(this, t)
        );
      }
    },
    _tileReady: function (t, i, e) {
      if (this._map && (!e || e.getAttribute("src") !== y)) {
        return on.prototype._tileReady.call(this, t, i, e);
      }
    },
  });
  function rn(t, i) {
    return new sn(t, i);
  }
  let an = sn.extend({
    defaultWmsParams: {
      service: "WMS",
      request: "GetMap",
      layers: "",
      styles: "",
      format: "image/jpeg",
      transparent: !1,
      version: "1.1.1",
    },
    options: { crs: null, uppercase: !1 },
    initialize: function (t, i) {
      this._url = t;
      let e = h({}, this.defaultWmsParams);
      for (let n in i) {
        n in this.options || (e[n] = i[n]);
      }
      let o = (i = c(this, i)).detectRetina && zt ? 2 : 1,
        s = this.getTileSize();
      (e.width = s.x * o), (e.height = s.y * o), (this.wmsParams = e);
    },
    onAdd: function (t) {
      (this._crs = this.options.crs || t.options.crs),
        (this._wmsVersion = parseFloat(this.wmsParams.version));
      let i = 1.3 <= this._wmsVersion ? "crs" : "srs";
      (this.wmsParams[i] = this._crs.code), sn.prototype.onAdd.call(this, t);
    },
    getTileUrl: function (t) {
      let i = this._tileCoordsToNwSe(t),
        e = this._crs,
        n = O(e.project(i[0]), e.project(i[1])),
        o = n.min,
        s = n.max,
        r = (1.3 <= this._wmsVersion && this._crs === be
          ? [o.y, o.x, s.y, s.x]
          : [o.x, o.y, s.x, s.y]
        ).join(","),
        a = sn.prototype.getTileUrl.call(this, t);
      return (
        a +
        _(this.wmsParams, a, this.options.uppercase) +
        (this.options.uppercase ? "&BBOX=" : "&bbox=") +
        r
      );
    },
    setParams: function (t, i) {
      return h(this.wmsParams, t), i || this.redraw(), this;
    },
  });
  (sn.WMS = an),
    (rn.wms = function (t, i) {
      return new an(t, i);
    });
  let hn = Me.extend({
      options: { padding: 0.1, tolerance: 0 },
      initialize: function (t) {
        c(this, t), m(this), (this._layers = this._layers || {});
      },
      onAdd: function () {
        this._container ||
          (this._initContainer(),
          this._zoomAnimated && ci(this._container, "leaflet-zoom-animated")),
          this.getPane().appendChild(this._container),
          this._update(),
          this.on("update", this._updatePaths, this);
      },
      onRemove: function () {
        this.off("update", this._updatePaths, this), this._destroyContainer();
      },
      getEvents: function () {
        let t = {
          viewreset: this._reset,
          zoom: this._onZoom,
          moveend: this._update,
          zoomend: this._onZoomEnd,
        };
        return this._zoomAnimated && (t.zoomanim = this._onAnimZoom), t;
      },
      _onAnimZoom: function (t) {
        this._updateTransform(t.center, t.zoom);
      },
      _onZoom: function () {
        this._updateTransform(this._map.getCenter(), this._map.getZoom());
      },
      _updateTransform: function (t, i) {
        let e = this._map.getZoomScale(i, this._zoom),
          n = yi(this._container),
          o = this._map.getSize().multiplyBy(0.5 + this.options.padding),
          s = this._map.project(this._center, i),
          r = this._map.project(t, i).subtract(s),
          a = o.multiplyBy(-e).add(n).add(o).subtract(r);
        vt ? gi(this._container, a, e) : vi(this._container, a);
      },
      _reset: function () {
        for (let t in (this._update(),
        this._updateTransform(this._center, this._zoom),
        this._layers)) {
          this._layers[t]._reset();
        }
      },
      _onZoomEnd: function () {
        for (let t in this._layers) {
          this._layers[t]._project();
        }
      },
      _updatePaths: function () {
        for (let t in this._layers) {
          this._layers[t]._update();
        }
      },
      _update: function () {
        let t = this.options.padding,
          i = this._map.getSize(),
          e = this._map.containerPointToLayerPoint(i.multiplyBy(-t)).round();
        (this._bounds = new I(e, e.add(i.multiplyBy(1 + 2 * t)).round())),
          (this._center = this._map.getCenter()),
          (this._zoom = this._map.getZoom());
      },
    }),
    un = hn.extend({
      getEvents: function () {
        let t = hn.prototype.getEvents.call(this);
        return (t.viewprereset = this._onViewPreReset), t;
      },
      _onViewPreReset: function () {
        this._postponeUpdatePaths = !0;
      },
      onAdd: function () {
        hn.prototype.onAdd.call(this), this._draw();
      },
      _initContainer: function () {
        let t = (this._container = document.createElement("canvas"));
        zi(t, "mousemove", this._onMouseMove, this),
          zi(
            t,
            "click dblclick mousedown mouseup contextmenu",
            this._onClick,
            this
          ),
          zi(t, "mouseout", this._handleMouseOut, this),
          (this._ctx = t.getContext("2d"));
      },
      _destroyContainer: function () {
        z(this._redrawRequest),
          delete this._ctx,
          ri(this._container),
          Si(this._container),
          delete this._container;
      },
      _updatePaths: function () {
        if (!this._postponeUpdatePaths) {
          for (let t in ((this._redrawBounds = null), this._layers)) {
            this._layers[t]._update();
          }
          this._redraw();
        }
      },
      _update: function () {
        let t, i, e, n;
        (this._map._animatingZoom && this._bounds) ||
          (hn.prototype._update.call(this),
          (t = this._bounds),
          (i = this._container),
          (e = t.getSize()),
          (n = zt ? 2 : 1),
          vi(i, t.min),
          (i.width = n * e.x),
          (i.height = n * e.y),
          (i.style.width = e.x + "px"),
          (i.style.height = e.y + "px"),
          zt && this._ctx.scale(2, 2),
          this._ctx.translate(-t.min.x, -t.min.y),
          this.fire("update"));
      },
      _reset: function () {
        hn.prototype._reset.call(this),
          this._postponeUpdatePaths &&
            ((this._postponeUpdatePaths = !1), this._updatePaths());
      },
      _initPath: function (t) {
        this._updateDashArray(t);
        let i = ((this._layers[m(t)] = t)._order = {
          layer: t,
          prev: this._drawLast,
          next: null,
        });
        this._drawLast && (this._drawLast.next = i),
          (this._drawLast = i),
          (this._drawFirst = this._drawFirst || this._drawLast);
      },
      _addPath: function (t) {
        this._requestRedraw(t);
      },
      _removePath: function (t) {
        let i = t._order,
          e = i.next,
          n = i.prev;
        e ? (e.prev = n) : (this._drawLast = n),
          n ? (n.next = e) : (this._drawFirst = e),
          delete t._order,
          delete this._layers[m(t)],
          this._requestRedraw(t);
      },
      _updatePath: function (t) {
        this._extendRedrawBounds(t),
          t._project(),
          t._update(),
          this._requestRedraw(t);
      },
      _updateStyle: function (t) {
        this._updateDashArray(t), this._requestRedraw(t);
      },
      _updateDashArray: function (t) {
        if ("string" === typeof t.options.dashArray) {
          for (
            var i, e = t.options.dashArray.split(/[, ]+/), n = [], o = 0;
            o < e.length;
            o++
          ) {
            if (((i = Number(e[o])), isNaN(i))) {
              return;
            }
            n.push(i);
          }
          t.options._dashArray = n;
        } else {
          t.options._dashArray = t.options.dashArray;
        }
      },
      _requestRedraw: function (t) {
        this._map &&
          (this._extendRedrawBounds(t),
          (this._redrawRequest = this._redrawRequest || M(this._redraw, this)));
      },
      _extendRedrawBounds: function (t) {
        let i;
        t._pxBounds &&
          ((i = (t.options.weight || 0) + 1),
          (this._redrawBounds = this._redrawBounds || new I()),
          this._redrawBounds.extend(t._pxBounds.min.subtract([i, i])),
          this._redrawBounds.extend(t._pxBounds.max.add([i, i])));
      },
      _redraw: function () {
        (this._redrawRequest = null),
          this._redrawBounds &&
            (this._redrawBounds.min._floor(), this._redrawBounds.max._ceil()),
          this._clear(),
          this._draw(),
          (this._redrawBounds = null);
      },
      _clear: function () {
        let t,
          i = this._redrawBounds;
        i
          ? ((t = i.getSize()), this._ctx.clearRect(i.min.x, i.min.y, t.x, t.y))
          : (this._ctx.save(),
            this._ctx.setTransform(1, 0, 0, 1, 0, 0),
            this._ctx.clearRect(
              0,
              0,
              this._container.width,
              this._container.height
            ),
            this._ctx.restore());
      },
      _draw: function () {
        let t,
          i,
          e = this._redrawBounds;
        this._ctx.save(),
          e &&
            ((i = e.getSize()),
            this._ctx.beginPath(),
            this._ctx.rect(e.min.x, e.min.y, i.x, i.y),
            this._ctx.clip()),
          (this._drawing = !0);
        for (let n = this._drawFirst; n; n = n.next) {
          (t = n.layer),
            (!e || (t._pxBounds && t._pxBounds.intersects(e))) &&
              t._updatePath();
        }
        (this._drawing = !1), this._ctx.restore();
      },
      _updatePoly: function (t, i) {
        if (this._drawing) {
          let e,
            n,
            o,
            s,
            r = t._parts,
            a = r.length,
            h = this._ctx;
          if (a) {
            for (h.beginPath(), e = 0; e < a; e++) {
              for (n = 0, o = r[e].length; n < o; n++) {
                (s = r[e][n]), h[n ? "lineTo" : "moveTo"](s.x, s.y);
              }
              i && h.closePath();
            }
            this._fillStroke(h, t);
          }
        }
      },
      _updateCircle: function (t) {
        let i, e, n, o;
        this._drawing &&
          !t._empty() &&
          ((i = t._point),
          (e = this._ctx),
          (n = Math.max(Math.round(t._radius), 1)),
          1 != (o = (Math.max(Math.round(t._radiusY), 1) || n) / n) &&
            (e.save(), e.scale(1, o)),
          e.beginPath(),
          e.arc(i.x, i.y / o, n, 0, 2 * Math.PI, !1),
          1 != o && e.restore(),
          this._fillStroke(e, t));
      },
      _fillStroke: function (t, i) {
        let e = i.options;
        e.fill &&
          ((t.globalAlpha = e.fillOpacity),
          (t.fillStyle = e.fillColor || e.color),
          t.fill(e.fillRule || "evenodd")),
          e.stroke &&
            0 !== e.weight &&
            (t.setLineDash &&
              t.setLineDash((i.options && i.options._dashArray) || []),
            (t.globalAlpha = e.opacity),
            (t.lineWidth = e.weight),
            (t.strokeStyle = e.color),
            (t.lineCap = e.lineCap),
            (t.lineJoin = e.lineJoin),
            t.stroke());
      },
      _onClick: function (t) {
        for (
          var i,
            e,
            n = this._map.mouseEventToLayerPoint(t),
            o = this._drawFirst;
          o;
          o = o.next
        ) {
          (i = o.layer).options.interactive &&
            i._containsPoint(n) &&
            ((("click" === t.type || "preclick" !== t.type) &&
              this._map._draggableMoved(i)) ||
              (e = i));
        }
        e && (Fi(t), this._fireEvent([e], t));
      },
      _onMouseMove: function (t) {
        let i;
        !this._map ||
          this._map.dragging.moving() ||
          this._map._animatingZoom ||
          ((i = this._map.mouseEventToLayerPoint(t)),
          this._handleMouseHover(t, i));
      },
      _handleMouseOut: function (t) {
        let i = this._hoveredLayer;
        i &&
          (_i(this._container, "leaflet-interactive"),
          this._fireEvent([i], t, "mouseout"),
          (this._hoveredLayer = null),
          (this._mouseHoverThrottled = !1));
      },
      _handleMouseHover: function (t, i) {
        if (!this._mouseHoverThrottled) {
          for (var e, n, o = this._drawFirst; o; o = o.next) {
            (e = o.layer).options.interactive && e._containsPoint(i) && (n = e);
          }
          n !== this._hoveredLayer &&
            (this._handleMouseOut(t),
            n &&
              (ci(this._container, "leaflet-interactive"),
              this._fireEvent([n], t, "mouseover"),
              (this._hoveredLayer = n))),
            this._hoveredLayer && this._fireEvent([this._hoveredLayer], t),
            (this._mouseHoverThrottled = !0),
            setTimeout(
              p(function () {
                this._mouseHoverThrottled = !1;
              }, this),
              32
            );
        }
      },
      _fireEvent: function (t, i, e) {
        this._map._fireDOMEvent(i, e || i.type, t);
      },
      _bringToFront: function (t) {
        let i,
          e,
          n = t._order;
        n &&
          ((i = n.next),
          (e = n.prev),
          i &&
            ((i.prev = e) ? (e.next = i) : i && (this._drawFirst = i),
            (n.prev = this._drawLast),
            ((this._drawLast.next = n).next = null),
            (this._drawLast = n),
            this._requestRedraw(t)));
      },
      _bringToBack: function (t) {
        let i,
          e,
          n = t._order;
        n &&
          ((i = n.next),
          (e = n.prev) &&
            ((e.next = i) ? (i.prev = e) : e && (this._drawLast = e),
            (n.prev = null),
            (n.next = this._drawFirst),
            (this._drawFirst.prev = n),
            (this._drawFirst = n),
            this._requestRedraw(t)));
      },
    });
  function ln(t) {
    return St ? new un(t) : null;
  }
  let cn = (function () {
      try {
        return (
          document.namespaces.add("lvml", "urn:schemas-microsoft-com:vml"),
          function (t) {
            return document.createElement("<lvml:" + t + ' class="lvml">');
          }
        );
      } catch (t) {
        return function (t) {
          return document.createElement(
            "<" + t + ' xmlns="urn:schemas-microsoft.com:vml" class="lvml">'
          );
        };
      }
    })(),
    _n = {
      _initContainer: function () {
        this._container = si("div", "leaflet-vml-container");
      },
      _update: function () {
        this._map._animatingZoom ||
          (hn.prototype._update.call(this), this.fire("update"));
      },
      _initPath: function (t) {
        let i = (t._container = cn("shape"));
        ci(i, "leaflet-vml-shape " + (this.options.className || "")),
          (i.coordsize = "1 1"),
          (t._path = cn("path")),
          i.appendChild(t._path),
          this._updateStyle(t),
          (this._layers[m(t)] = t);
      },
      _addPath: function (t) {
        let i = t._container;
        this._container.appendChild(i),
          t.options.interactive && t.addInteractiveTarget(i);
      },
      _removePath: function (t) {
        let i = t._container;
        ri(i), t.removeInteractiveTarget(i), delete this._layers[m(t)];
      },
      _updateStyle: function (t) {
        let i = t._stroke,
          e = t._fill,
          n = t.options,
          o = t._container;
        (o.stroked = !!n.stroke),
          (o.filled = !!n.fill),
          n.stroke
            ? ((i = i || (t._stroke = cn("stroke"))),
              o.appendChild(i),
              (i.weight = n.weight + "px"),
              (i.color = n.color),
              (i.opacity = n.opacity),
              n.dashArray
                ? (i.dashStyle = g(n.dashArray)
                    ? n.dashArray.join(" ")
                    : n.dashArray.replace(/( *, *)/g, " "))
                : (i.dashStyle = ""),
              (i.endcap = n.lineCap.replace("butt", "flat")),
              (i.joinstyle = n.lineJoin))
            : i && (o.removeChild(i), (t._stroke = null)),
          n.fill
            ? ((e = e || (t._fill = cn("fill"))),
              o.appendChild(e),
              (e.color = n.fillColor || n.color),
              (e.opacity = n.fillOpacity))
            : e && (o.removeChild(e), (t._fill = null));
      },
      _updateCircle: function (t) {
        let i = t._point.round(),
          e = Math.round(t._radius),
          n = Math.round(t._radiusY || e);
        this._setPath(
          t,
          t._empty()
            ? "M0 0"
            : "AL " + i.x + "," + i.y + " " + e + "," + n + " 0,23592600"
        );
      },
      _setPath: function (t, i) {
        t._path.v = i;
      },
      _bringToFront: function (t) {
        hi(t._container);
      },
      _bringToBack: function (t) {
        ui(t._container);
      },
    },
    dn = Et ? cn : J,
    pn = hn.extend({
      getEvents: function () {
        let t = hn.prototype.getEvents.call(this);
        return (t.zoomstart = this._onZoomStart), t;
      },
      _initContainer: function () {
        (this._container = dn("svg")),
          this._container.setAttribute("pointer-events", "none"),
          (this._rootGroup = dn("g")),
          this._container.appendChild(this._rootGroup);
      },
      _destroyContainer: function () {
        ri(this._container),
          Si(this._container),
          delete this._container,
          delete this._rootGroup,
          delete this._svgSize;
      },
      _onZoomStart: function () {
        this._update();
      },
      _update: function () {
        let t, i, e;
        (this._map._animatingZoom && this._bounds) ||
          (hn.prototype._update.call(this),
          (i = (t = this._bounds).getSize()),
          (e = this._container),
          (this._svgSize && this._svgSize.equals(i)) ||
            ((this._svgSize = i),
            e.setAttribute("width", i.x),
            e.setAttribute("height", i.y)),
          vi(e, t.min),
          e.setAttribute("viewBox", [t.min.x, t.min.y, i.x, i.y].join(" ")),
          this.fire("update"));
      },
      _initPath: function (t) {
        let i = (t._path = dn("path"));
        t.options.className && ci(i, t.options.className),
          t.options.interactive && ci(i, "leaflet-interactive"),
          this._updateStyle(t),
          (this._layers[m(t)] = t);
      },
      _addPath: function (t) {
        this._rootGroup || this._initContainer(),
          this._rootGroup.appendChild(t._path),
          t.addInteractiveTarget(t._path);
      },
      _removePath: function (t) {
        ri(t._path),
          t.removeInteractiveTarget(t._path),
          delete this._layers[m(t)];
      },
      _updatePath: function (t) {
        t._project(), t._update();
      },
      _updateStyle: function (t) {
        let i = t._path,
          e = t.options;
        i &&
          (e.stroke
            ? (i.setAttribute("stroke", e.color),
              i.setAttribute("stroke-opacity", e.opacity),
              i.setAttribute("stroke-width", e.weight),
              i.setAttribute("stroke-linecap", e.lineCap),
              i.setAttribute("stroke-linejoin", e.lineJoin),
              e.dashArray
                ? i.setAttribute("stroke-dasharray", e.dashArray)
                : i.removeAttribute("stroke-dasharray"),
              e.dashOffset
                ? i.setAttribute("stroke-dashoffset", e.dashOffset)
                : i.removeAttribute("stroke-dashoffset"))
            : i.setAttribute("stroke", "none"),
          e.fill
            ? (i.setAttribute("fill", e.fillColor || e.color),
              i.setAttribute("fill-opacity", e.fillOpacity),
              i.setAttribute("fill-rule", e.fillRule || "evenodd"))
            : i.setAttribute("fill", "none"));
      },
      _updatePoly: function (t, i) {
        this._setPath(t, $(t._parts, i));
      },
      _updateCircle: function (t) {
        let i = t._point,
          e = Math.max(Math.round(t._radius), 1),
          n =
            "a" +
            e +
            "," +
            (Math.max(Math.round(t._radiusY), 1) || e) +
            " 0 1,0 ",
          o = t._empty()
            ? "M0 0"
            : "M" +
              (i.x - e) +
              "," +
              i.y +
              n +
              2 * e +
              ",0 " +
              n +
              2 * -e +
              ",0 ";
        this._setPath(t, o);
      },
      _setPath: function (t, i) {
        t._path.setAttribute("d", i);
      },
      _bringToFront: function (t) {
        hi(t._path);
      },
      _bringToBack: function (t) {
        ui(t._path);
      },
    });
  function mn(t) {
    return Zt || Et ? new pn(t) : null;
  }
  Et && pn.include(_n),
    Ki.include({
      getRenderer: function (t) {
        var i =
          (i =
            t.options.renderer ||
            this._getPaneRenderer(t.options.pane) ||
            this.options.renderer ||
            this._renderer) || (this._renderer = this._createRenderer());
        return this.hasLayer(i) || this.addLayer(i), i;
      },
      _getPaneRenderer: function (t) {
        if ("overlayPane" === t || void 0 === t) {
          return !1;
        }
        let i = this._paneRenderers[t];
        return (
          void 0 === i &&
            ((i = this._createRenderer({ pane: t })),
            (this._paneRenderers[t] = i)),
          i
        );
      },
      _createRenderer: function (t) {
        return (this.options.preferCanvas && ln(t)) || mn(t);
      },
    });
  let fn = Re.extend({
    initialize: function (t, i) {
      Re.prototype.initialize.call(this, this._boundsToLatLngs(t), i);
    },
    setBounds: function (t) {
      return this.setLatLngs(this._boundsToLatLngs(t));
    },
    _boundsToLatLngs: function (t) {
      return [
        (t = N(t)).getSouthWest(),
        t.getNorthWest(),
        t.getNorthEast(),
        t.getSouthEast(),
      ];
    },
  });
  (pn.create = dn),
    (pn.pointsToPath = $),
    (Ne.geometryToLayer = De),
    (Ne.coordsToLatLng = We),
    (Ne.coordsToLatLngs = He),
    (Ne.latLngToCoords = Fe),
    (Ne.latLngsToCoords = Ue),
    (Ne.getFeature = Ve),
    (Ne.asFeature = qe),
    Ki.mergeOptions({ boxZoom: !0 });
  let gn = ie.extend({
    initialize: function (t) {
      (this._map = t),
        (this._container = t._container),
        (this._pane = t._panes.overlayPane),
        (this._resetStateTimeout = 0),
        t.on("unload", this._destroy, this);
    },
    addHooks: function () {
      zi(this._container, "mousedown", this._onMouseDown, this);
    },
    removeHooks: function () {
      Si(this._container, "mousedown", this._onMouseDown, this);
    },
    moved: function () {
      return this._moved;
    },
    _destroy: function () {
      ri(this._pane), delete this._pane;
    },
    _resetState: function () {
      (this._resetStateTimeout = 0), (this._moved = !1);
    },
    _clearDeferredResetState: function () {
      0 !== this._resetStateTimeout &&
        (clearTimeout(this._resetStateTimeout), (this._resetStateTimeout = 0));
    },
    _onMouseDown: function (t) {
      if (!t.shiftKey || (1 !== t.which && 1 !== t.button)) {
        return !1;
      }
      this._clearDeferredResetState(),
        this._resetState(),
        Xt(),
        xi(),
        (this._startPoint = this._map.mouseEventToContainerPoint(t)),
        zi(
          document,
          {
            contextmenu: Ni,
            mousemove: this._onMouseMove,
            mouseup: this._onMouseUp,
            keydown: this._onKeyDown,
          },
          this
        );
    },
    _onMouseMove: function (t) {
      this._moved ||
        ((this._moved = !0),
        (this._box = si("div", "leaflet-zoom-box", this._container)),
        ci(this._container, "leaflet-crosshair"),
        this._map.fire("boxzoomstart")),
        (this._point = this._map.mouseEventToContainerPoint(t));
      let i = new I(this._point, this._startPoint),
        e = i.getSize();
      vi(this._box, i.min),
        (this._box.style.width = e.x + "px"),
        (this._box.style.height = e.y + "px");
    },
    _finish: function () {
      this._moved && (ri(this._box), _i(this._container, "leaflet-crosshair")),
        Jt(),
        wi(),
        Si(
          document,
          {
            contextmenu: Ni,
            mousemove: this._onMouseMove,
            mouseup: this._onMouseUp,
            keydown: this._onKeyDown,
          },
          this
        );
    },
    _onMouseUp: function (t) {
      let i;
      (1 !== t.which && 1 !== t.button) ||
        (this._finish(),
        this._moved &&
          (this._clearDeferredResetState(),
          (this._resetStateTimeout = setTimeout(p(this._resetState, this), 0)),
          (i = new R(
            this._map.containerPointToLatLng(this._startPoint),
            this._map.containerPointToLatLng(this._point)
          )),
          this._map.fitBounds(i).fire("boxzoomend", { boxZoomBounds: i })));
    },
    _onKeyDown: function (t) {
      27 === t.keyCode && this._finish();
    },
  });
  Ki.addInitHook("addHandler", "boxZoom", gn),
    Ki.mergeOptions({ doubleClickZoom: !0 });
  let vn = ie.extend({
    addHooks: function () {
      this._map.on("dblclick", this._onDoubleClick, this);
    },
    removeHooks: function () {
      this._map.off("dblclick", this._onDoubleClick, this);
    },
    _onDoubleClick: function (t) {
      let i = this._map,
        e = i.getZoom(),
        n = i.options.zoomDelta,
        o = t.originalEvent.shiftKey ? e - n : e + n;
      "center" === i.options.doubleClickZoom
        ? i.setZoom(o)
        : i.setZoomAround(t.containerPoint, o);
    },
  });
  Ki.addInitHook("addHandler", "doubleClickZoom", vn),
    Ki.mergeOptions({
      dragging: !0,
      inertia: !st,
      inertiaDeceleration: 3400,
      inertiaMaxSpeed: 1 / 0,
      easeLinearity: 0.2,
      worldCopyJump: !1,
      maxBoundsViscosity: 0,
    });
  let yn = ie.extend({
    addHooks: function () {
      let t;
      this._draggable ||
        ((t = this._map),
        (this._draggable = new ae(t._mapPane, t._container)),
        this._draggable.on(
          {
            dragstart: this._onDragStart,
            drag: this._onDrag,
            dragend: this._onDragEnd,
          },
          this
        ),
        this._draggable.on("predrag", this._onPreDragLimit, this),
        t.options.worldCopyJump &&
          (this._draggable.on("predrag", this._onPreDragWrap, this),
          t.on("zoomend", this._onZoomEnd, this),
          t.whenReady(this._onZoomEnd, this))),
        ci(this._map._container, "leaflet-grab leaflet-touch-drag"),
        this._draggable.enable(),
        (this._positions = []),
        (this._times = []);
    },
    removeHooks: function () {
      _i(this._map._container, "leaflet-grab"),
        _i(this._map._container, "leaflet-touch-drag"),
        this._draggable.disable();
    },
    moved: function () {
      return this._draggable && this._draggable._moved;
    },
    moving: function () {
      return this._draggable && this._draggable._moving;
    },
    _onDragStart: function () {
      let t,
        i = this._map;
      i._stop(),
        this._map.options.maxBounds && this._map.options.maxBoundsViscosity
          ? ((t = N(this._map.options.maxBounds)),
            (this._offsetLimit = O(
              this._map.latLngToContainerPoint(t.getNorthWest()).multiplyBy(-1),
              this._map
                .latLngToContainerPoint(t.getSouthEast())
                .multiplyBy(-1)
                .add(this._map.getSize())
            )),
            (this._viscosity = Math.min(
              1,
              Math.max(0, this._map.options.maxBoundsViscosity)
            )))
          : (this._offsetLimit = null),
        i.fire("movestart").fire("dragstart"),
        i.options.inertia && ((this._positions = []), (this._times = []));
    },
    _onDrag: function (t) {
      let i, e;
      this._map.options.inertia &&
        ((i = this._lastTime = +new Date()),
        (e = this._lastPos =
          this._draggable._absPos || this._draggable._newPos),
        this._positions.push(e),
        this._times.push(i),
        this._prunePositions(i)),
        this._map.fire("move", t).fire("drag", t);
    },
    _prunePositions: function (t) {
      for (; 1 < this._positions.length && 50 < t - this._times[0]; ) {
        this._positions.shift(), this._times.shift();
      }
    },
    _onZoomEnd: function () {
      let t = this._map.getSize().divideBy(2),
        i = this._map.latLngToLayerPoint([0, 0]);
      (this._initialWorldOffset = i.subtract(t).x),
        (this._worldWidth = this._map.getPixelWorldBounds().getSize().x);
    },
    _viscousLimit: function (t, i) {
      return t - (t - i) * this._viscosity;
    },
    _onPreDragLimit: function () {
      let t, i;
      this._viscosity &&
        this._offsetLimit &&
        ((t = this._draggable._newPos.subtract(this._draggable._startPos)),
        (i = this._offsetLimit),
        t.x < i.min.x && (t.x = this._viscousLimit(t.x, i.min.x)),
        t.y < i.min.y && (t.y = this._viscousLimit(t.y, i.min.y)),
        t.x > i.max.x && (t.x = this._viscousLimit(t.x, i.max.x)),
        t.y > i.max.y && (t.y = this._viscousLimit(t.y, i.max.y)),
        (this._draggable._newPos = this._draggable._startPos.add(t)));
    },
    _onPreDragWrap: function () {
      let t = this._worldWidth,
        i = Math.round(t / 2),
        e = this._initialWorldOffset,
        n = this._draggable._newPos.x,
        o = ((n - i + e) % t) + i - e,
        s = ((n + i + e) % t) - i - e,
        r = Math.abs(o + e) < Math.abs(s + e) ? o : s;
      (this._draggable._absPos = this._draggable._newPos.clone()),
        (this._draggable._newPos.x = r);
    },
    _onDragEnd: function (t) {
      let i,
        e,
        n,
        o,
        s,
        r,
        a,
        h,
        u,
        l = this._map,
        c = l.options,
        _ = !c.inertia || this._times.length < 2;
      l.fire("dragend", t),
        _
          ? l.fire("moveend")
          : (this._prunePositions(+new Date()),
            (i = this._lastPos.subtract(this._positions[0])),
            (e = (this._lastTime - this._times[0]) / 1e3),
            (n = c.easeLinearity),
            (s = (o = i.multiplyBy(n / e)).distanceTo([0, 0])),
            (r = Math.min(c.inertiaMaxSpeed, s)),
            (a = o.multiplyBy(r / s)),
            (h = r / (c.inertiaDeceleration * n)),
            (u = a.multiplyBy(-h / 2).round()).x || u.y
              ? ((u = l._limitOffset(u, l.options.maxBounds)),
                M(function () {
                  l.panBy(u, {
                    duration: h,
                    easeLinearity: n,
                    noMoveStart: !0,
                    animate: !0,
                  });
                }))
              : l.fire("moveend"));
    },
  });
  Ki.addInitHook("addHandler", "dragging", yn),
    Ki.mergeOptions({ keyboard: !0, keyboardPanDelta: 80 });
  let xn = ie.extend({
    keyCodes: {
      left: [37],
      right: [39],
      down: [40],
      up: [38],
      zoomIn: [187, 107, 61, 171],
      zoomOut: [189, 109, 54, 173],
    },
    initialize: function (t) {
      (this._map = t),
        this._setPanDelta(t.options.keyboardPanDelta),
        this._setZoomDelta(t.options.zoomDelta);
    },
    addHooks: function () {
      let t = this._map._container;
      t.tabIndex <= 0 && (t.tabIndex = "0"),
        zi(
          t,
          {
            focus: this._onFocus,
            blur: this._onBlur,
            mousedown: this._onMouseDown,
          },
          this
        ),
        this._map.on({ focus: this._addHooks, blur: this._removeHooks }, this);
    },
    removeHooks: function () {
      this._removeHooks(),
        Si(
          this._map._container,
          {
            focus: this._onFocus,
            blur: this._onBlur,
            mousedown: this._onMouseDown,
          },
          this
        ),
        this._map.off({ focus: this._addHooks, blur: this._removeHooks }, this);
    },
    _onMouseDown: function () {
      let t, i, e, n;
      this._focused ||
        ((t = document.body),
        (i = document.documentElement),
        (e = t.scrollTop || i.scrollTop),
        (n = t.scrollLeft || i.scrollLeft),
        this._map._container.focus(),
        window.scrollTo(n, e));
    },
    _onFocus: function () {
      (this._focused = !0), this._map.fire("focus");
    },
    _onBlur: function () {
      (this._focused = !1), this._map.fire("blur");
    },
    _setPanDelta: function (t) {
      for (
        var i = (this._panKeys = {}),
          e = this.keyCodes,
          n = 0,
          o = e.left.length;
        n < o;
        n++
      ) {
        i[e.left[n]] = [-1 * t, 0];
      }
      for (n = 0, o = e.right.length; n < o; n++) {
        i[e.right[n]] = [t, 0];
      }
      for (n = 0, o = e.down.length; n < o; n++) {
        i[e.down[n]] = [0, t];
      }
      for (n = 0, o = e.up.length; n < o; n++) {
        i[e.up[n]] = [0, -1 * t];
      }
    },
    _setZoomDelta: function (t) {
      for (
        var i = (this._zoomKeys = {}),
          e = this.keyCodes,
          n = 0,
          o = e.zoomIn.length;
        n < o;
        n++
      ) {
        i[e.zoomIn[n]] = t;
      }
      for (n = 0, o = e.zoomOut.length; n < o; n++) {
        i[e.zoomOut[n]] = -t;
      }
    },
    _addHooks: function () {
      zi(document, "keydown", this._onKeyDown, this);
    },
    _removeHooks: function () {
      Si(document, "keydown", this._onKeyDown, this);
    },
    _onKeyDown: function (t) {
      if (!(t.altKey || t.ctrlKey || t.metaKey)) {
        let i,
          e = t.keyCode,
          n = this._map;
        if (e in this._panKeys) {
          (n._panAnim && n._panAnim._inProgress) ||
            ((i = this._panKeys[e]),
            t.shiftKey && (i = A(i).multiplyBy(3)),
            n.panBy(i),
            n.options.maxBounds && n.panInsideBounds(n.options.maxBounds));
        } else if (e in this._zoomKeys) {
          n.setZoom(n.getZoom() + (t.shiftKey ? 3 : 1) * this._zoomKeys[e]);
        } else {
          if (27 !== e || !n._popup || !n._popup.options.closeOnEscapeKey) {
            return;
          }
          n.closePopup();
        }
        Ni(t);
      }
    },
  });
  Ki.addInitHook("addHandler", "keyboard", xn),
    Ki.mergeOptions({
      scrollWheelZoom: !0,
      wheelDebounceTime: 40,
      wheelPxPerZoomLevel: 60,
    });
  let wn = ie.extend({
    addHooks: function () {
      zi(this._map._container, "wheel", this._onWheelScroll, this),
        (this._delta = 0);
    },
    removeHooks: function () {
      Si(this._map._container, "wheel", this._onWheelScroll, this);
    },
    _onWheelScroll: function (t) {
      let i = Wi(t),
        e = this._map.options.wheelDebounceTime;
      (this._delta += i),
        (this._lastMousePos = this._map.mouseEventToContainerPoint(t)),
        this._startTime || (this._startTime = +new Date());
      let n = Math.max(e - (new Date() - this._startTime), 0);
      clearTimeout(this._timer),
        (this._timer = setTimeout(p(this._performZoom, this), n)),
        Ni(t);
    },
    _performZoom: function () {
      let t = this._map,
        i = t.getZoom(),
        e = this._map.options.zoomSnap || 0;
      t._stop();
      let n = this._delta / (4 * this._map.options.wheelPxPerZoomLevel),
        o = (4 * Math.log(2 / (1 + Math.exp(-Math.abs(n))))) / Math.LN2,
        s = e ? Math.ceil(o / e) * e : o,
        r = t._limitZoom(i + (0 < this._delta ? s : -s)) - i;
      (this._delta = 0),
        (this._startTime = null),
        r &&
          ("center" === t.options.scrollWheelZoom
            ? t.setZoom(i + r)
            : t.setZoomAround(this._lastMousePos, i + r));
    },
  });
  Ki.addInitHook("addHandler", "scrollWheelZoom", wn),
    Ki.mergeOptions({ tap: !0, tapTolerance: 15 });
  let Pn = ie.extend({
    addHooks: function () {
      zi(this._map._container, "touchstart", this._onDown, this);
    },
    removeHooks: function () {
      Si(this._map._container, "touchstart", this._onDown, this);
    },
    _onDown: function (t) {
      if (t.touches) {
        if ((Ri(t), (this._fireClick = !0), 1 < t.touches.length)) {
          return (this._fireClick = !1), void clearTimeout(this._holdTimeout);
        }
        let i = t.touches[0],
          e = i.target;
        (this._startPos = this._newPos = new k(i.clientX, i.clientY)),
          e.tagName &&
            "a" === e.tagName.toLowerCase() &&
            ci(e, "leaflet-active"),
          (this._holdTimeout = setTimeout(
            p(function () {
              this._isTapValid() &&
                ((this._fireClick = !1),
                this._onUp(),
                this._simulateEvent("contextmenu", i));
            }, this),
            1e3
          )),
          this._simulateEvent("mousedown", i),
          zi(document, { touchmove: this._onMove, touchend: this._onUp }, this);
      }
    },
    _onUp: function (t) {
      let i, e;
      clearTimeout(this._holdTimeout),
        Si(document, { touchmove: this._onMove, touchend: this._onUp }, this),
        this._fireClick &&
          t &&
          t.changedTouches &&
          ((e = (i = t.changedTouches[0]).target) &&
            e.tagName &&
            "a" === e.tagName.toLowerCase() &&
            _i(e, "leaflet-active"),
          this._simulateEvent("mouseup", i),
          this._isTapValid() && this._simulateEvent("click", i));
    },
    _isTapValid: function () {
      return (
        this._newPos.distanceTo(this._startPos) <=
        this._map.options.tapTolerance
      );
    },
    _onMove: function (t) {
      let i = t.touches[0];
      (this._newPos = new k(i.clientX, i.clientY)),
        this._simulateEvent("mousemove", i);
    },
    _simulateEvent: function (t, i) {
      let e = document.createEvent("MouseEvents");
      (e._simulated = !0),
        (i.target._simulatedClick = !0),
        e.initMouseEvent(
          t,
          !0,
          !0,
          window,
          1,
          i.screenX,
          i.screenY,
          i.clientX,
          i.clientY,
          !1,
          !1,
          !1,
          !1,
          0,
          null
        ),
        i.target.dispatchEvent(e);
    },
  });
  !bt || (Lt && !ct) || Ki.addInitHook("addHandler", "tap", Pn),
    Ki.mergeOptions({ touchZoom: bt && !st, bounceAtZoomLimits: !0 });
  let Ln = ie.extend({
    addHooks: function () {
      ci(this._map._container, "leaflet-touch-zoom"),
        zi(this._map._container, "touchstart", this._onTouchStart, this);
    },
    removeHooks: function () {
      _i(this._map._container, "leaflet-touch-zoom"),
        Si(this._map._container, "touchstart", this._onTouchStart, this);
    },
    _onTouchStart: function (t) {
      let i,
        e,
        n = this._map;
      !t.touches ||
        2 !== t.touches.length ||
        n._animatingZoom ||
        this._zooming ||
        ((i = n.mouseEventToContainerPoint(t.touches[0])),
        (e = n.mouseEventToContainerPoint(t.touches[1])),
        (this._centerPoint = n.getSize()._divideBy(2)),
        (this._startLatLng = n.containerPointToLatLng(this._centerPoint)),
        "center" !== n.options.touchZoom &&
          (this._pinchStartLatLng = n.containerPointToLatLng(
            i.add(e)._divideBy(2)
          )),
        (this._startDist = i.distanceTo(e)),
        (this._startZoom = n.getZoom()),
        (this._moved = !1),
        (this._zooming = !0),
        n._stop(),
        zi(document, "touchmove", this._onTouchMove, this),
        zi(document, "touchend", this._onTouchEnd, this),
        Ri(t));
    },
    _onTouchMove: function (t) {
      if (t.touches && 2 === t.touches.length && this._zooming) {
        let i = this._map,
          e = i.mouseEventToContainerPoint(t.touches[0]),
          n = i.mouseEventToContainerPoint(t.touches[1]),
          o = e.distanceTo(n) / this._startDist;
        if (
          ((this._zoom = i.getScaleZoom(o, this._startZoom)),
          !i.options.bounceAtZoomLimits &&
            ((this._zoom < i.getMinZoom() && o < 1) ||
              (this._zoom > i.getMaxZoom() && 1 < o)) &&
            (this._zoom = i._limitZoom(this._zoom)),
          "center" === i.options.touchZoom)
        ) {
          if (((this._center = this._startLatLng), 1 == o)) {
            return;
          }
        } else {
          let s = e._add(n)._divideBy(2)._subtract(this._centerPoint);
          if (1 == o && 0 === s.x && 0 === s.y) {
            return;
          }
          this._center = i.unproject(
            i.project(this._pinchStartLatLng, this._zoom).subtract(s),
            this._zoom
          );
        }
        this._moved || (i._moveStart(!0, !1), (this._moved = !0)),
          z(this._animRequest);
        let r = p(i._move, i, this._center, this._zoom, {
          pinch: !0,
          round: !1,
        });
        (this._animRequest = M(r, this, !0)), Ri(t);
      }
    },
    _onTouchEnd: function () {
      this._moved && this._zooming
        ? ((this._zooming = !1),
          z(this._animRequest),
          Si(document, "touchmove", this._onTouchMove, this),
          Si(document, "touchend", this._onTouchEnd, this),
          this._map.options.zoomAnimation
            ? this._map._animateZoom(
                this._center,
                this._map._limitZoom(this._zoom),
                !0,
                this._map.options.zoomSnap
              )
            : this._map._resetView(
                this._center,
                this._map._limitZoom(this._zoom)
              ))
        : (this._zooming = !1);
    },
  });
  Ki.addInitHook("addHandler", "touchZoom", Ln),
    (Ki.BoxZoom = gn),
    (Ki.DoubleClickZoom = vn),
    (Ki.Drag = yn),
    (Ki.Keyboard = xn),
    (Ki.ScrollWheelZoom = wn),
    (Ki.Tap = Pn),
    (Ki.TouchZoom = Ln),
    (t.version = "1.7.1"),
    (t.Control = Xi),
    (t.control = Yi),
    (t.Browser = Bt),
    (t.Evented = E),
    (t.Mixin = ne),
    (t.Util = C),
    (t.Class = S),
    (t.Handler = ie),
    (t.extend = h),
    (t.bind = p),
    (t.stamp = m),
    (t.setOptions = c),
    (t.DomEvent = qi),
    (t.DomUtil = Mi),
    (t.PosAnimation = Gi),
    (t.Draggable = ae),
    (t.LineUtil = fe),
    (t.PolyUtil = ye),
    (t.Point = k),
    (t.point = A),
    (t.Bounds = I),
    (t.bounds = O),
    (t.Transformation = q),
    (t.transformation = G),
    (t.Projection = Pe),
    (t.LatLng = D),
    (t.latLng = j),
    (t.LatLngBounds = R),
    (t.latLngBounds = N),
    (t.CRS = H),
    (t.GeoJSON = Ne),
    (t.geoJSON = Ke),
    (t.geoJson = Ye),
    (t.Layer = Me),
    (t.LayerGroup = ze),
    (t.layerGroup = function (t, i) {
      return new ze(t, i);
    }),
    (t.FeatureGroup = Ce),
    (t.featureGroup = function (t, i) {
      return new Ce(t, i);
    }),
    (t.ImageOverlay = Xe),
    (t.imageOverlay = function (t, i, e) {
      return new Xe(t, i, e);
    }),
    (t.VideoOverlay = Je),
    (t.videoOverlay = function (t, i, e) {
      return new Je(t, i, e);
    }),
    (t.SVGOverlay = $e),
    (t.svgOverlay = function (t, i, e) {
      return new $e(t, i, e);
    }),
    (t.DivOverlay = Qe),
    (t.Popup = tn),
    (t.popup = function (t, i) {
      return new tn(t, i);
    }),
    (t.Tooltip = en),
    (t.tooltip = function (t, i) {
      return new en(t, i);
    }),
    (t.Icon = Se),
    (t.icon = function (t) {
      return new Se(t);
    }),
    (t.DivIcon = nn),
    (t.divIcon = function (t) {
      return new nn(t);
    }),
    (t.Marker = ke),
    (t.marker = function (t, i) {
      return new ke(t, i);
    }),
    (t.TileLayer = sn),
    (t.tileLayer = rn),
    (t.GridLayer = on),
    (t.gridLayer = function (t) {
      return new on(t);
    }),
    (t.SVG = pn),
    (t.svg = mn),
    (t.Renderer = hn),
    (t.Canvas = un),
    (t.canvas = ln),
    (t.Path = Be),
    (t.CircleMarker = Ae),
    (t.circleMarker = function (t, i) {
      return new Ae(t, i);
    }),
    (t.Circle = Ie),
    (t.circle = function (t, i, e) {
      return new Ie(t, i, e);
    }),
    (t.Polyline = Oe),
    (t.polyline = function (t, i) {
      return new Oe(t, i);
    }),
    (t.Polygon = Re),
    (t.polygon = function (t, i) {
      return new Re(t, i);
    }),
    (t.Rectangle = fn),
    (t.rectangle = function (t, i) {
      return new fn(t, i);
    }),
    (t.Map = Ki),
    (t.map = function (t, i) {
      return new Ki(t, i);
    });
  let bn = window.L;
  (t.noConflict = function () {
    return (window.L = bn), this;
  }),
    (window.L = t);
});

/*
 Leaflet.markercluster, Provides Beautiful Animated Marker Clustering functionality for Leaflet, a JS library for interactive maps.
 https://github.com/Leaflet/Leaflet.markercluster
 (c) 2012-2013, Dave Leaver, smartrak
*/

!(function (e, t, i) {
  (L.MarkerClusterGroup = L.FeatureGroup.extend({
    options: {
      maxClusterRadius: 80,
      iconCreateFunction: null,
      spiderfyOnMaxZoom: !0,
      showCoverageOnHover: !0,
      zoomToBoundsOnClick: !0,
      singleMarkerMode: !1,
      disableClusteringAtZoom: null,
      removeOutsideVisibleBounds: !0,
      animate: !0,
      animateAddingMarkers: !1,
      spiderfyDistanceMultiplier: 1,
      spiderLegPolylineOptions: { weight: 1.5, color: "#222", opacity: 0.5 },
      chunkedLoading: !1,
      chunkInterval: 200,
      chunkDelay: 50,
      chunkProgress: null,
      polygonOptions: {},
    },
    initialize: function (e) {
      L.Util.setOptions(this, e),
        this.options.iconCreateFunction ||
          (this.options.iconCreateFunction = this._defaultIconCreateFunction),
        this.options.clusterPane ||
          (this.options.clusterPane = L.Marker.prototype.options.pane),
        (this._featureGroup = L.featureGroup()),
        this._featureGroup.addEventParent(this),
        (this._nonPointGroup = L.featureGroup()),
        this._nonPointGroup.addEventParent(this),
        (this._inZoomAnimation = 0),
        (this._needsClustering = []),
        (this._needsRemoving = []),
        (this._currentShownBounds = null),
        (this._queue = []),
        (this._childMarkerEventHandlers = {
          dragstart: this._childMarkerDragStart,
          move: this._childMarkerMoved,
          dragend: this._childMarkerDragEnd,
        });
      let t = L.DomUtil.TRANSITION && this.options.animate;
      L.extend(this, t ? this._withAnimation : this._noAnimation),
        (this._markerCluster = t
          ? L.MarkerCluster
          : L.MarkerClusterNonAnimated);
    },
    addLayer: function (e) {
      if (e instanceof L.LayerGroup) {
        return this.addLayers([e]);
      }
      if (!e.getLatLng) {
        return (
          this._nonPointGroup.addLayer(e),
          this.fire("layeradd", { layer: e }),
          this
        );
      }
      if (!this._map) {
        return (
          this._needsClustering.push(e),
          this.fire("layeradd", { layer: e }),
          this
        );
      }
      if (this.hasLayer(e)) {
        return this;
      }
      this._unspiderfy && this._unspiderfy(),
        this._addLayer(e, this._maxZoom),
        this.fire("layeradd", { layer: e }),
        this._topClusterLevel._recalculateBounds(),
        this._refreshClustersIcons();
      let t = e,
        i = this._zoom;
      if (e.__parent) {
        for (; t.__parent._zoom >= i; ) {
          t = t.__parent;
        }
      }
      return (
        this._currentShownBounds.contains(t.getLatLng()) &&
          (this.options.animateAddingMarkers
            ? this._animationAddLayer(e, t)
            : this._animationAddLayerNonAnimated(e, t)),
        this
      );
    },
    removeLayer: function (e) {
      return e instanceof L.LayerGroup
        ? this.removeLayers([e])
        : e.getLatLng
        ? this._map
          ? e.__parent
            ? (this._unspiderfy &&
                (this._unspiderfy(), this._unspiderfyLayer(e)),
              this._removeLayer(e, !0),
              this.fire("layerremove", { layer: e }),
              this._topClusterLevel._recalculateBounds(),
              this._refreshClustersIcons(),
              e.off(this._childMarkerEventHandlers, this),
              this._featureGroup.hasLayer(e) &&
                (this._featureGroup.removeLayer(e),
                e.clusterShow && e.clusterShow()),
              this)
            : this
          : (!this._arraySplice(this._needsClustering, e) &&
              this.hasLayer(e) &&
              this._needsRemoving.push({ layer: e, latlng: e._latlng }),
            this.fire("layerremove", { layer: e }),
            this)
        : (this._nonPointGroup.removeLayer(e),
          this.fire("layerremove", { layer: e }),
          this);
    },
    addLayers: function (e, t) {
      if (!L.Util.isArray(e)) {
        return this.addLayer(e);
      }
      let i,
        n = this._featureGroup,
        r = this._nonPointGroup,
        s = this.options.chunkedLoading,
        o = this.options.chunkInterval,
        a = this.options.chunkProgress,
        h = e.length,
        l = 0,
        u = !0;
      if (this._map) {
        var _ = new Date().getTime(),
          d = L.bind(function () {
            for (let c = new Date().getTime(); h > l; l++) {
              if (s && 0 === l % 200) {
                let p = new Date().getTime() - c;
                if (p > o) {
                  break;
                }
              }
              if (((i = e[l]), i instanceof L.LayerGroup)) {
                u && ((e = e.slice()), (u = !1)),
                  this._extractNonGroupLayers(i, e),
                  (h = e.length);
              } else if (i.getLatLng) {
                if (
                  !this.hasLayer(i) &&
                  (this._addLayer(i, this._maxZoom),
                  t || this.fire("layeradd", { layer: i }),
                  i.__parent && 2 === i.__parent.getChildCount())
                ) {
                  let f = i.__parent.getAllChildMarkers(),
                    m = f[0] === i ? f[1] : f[0];
                  n.removeLayer(m);
                }
              } else {
                r.addLayer(i), t || this.fire("layeradd", { layer: i });
              }
            }
            a && a(l, h, new Date().getTime() - _),
              l === h
                ? (this._topClusterLevel._recalculateBounds(),
                  this._refreshClustersIcons(),
                  this._topClusterLevel._recursivelyAddChildrenToMap(
                    null,
                    this._zoom,
                    this._currentShownBounds
                  ))
                : setTimeout(d, this.options.chunkDelay);
          }, this);
        d();
      } else {
        for (let c = this._needsClustering; h > l; l++) {
          (i = e[l]),
            i instanceof L.LayerGroup
              ? (u && ((e = e.slice()), (u = !1)),
                this._extractNonGroupLayers(i, e),
                (h = e.length))
              : i.getLatLng
              ? this.hasLayer(i) || c.push(i)
              : r.addLayer(i);
        }
      }
      return this;
    },
    removeLayers: function (e) {
      let t,
        i,
        n = e.length,
        r = this._featureGroup,
        s = this._nonPointGroup,
        o = !0;
      if (!this._map) {
        for (t = 0; n > t; t++) {
          (i = e[t]),
            i instanceof L.LayerGroup
              ? (o && ((e = e.slice()), (o = !1)),
                this._extractNonGroupLayers(i, e),
                (n = e.length))
              : (this._arraySplice(this._needsClustering, i),
                s.removeLayer(i),
                this.hasLayer(i) &&
                  this._needsRemoving.push({ layer: i, latlng: i._latlng }),
                this.fire("layerremove", { layer: i }));
        }
        return this;
      }
      if (this._unspiderfy) {
        this._unspiderfy();
        let a = e.slice(),
          h = n;
        for (t = 0; h > t; t++) {
          (i = a[t]),
            i instanceof L.LayerGroup
              ? (this._extractNonGroupLayers(i, a), (h = a.length))
              : this._unspiderfyLayer(i);
        }
      }
      for (t = 0; n > t; t++) {
        (i = e[t]),
          i instanceof L.LayerGroup
            ? (o && ((e = e.slice()), (o = !1)),
              this._extractNonGroupLayers(i, e),
              (n = e.length))
            : i.__parent
            ? (this._removeLayer(i, !0, !0),
              this.fire("layerremove", { layer: i }),
              r.hasLayer(i) &&
                (r.removeLayer(i), i.clusterShow && i.clusterShow()))
            : (s.removeLayer(i), this.fire("layerremove", { layer: i }));
      }
      return (
        this._topClusterLevel._recalculateBounds(),
        this._refreshClustersIcons(),
        this._topClusterLevel._recursivelyAddChildrenToMap(
          null,
          this._zoom,
          this._currentShownBounds
        ),
        this
      );
    },
    clearLayers: function () {
      return (
        this._map ||
          ((this._needsClustering = []),
          delete this._gridClusters,
          delete this._gridUnclustered),
        this._noanimationUnspiderfy && this._noanimationUnspiderfy(),
        this._featureGroup.clearLayers(),
        this._nonPointGroup.clearLayers(),
        this.eachLayer(function (e) {
          e.off(this._childMarkerEventHandlers, this), delete e.__parent;
        }, this),
        this._map && this._generateInitialClusters(),
        this
      );
    },
    getBounds: function () {
      let e = new L.LatLngBounds();
      this._topClusterLevel && e.extend(this._topClusterLevel._bounds);
      for (let t = this._needsClustering.length - 1; t >= 0; t--) {
        e.extend(this._needsClustering[t].getLatLng());
      }
      return e.extend(this._nonPointGroup.getBounds()), e;
    },
    eachLayer: function (e, t) {
      let i,
        n,
        r,
        s = this._needsClustering.slice(),
        o = this._needsRemoving;
      for (
        this._topClusterLevel && this._topClusterLevel.getAllChildMarkers(s),
          n = s.length - 1;
        n >= 0;
        n--
      ) {
        for (i = !0, r = o.length - 1; r >= 0; r--) {
          if (o[r].layer === s[n]) {
            i = !1;
            break;
          }
        }
        i && e.call(t, s[n]);
      }
      this._nonPointGroup.eachLayer(e, t);
    },
    getLayers: function () {
      let e = [];
      return (
        this.eachLayer(function (t) {
          e.push(t);
        }),
        e
      );
    },
    getLayer: function (e) {
      let t = null;
      return (
        (e = parseInt(e, 10)),
        this.eachLayer(function (i) {
          L.stamp(i) === e && (t = i);
        }),
        t
      );
    },
    hasLayer: function (e) {
      if (!e) {
        return !1;
      }
      let t,
        i = this._needsClustering;
      for (t = i.length - 1; t >= 0; t--) {
        if (i[t] === e) {
          return !0;
        }
      }
      for (i = this._needsRemoving, t = i.length - 1; t >= 0; t--) {
        if (i[t].layer === e) {
          return !1;
        }
      }
      return (
        !(!e.__parent || e.__parent._group !== this) ||
        this._nonPointGroup.hasLayer(e)
      );
    },
    zoomToShowLayer: function (e, t) {
      "function" !== typeof t && (t = function () {});
      var i = function () {
        (!e._icon && !e.__parent._icon) ||
          this._inZoomAnimation ||
          (this._map.off("moveend", i, this),
          this.off("animationend", i, this),
          e._icon
            ? t()
            : e.__parent._icon &&
              (this.once("spiderfied", t, this), e.__parent.spiderfy()));
      };
      e._icon && this._map.getBounds().contains(e.getLatLng())
        ? t()
        : e.__parent._zoom < Math.round(this._map._zoom)
        ? (this._map.on("moveend", i, this), this._map.panTo(e.getLatLng()))
        : (this._map.on("moveend", i, this),
          this.on("animationend", i, this),
          e.__parent.zoomToBounds());
    },
    onAdd: function (e) {
      this._map = e;
      let t, i, n;
      if (!isFinite(this._map.getMaxZoom())) {
        throw "Map has no maxZoom specified";
      }
      for (
        this._featureGroup.addTo(e),
          this._nonPointGroup.addTo(e),
          this._gridClusters || this._generateInitialClusters(),
          this._maxLat = e.options.crs.projection.MAX_LATITUDE,
          t = 0,
          i = this._needsRemoving.length;
        i > t;
        t++
      ) {
        (n = this._needsRemoving[t]),
          (n.newlatlng = n.layer._latlng),
          (n.layer._latlng = n.latlng);
      }
      for (t = 0, i = this._needsRemoving.length; i > t; t++) {
        (n = this._needsRemoving[t]),
          this._removeLayer(n.layer, !0),
          (n.layer._latlng = n.newlatlng);
      }
      (this._needsRemoving = []),
        (this._zoom = Math.round(this._map._zoom)),
        (this._currentShownBounds = this._getExpandedVisibleBounds()),
        this._map.on("zoomend", this._zoomEnd, this),
        this._map.on("moveend", this._moveEnd, this),
        this._spiderfierOnAdd && this._spiderfierOnAdd(),
        this._bindEvents(),
        (i = this._needsClustering),
        (this._needsClustering = []),
        this.addLayers(i, !0);
    },
    onRemove: function (e) {
      e.off("zoomend", this._zoomEnd, this),
        e.off("moveend", this._moveEnd, this),
        this._unbindEvents(),
        (this._map._mapPane.className = this._map._mapPane.className.replace(
          " leaflet-cluster-anim",
          ""
        )),
        this._spiderfierOnRemove && this._spiderfierOnRemove(),
        delete this._maxLat,
        this._hideCoverage(),
        this._featureGroup.remove(),
        this._nonPointGroup.remove(),
        this._featureGroup.clearLayers(),
        (this._map = null);
    },
    getVisibleParent: function (e) {
      for (var t = e; t && !t._icon; ) {
        t = t.__parent;
      }
      return t || null;
    },
    _arraySplice: function (e, t) {
      for (let i = e.length - 1; i >= 0; i--) {
        if (e[i] === t) {
          return e.splice(i, 1), !0;
        }
      }
    },
    _removeFromGridUnclustered: function (e, t) {
      for (
        let i = this._map,
          n = this._gridUnclustered,
          r = Math.floor(this._map.getMinZoom());
        t >= r && n[t].removeObject(e, i.project(e.getLatLng(), t));
        t--
      ) {}
    },
    _childMarkerDragStart: function (e) {
      e.target.__dragStart = e.target._latlng;
    },
    _childMarkerMoved: function (e) {
      if (!this._ignoreMove && !e.target.__dragStart) {
        let t = e.target._popup && e.target._popup.isOpen();
        this._moveChild(e.target, e.oldLatLng, e.latlng),
          t && e.target.openPopup();
      }
    },
    _moveChild: function (e, t, i) {
      (e._latlng = t), this.removeLayer(e), (e._latlng = i), this.addLayer(e);
    },
    _childMarkerDragEnd: function (e) {
      e.target.__dragStart &&
        this._moveChild(e.target, e.target.__dragStart, e.target._latlng),
        delete e.target.__dragStart;
    },
    _removeLayer: function (e, t, i) {
      let n = this._gridClusters,
        r = this._gridUnclustered,
        s = this._featureGroup,
        o = this._map,
        a = Math.floor(this._map.getMinZoom());
      t && this._removeFromGridUnclustered(e, this._maxZoom);
      let h,
        l = e.__parent,
        u = l._markers;
      for (
        this._arraySplice(u, e);
        l && (l._childCount--, (l._boundsNeedUpdate = !0), !(l._zoom < a));

      ) {
        t && l._childCount <= 1
          ? ((h = l._markers[0] === e ? l._markers[1] : l._markers[0]),
            n[l._zoom].removeObject(l, o.project(l._cLatLng, l._zoom)),
            r[l._zoom].addObject(h, o.project(h.getLatLng(), l._zoom)),
            this._arraySplice(l.__parent._childClusters, l),
            l.__parent._markers.push(h),
            (h.__parent = l.__parent),
            l._icon && (s.removeLayer(l), i || s.addLayer(h)))
          : (l._iconNeedsUpdate = !0),
          (l = l.__parent);
      }
      delete e.__parent;
    },
    _isOrIsParent: function (e, t) {
      for (; t; ) {
        if (e === t) {
          return !0;
        }
        t = t.parentNode;
      }
      return !1;
    },
    fire: function (e, t, i) {
      if (t && t.layer instanceof L.MarkerCluster) {
        if (
          t.originalEvent &&
          this._isOrIsParent(t.layer._icon, t.originalEvent.relatedTarget)
        ) {
          return;
        }
        e = "cluster" + e;
      }
      L.FeatureGroup.prototype.fire.call(this, e, t, i);
    },
    listens: function (e, t) {
      return (
        L.FeatureGroup.prototype.listens.call(this, e, t) ||
        L.FeatureGroup.prototype.listens.call(this, "cluster" + e, t)
      );
    },
    _defaultIconCreateFunction: function (e) {
      let t = e.getChildCount(),
        i = " marker-cluster-";
      return (
        (i += 10 > t ? "small" : 100 > t ? "medium" : "large"),
        new L.DivIcon({
          html: "<div><span>" + t + "</span></div>",
          className: "marker-cluster" + i,
          iconSize: new L.Point(40, 40),
        })
      );
    },
    _bindEvents: function () {
      let e = this._map,
        t = this.options.spiderfyOnMaxZoom,
        i = this.options.showCoverageOnHover,
        n = this.options.zoomToBoundsOnClick;
      (t || n) && this.on("clusterclick", this._zoomOrSpiderfy, this),
        i &&
          (this.on("clustermouseover", this._showCoverage, this),
          this.on("clustermouseout", this._hideCoverage, this),
          e.on("zoomend", this._hideCoverage, this));
    },
    _zoomOrSpiderfy: function (e) {
      for (var t = e.layer, i = t; 1 === i._childClusters.length; ) {
        i = i._childClusters[0];
      }
      i._zoom === this._maxZoom &&
      i._childCount === t._childCount &&
      this.options.spiderfyOnMaxZoom
        ? t.spiderfy()
        : this.options.zoomToBoundsOnClick && t.zoomToBounds(),
        e.originalEvent &&
          13 === e.originalEvent.keyCode &&
          this._map._container.focus();
    },
    _showCoverage: function (e) {
      let t = this._map;
      this._inZoomAnimation ||
        (this._shownPolygon && t.removeLayer(this._shownPolygon),
        e.layer.getChildCount() > 2 &&
          e.layer !== this._spiderfied &&
          ((this._shownPolygon = new L.Polygon(
            e.layer.getConvexHull(),
            this.options.polygonOptions
          )),
          t.addLayer(this._shownPolygon)));
    },
    _hideCoverage: function () {
      this._shownPolygon &&
        (this._map.removeLayer(this._shownPolygon),
        (this._shownPolygon = null));
    },
    _unbindEvents: function () {
      let e = this.options.spiderfyOnMaxZoom,
        t = this.options.showCoverageOnHover,
        i = this.options.zoomToBoundsOnClick,
        n = this._map;
      (e || i) && this.off("clusterclick", this._zoomOrSpiderfy, this),
        t &&
          (this.off("clustermouseover", this._showCoverage, this),
          this.off("clustermouseout", this._hideCoverage, this),
          n.off("zoomend", this._hideCoverage, this));
    },
    _zoomEnd: function () {
      this._map &&
        (this._mergeSplitClusters(),
        (this._zoom = Math.round(this._map._zoom)),
        (this._currentShownBounds = this._getExpandedVisibleBounds()));
    },
    _moveEnd: function () {
      if (!this._inZoomAnimation) {
        let e = this._getExpandedVisibleBounds();
        this._topClusterLevel._recursivelyRemoveChildrenFromMap(
          this._currentShownBounds,
          Math.floor(this._map.getMinZoom()),
          this._zoom,
          e
        ),
          this._topClusterLevel._recursivelyAddChildrenToMap(
            null,
            Math.round(this._map._zoom),
            e
          ),
          (this._currentShownBounds = e);
      }
    },
    _generateInitialClusters: function () {
      let e = Math.ceil(this._map.getMaxZoom()),
        t = Math.floor(this._map.getMinZoom()),
        i = this.options.maxClusterRadius,
        n = i;
      "function" !== typeof i &&
        (n = function () {
          return i;
        }),
        null !== this.options.disableClusteringAtZoom &&
          (e = this.options.disableClusteringAtZoom - 1),
        (this._maxZoom = e),
        (this._gridClusters = {}),
        (this._gridUnclustered = {});
      for (let r = e; r >= t; r--) {
        (this._gridClusters[r] = new L.DistanceGrid(n(r))),
          (this._gridUnclustered[r] = new L.DistanceGrid(n(r)));
      }
      this._topClusterLevel = new this._markerCluster(this, t - 1);
    },
    _addLayer: function (e, t) {
      let i,
        n,
        r = this._gridClusters,
        s = this._gridUnclustered,
        o = Math.floor(this._map.getMinZoom());
      for (
        this.options.singleMarkerMode && this._overrideMarkerIcon(e),
          e.on(this._childMarkerEventHandlers, this);
        t >= o;
        t--
      ) {
        i = this._map.project(e.getLatLng(), t);
        let a = r[t].getNearObject(i);
        if (a) {
          return a._addChild(e), (e.__parent = a), void 0;
        }
        if ((a = s[t].getNearObject(i))) {
          let h = a.__parent;
          h && this._removeLayer(a, !1);
          let l = new this._markerCluster(this, t, a, e);
          r[t].addObject(l, this._map.project(l._cLatLng, t)),
            (a.__parent = l),
            (e.__parent = l);
          let u = l;
          for (n = t - 1; n > h._zoom; n--) {
            (u = new this._markerCluster(this, n, u)),
              r[n].addObject(u, this._map.project(a.getLatLng(), n));
          }
          return h._addChild(u), this._removeFromGridUnclustered(a, t), void 0;
        }
        s[t].addObject(e, i);
      }
      this._topClusterLevel._addChild(e), (e.__parent = this._topClusterLevel);
    },
    _refreshClustersIcons: function () {
      this._featureGroup.eachLayer(function (e) {
        e instanceof L.MarkerCluster && e._iconNeedsUpdate && e._updateIcon();
      });
    },
    _enqueue: function (e) {
      this._queue.push(e),
        this._queueTimeout ||
          (this._queueTimeout = setTimeout(
            L.bind(this._processQueue, this),
            300
          ));
    },
    _processQueue: function () {
      for (let e = 0; e < this._queue.length; e++) {
        this._queue[e].call(this);
      }
      (this._queue.length = 0),
        clearTimeout(this._queueTimeout),
        (this._queueTimeout = null);
    },
    _mergeSplitClusters: function () {
      let e = Math.round(this._map._zoom);
      this._processQueue(),
        this._zoom < e &&
        this._currentShownBounds.intersects(this._getExpandedVisibleBounds())
          ? (this._animationStart(),
            this._topClusterLevel._recursivelyRemoveChildrenFromMap(
              this._currentShownBounds,
              Math.floor(this._map.getMinZoom()),
              this._zoom,
              this._getExpandedVisibleBounds()
            ),
            this._animationZoomIn(this._zoom, e))
          : this._zoom > e
          ? (this._animationStart(), this._animationZoomOut(this._zoom, e))
          : this._moveEnd();
    },
    _getExpandedVisibleBounds: function () {
      return this.options.removeOutsideVisibleBounds
        ? L.Browser.mobile
          ? this._checkBoundsMaxLat(this._map.getBounds())
          : this._checkBoundsMaxLat(this._map.getBounds().pad(1))
        : this._mapBoundsInfinite;
    },
    _checkBoundsMaxLat: function (e) {
      let t = this._maxLat;
      return (
        t !== i &&
          (e.getNorth() >= t && (e._northEast.lat = 1 / 0),
          e.getSouth() <= -t && (e._southWest.lat = -1 / 0)),
        e
      );
    },
    _animationAddLayerNonAnimated: function (e, t) {
      if (t === e) {
        this._featureGroup.addLayer(e);
      } else if (2 === t._childCount) {
        t._addToMap();
        let i = t.getAllChildMarkers();
        this._featureGroup.removeLayer(i[0]),
          this._featureGroup.removeLayer(i[1]);
      } else {
        t._updateIcon();
      }
    },
    _extractNonGroupLayers: function (e, t) {
      let i,
        n = e.getLayers(),
        r = 0;
      for (t = t || []; r < n.length; r++) {
        (i = n[r]),
          i instanceof L.LayerGroup
            ? this._extractNonGroupLayers(i, t)
            : t.push(i);
      }
      return t;
    },
    _overrideMarkerIcon: function (e) {
      let t = (e.options.icon = this.options.iconCreateFunction({
        getChildCount: function () {
          return 1;
        },
        getAllChildMarkers: function () {
          return [e];
        },
      }));
      return t;
    },
  })),
    L.MarkerClusterGroup.include({
      _mapBoundsInfinite: new L.LatLngBounds(
        new L.LatLng(-1 / 0, -1 / 0),
        new L.LatLng(1 / 0, 1 / 0)
      ),
    }),
    L.MarkerClusterGroup.include({
      _noAnimation: {
        _animationStart: function () {},
        _animationZoomIn: function (e, t) {
          this._topClusterLevel._recursivelyRemoveChildrenFromMap(
            this._currentShownBounds,
            Math.floor(this._map.getMinZoom()),
            e
          ),
            this._topClusterLevel._recursivelyAddChildrenToMap(
              null,
              t,
              this._getExpandedVisibleBounds()
            ),
            this.fire("animationend");
        },
        _animationZoomOut: function (e, t) {
          this._topClusterLevel._recursivelyRemoveChildrenFromMap(
            this._currentShownBounds,
            Math.floor(this._map.getMinZoom()),
            e
          ),
            this._topClusterLevel._recursivelyAddChildrenToMap(
              null,
              t,
              this._getExpandedVisibleBounds()
            ),
            this.fire("animationend");
        },
        _animationAddLayer: function (e, t) {
          this._animationAddLayerNonAnimated(e, t);
        },
      },
      _withAnimation: {
        _animationStart: function () {
          (this._map._mapPane.className += " leaflet-cluster-anim"),
            this._inZoomAnimation++;
        },
        _animationZoomIn: function (e, t) {
          let i,
            n = this._getExpandedVisibleBounds(),
            r = this._featureGroup,
            s = Math.floor(this._map.getMinZoom());
          (this._ignoreMove = !0),
            this._topClusterLevel._recursively(n, e, s, function (s) {
              let o,
                a = s._latlng,
                h = s._markers;
              for (
                n.contains(a) || (a = null),
                  s._isSingleParent() && e + 1 === t
                    ? (r.removeLayer(s),
                      s._recursivelyAddChildrenToMap(null, t, n))
                    : (s.clusterHide(),
                      s._recursivelyAddChildrenToMap(a, t, n)),
                  i = h.length - 1;
                i >= 0;
                i--
              ) {
                (o = h[i]), n.contains(o._latlng) || r.removeLayer(o);
              }
            }),
            this._forceLayout(),
            this._topClusterLevel._recursivelyBecomeVisible(n, t),
            r.eachLayer(function (e) {
              e instanceof L.MarkerCluster || !e._icon || e.clusterShow();
            }),
            this._topClusterLevel._recursively(n, e, t, function (e) {
              e._recursivelyRestoreChildPositions(t);
            }),
            (this._ignoreMove = !1),
            this._enqueue(function () {
              this._topClusterLevel._recursively(n, e, s, function (e) {
                r.removeLayer(e), e.clusterShow();
              }),
                this._animationEnd();
            });
        },
        _animationZoomOut: function (e, t) {
          this._animationZoomOutSingle(this._topClusterLevel, e - 1, t),
            this._topClusterLevel._recursivelyAddChildrenToMap(
              null,
              t,
              this._getExpandedVisibleBounds()
            ),
            this._topClusterLevel._recursivelyRemoveChildrenFromMap(
              this._currentShownBounds,
              Math.floor(this._map.getMinZoom()),
              e,
              this._getExpandedVisibleBounds()
            );
        },
        _animationAddLayer: function (e, t) {
          let i = this,
            n = this._featureGroup;
          n.addLayer(e),
            t !== e &&
              (t._childCount > 2
                ? (t._updateIcon(),
                  this._forceLayout(),
                  this._animationStart(),
                  e._setPos(this._map.latLngToLayerPoint(t.getLatLng())),
                  e.clusterHide(),
                  this._enqueue(function () {
                    n.removeLayer(e), e.clusterShow(), i._animationEnd();
                  }))
                : (this._forceLayout(),
                  i._animationStart(),
                  i._animationZoomOutSingle(
                    t,
                    this._map.getMaxZoom(),
                    this._zoom
                  )));
        },
      },
      _animationZoomOutSingle: function (e, t, i) {
        let n = this._getExpandedVisibleBounds(),
          r = Math.floor(this._map.getMinZoom());
        e._recursivelyAnimateChildrenInAndAddSelfToMap(n, r, t + 1, i);
        let s = this;
        this._forceLayout(),
          e._recursivelyBecomeVisible(n, i),
          this._enqueue(function () {
            if (1 === e._childCount) {
              let o = e._markers[0];
              (this._ignoreMove = !0),
                o.setLatLng(o.getLatLng()),
                (this._ignoreMove = !1),
                o.clusterShow && o.clusterShow();
            } else {
              e._recursively(n, i, r, function (e) {
                e._recursivelyRemoveChildrenFromMap(n, r, t + 1);
              });
            }
            s._animationEnd();
          });
      },
      _animationEnd: function () {
        this._map &&
          (this._map._mapPane.className = this._map._mapPane.className.replace(
            " leaflet-cluster-anim",
            ""
          )),
          this._inZoomAnimation--,
          this.fire("animationend");
      },
      _forceLayout: function () {
        L.Util.falseFn(t.body.offsetWidth);
      },
    }),
    (L.markerClusterGroup = function (e) {
      return new L.MarkerClusterGroup(e);
    }),
    (L.MarkerCluster = L.Marker.extend({
      initialize: function (e, t, i, n) {
        L.Marker.prototype.initialize.call(
          this,
          i ? i._cLatLng || i.getLatLng() : new L.LatLng(0, 0),
          { icon: this, pane: e.options.clusterPane }
        ),
          (this._group = e),
          (this._zoom = t),
          (this._markers = []),
          (this._childClusters = []),
          (this._childCount = 0),
          (this._iconNeedsUpdate = !0),
          (this._boundsNeedUpdate = !0),
          (this._bounds = new L.LatLngBounds()),
          i && this._addChild(i),
          n && this._addChild(n);
      },
      getAllChildMarkers: function (e) {
        e = e || [];
        for (let t = this._childClusters.length - 1; t >= 0; t--) {
          this._childClusters[t].getAllChildMarkers(e);
        }
        for (let i = this._markers.length - 1; i >= 0; i--) {
          e.push(this._markers[i]);
        }
        return e;
      },
      getChildCount: function () {
        return this._childCount;
      },
      zoomToBounds: function (e) {
        for (
          var t,
            i = this._childClusters.slice(),
            n = this._group._map,
            r = n.getBoundsZoom(this._bounds),
            s = this._zoom + 1,
            o = n.getZoom();
          i.length > 0 && r > s;

        ) {
          s++;
          let a = [];
          for (t = 0; t < i.length; t++) {
            a = a.concat(i[t]._childClusters);
          }
          i = a;
        }
        r > s
          ? this._group._map.setView(this._latlng, s)
          : o >= r
          ? this._group._map.setView(this._latlng, o + 1)
          : this._group._map.fitBounds(this._bounds, e);
      },
      getBounds: function () {
        let e = new L.LatLngBounds();
        return e.extend(this._bounds), e;
      },
      _updateIcon: function () {
        (this._iconNeedsUpdate = !0), this._icon && this.setIcon(this);
      },
      createIcon: function () {
        return (
          this._iconNeedsUpdate &&
            ((this._iconObj = this._group.options.iconCreateFunction(this)),
            (this._iconNeedsUpdate = !1)),
          this._iconObj.createIcon()
        );
      },
      createShadow: function () {
        return this._iconObj.createShadow();
      },
      _addChild: function (e, t) {
        (this._iconNeedsUpdate = !0),
          (this._boundsNeedUpdate = !0),
          this._setClusterCenter(e),
          e instanceof L.MarkerCluster
            ? (t || (this._childClusters.push(e), (e.__parent = this)),
              (this._childCount += e._childCount))
            : (t || this._markers.push(e), this._childCount++),
          this.__parent && this.__parent._addChild(e, !0);
      },
      _setClusterCenter: function (e) {
        this._cLatLng || (this._cLatLng = e._cLatLng || e._latlng);
      },
      _resetBounds: function () {
        let e = this._bounds;
        e._southWest &&
          ((e._southWest.lat = 1 / 0), (e._southWest.lng = 1 / 0)),
          e._northEast &&
            ((e._northEast.lat = -1 / 0), (e._northEast.lng = -1 / 0));
      },
      _recalculateBounds: function () {
        let e,
          t,
          i,
          n,
          r = this._markers,
          s = this._childClusters,
          o = 0,
          a = 0,
          h = this._childCount;
        if (0 !== h) {
          for (this._resetBounds(), e = 0; e < r.length; e++) {
            (i = r[e]._latlng),
              this._bounds.extend(i),
              (o += i.lat),
              (a += i.lng);
          }
          for (e = 0; e < s.length; e++) {
            (t = s[e]),
              t._boundsNeedUpdate && t._recalculateBounds(),
              this._bounds.extend(t._bounds),
              (i = t._wLatLng),
              (n = t._childCount),
              (o += i.lat * n),
              (a += i.lng * n);
          }
          (this._latlng = this._wLatLng = new L.LatLng(o / h, a / h)),
            (this._boundsNeedUpdate = !1);
        }
      },
      _addToMap: function (e) {
        e && ((this._backupLatlng = this._latlng), this.setLatLng(e)),
          this._group._featureGroup.addLayer(this);
      },
      _recursivelyAnimateChildrenIn: function (e, t, i) {
        this._recursively(
          e,
          this._group._map.getMinZoom(),
          i - 1,
          function (e) {
            let i,
              n,
              r = e._markers;
            for (i = r.length - 1; i >= 0; i--) {
              (n = r[i]), n._icon && (n._setPos(t), n.clusterHide());
            }
          },
          function (e) {
            let i,
              n,
              r = e._childClusters;
            for (i = r.length - 1; i >= 0; i--) {
              (n = r[i]), n._icon && (n._setPos(t), n.clusterHide());
            }
          }
        );
      },
      _recursivelyAnimateChildrenInAndAddSelfToMap: function (e, t, i, n) {
        this._recursively(e, n, t, function (r) {
          r._recursivelyAnimateChildrenIn(
            e,
            r._group._map.latLngToLayerPoint(r.getLatLng()).round(),
            i
          ),
            r._isSingleParent() && i - 1 === n
              ? (r.clusterShow(), r._recursivelyRemoveChildrenFromMap(e, t, i))
              : r.clusterHide(),
            r._addToMap();
        });
      },
      _recursivelyBecomeVisible: function (e, t) {
        this._recursively(
          e,
          this._group._map.getMinZoom(),
          t,
          null,
          function (e) {
            e.clusterShow();
          }
        );
      },
      _recursivelyAddChildrenToMap: function (e, t, i) {
        this._recursively(
          i,
          this._group._map.getMinZoom() - 1,
          t,
          function (n) {
            if (t !== n._zoom) {
              for (let r = n._markers.length - 1; r >= 0; r--) {
                let s = n._markers[r];
                i.contains(s._latlng) &&
                  (e &&
                    ((s._backupLatlng = s.getLatLng()),
                    s.setLatLng(e),
                    s.clusterHide && s.clusterHide()),
                  n._group._featureGroup.addLayer(s));
              }
            }
          },
          function (t) {
            t._addToMap(e);
          }
        );
      },
      _recursivelyRestoreChildPositions: function (e) {
        for (let t = this._markers.length - 1; t >= 0; t--) {
          let i = this._markers[t];
          i._backupLatlng &&
            (i.setLatLng(i._backupLatlng), delete i._backupLatlng);
        }
        if (e - 1 === this._zoom) {
          for (let n = this._childClusters.length - 1; n >= 0; n--) {
            this._childClusters[n]._restorePosition();
          }
        } else {
          for (let r = this._childClusters.length - 1; r >= 0; r--) {
            this._childClusters[r]._recursivelyRestoreChildPositions(e);
          }
        }
      },
      _restorePosition: function () {
        this._backupLatlng &&
          (this.setLatLng(this._backupLatlng), delete this._backupLatlng);
      },
      _recursivelyRemoveChildrenFromMap: function (e, t, i, n) {
        let r, s;
        this._recursively(
          e,
          t - 1,
          i - 1,
          function (e) {
            for (s = e._markers.length - 1; s >= 0; s--) {
              (r = e._markers[s]),
                (n && n.contains(r._latlng)) ||
                  (e._group._featureGroup.removeLayer(r),
                  r.clusterShow && r.clusterShow());
            }
          },
          function (e) {
            for (s = e._childClusters.length - 1; s >= 0; s--) {
              (r = e._childClusters[s]),
                (n && n.contains(r._latlng)) ||
                  (e._group._featureGroup.removeLayer(r),
                  r.clusterShow && r.clusterShow());
            }
          }
        );
      },
      _recursively: function (e, t, i, n, r) {
        let s,
          o,
          a = this._childClusters,
          h = this._zoom;
        if (
          (h >= t && (n && n(this), r && h === i && r(this)), t > h || i > h)
        ) {
          for (s = a.length - 1; s >= 0; s--) {
            (o = a[s]),
              e.intersects(o._bounds) && o._recursively(e, t, i, n, r);
          }
        }
      },
      _isSingleParent: function () {
        return (
          this._childClusters.length > 0 &&
          this._childClusters[0]._childCount === this._childCount
        );
      },
    })),
    L.Marker.include({
      clusterHide: function () {
        return (
          (this.options.opacityWhenUnclustered = this.options.opacity || 1),
          this.setOpacity(0)
        );
      },
      clusterShow: function () {
        let e = this.setOpacity(
          this.options.opacity || this.options.opacityWhenUnclustered
        );
        return delete this.options.opacityWhenUnclustered, e;
      },
    }),
    (L.DistanceGrid = function (e) {
      (this._cellSize = e),
        (this._sqCellSize = e * e),
        (this._grid = {}),
        (this._objectPoint = {});
    }),
    (L.DistanceGrid.prototype = {
      addObject: function (e, t) {
        let i = this._getCoord(t.x),
          n = this._getCoord(t.y),
          r = this._grid,
          s = (r[n] = r[n] || {}),
          o = (s[i] = s[i] || []),
          a = L.Util.stamp(e);
        (this._objectPoint[a] = t), o.push(e);
      },
      updateObject: function (e, t) {
        this.removeObject(e), this.addObject(e, t);
      },
      removeObject: function (e, t) {
        let i,
          n,
          r = this._getCoord(t.x),
          s = this._getCoord(t.y),
          o = this._grid,
          a = (o[s] = o[s] || {}),
          h = (a[r] = a[r] || []);
        for (
          delete this._objectPoint[L.Util.stamp(e)], i = 0, n = h.length;
          n > i;
          i++
        ) {
          if (h[i] === e) {
            return h.splice(i, 1), 1 === n && delete a[r], !0;
          }
        }
      },
      eachObject: function (e, t) {
        let i,
          n,
          r,
          s,
          o,
          a,
          h,
          l = this._grid;
        for (i in l) {
          o = l[i];
          for (n in o) {
            for (a = o[n], r = 0, s = a.length; s > r; r++) {
              (h = e.call(t, a[r])), h && (r--, s--);
            }
          }
        }
      },
      getNearObject: function (e) {
        let t,
          i,
          n,
          r,
          s,
          o,
          a,
          h,
          l = this._getCoord(e.x),
          u = this._getCoord(e.y),
          _ = this._objectPoint,
          d = this._sqCellSize,
          c = null;
        for (t = u - 1; u + 1 >= t; t++) {
          if ((r = this._grid[t])) {
            for (i = l - 1; l + 1 >= i; i++) {
              if ((s = r[i])) {
                for (n = 0, o = s.length; o > n; n++) {
                  (a = s[n]),
                    (h = this._sqDist(_[L.Util.stamp(a)], e)),
                    d > h && ((d = h), (c = a));
                }
              }
            }
          }
        }
        return c;
      },
      _getCoord: function (e) {
        return Math.floor(e / this._cellSize);
      },
      _sqDist: function (e, t) {
        let i = t.x - e.x,
          n = t.y - e.y;
        return i * i + n * n;
      },
    }),
    (function () {
      L.QuickHull = {
        getDistant: function (e, t) {
          let i = t[1].lat - t[0].lat,
            n = t[0].lng - t[1].lng;
          return n * (e.lat - t[0].lat) + i * (e.lng - t[0].lng);
        },
        findMostDistantPointFromBaseLine: function (e, t) {
          let i,
            n,
            r,
            s = 0,
            o = null,
            a = [];
          for (i = t.length - 1; i >= 0; i--) {
            (n = t[i]),
              (r = this.getDistant(n, e)),
              r > 0 && (a.push(n), r > s && ((s = r), (o = n)));
          }
          return { maxPoint: o, newPoints: a };
        },
        buildConvexHull: function (e, t) {
          let i = [],
            n = this.findMostDistantPointFromBaseLine(e, t);
          return n.maxPoint
            ? ((i = i.concat(
                this.buildConvexHull([e[0], n.maxPoint], n.newPoints)
              )),
              (i = i.concat(
                this.buildConvexHull([n.maxPoint, e[1]], n.newPoints)
              )))
            : [e[0]];
        },
        getConvexHull: function (e) {
          let t,
            i = !1,
            n = !1,
            r = !1,
            s = !1,
            o = null,
            a = null,
            h = null,
            l = null,
            u = null,
            _ = null;
          for (t = e.length - 1; t >= 0; t--) {
            let d = e[t];
            (i === !1 || d.lat > i) && ((o = d), (i = d.lat)),
              (n === !1 || d.lat < n) && ((a = d), (n = d.lat)),
              (r === !1 || d.lng > r) && ((h = d), (r = d.lng)),
              (s === !1 || d.lng < s) && ((l = d), (s = d.lng));
          }
          n !== i ? ((_ = a), (u = o)) : ((_ = l), (u = h));
          let c = [].concat(
            this.buildConvexHull([_, u], e),
            this.buildConvexHull([u, _], e)
          );
          return c;
        },
      };
    })(),
    L.MarkerCluster.include({
      getConvexHull: function () {
        let e,
          t,
          i = this.getAllChildMarkers(),
          n = [];
        for (t = i.length - 1; t >= 0; t--) {
          (e = i[t].getLatLng()), n.push(e);
        }
        return L.QuickHull.getConvexHull(n);
      },
    }),
    L.MarkerCluster.include({
      _2PI: 2 * Math.PI,
      _circleFootSeparation: 25,
      _circleStartAngle: Math.PI / 6,
      _spiralFootSeparation: 28,
      _spiralLengthStart: 11,
      _spiralLengthFactor: 5,
      _circleSpiralSwitchover: 9,
      spiderfy: function () {
        if (this._group._spiderfied !== this && !this._group._inZoomAnimation) {
          let e,
            t = this.getAllChildMarkers(),
            i = this._group,
            n = i._map,
            r = n.latLngToLayerPoint(this._latlng);
          this._group._unspiderfy(),
            (this._group._spiderfied = this),
            t.length >= this._circleSpiralSwitchover
              ? (e = this._generatePointsSpiral(t.length, r))
              : ((r.y += 10), (e = this._generatePointsCircle(t.length, r))),
            this._animationSpiderfy(t, e);
        }
      },
      unspiderfy: function (e) {
        this._group._inZoomAnimation ||
          (this._animationUnspiderfy(e), (this._group._spiderfied = null));
      },
      _generatePointsCircle: function (e, t) {
        let i,
          n,
          r =
            this._group.options.spiderfyDistanceMultiplier *
            this._circleFootSeparation *
            (2 + e),
          s = r / this._2PI,
          o = this._2PI / e,
          a = [];
        for (a.length = e, i = e - 1; i >= 0; i--) {
          (n = this._circleStartAngle + i * o),
            (a[i] = new L.Point(
              t.x + s * Math.cos(n),
              t.y + s * Math.sin(n)
            )._round());
        }
        return a;
      },
      _generatePointsSpiral: function (e, t) {
        let i,
          n = this._group.options.spiderfyDistanceMultiplier,
          r = n * this._spiralLengthStart,
          s = n * this._spiralFootSeparation,
          o = n * this._spiralLengthFactor * this._2PI,
          a = 0,
          h = [];
        for (h.length = e, i = e - 1; i >= 0; i--) {
          (a += s / r + 5e-4 * i),
            (h[i] = new L.Point(
              t.x + r * Math.cos(a),
              t.y + r * Math.sin(a)
            )._round()),
            (r += o / a);
        }
        return h;
      },
      _noanimationUnspiderfy: function () {
        let e,
          t,
          i = this._group,
          n = i._map,
          r = i._featureGroup,
          s = this.getAllChildMarkers();
        for (
          i._ignoreMove = !0, this.setOpacity(1), t = s.length - 1;
          t >= 0;
          t--
        ) {
          (e = s[t]),
            r.removeLayer(e),
            e._preSpiderfyLatlng &&
              (e.setLatLng(e._preSpiderfyLatlng), delete e._preSpiderfyLatlng),
            e.setZIndexOffset && e.setZIndexOffset(0),
            e._spiderLeg && (n.removeLayer(e._spiderLeg), delete e._spiderLeg);
        }
        i.fire("unspiderfied", { cluster: this, markers: s }),
          (i._ignoreMove = !1),
          (i._spiderfied = null);
      },
    }),
    (L.MarkerClusterNonAnimated = L.MarkerCluster.extend({
      _animationSpiderfy: function (e, t) {
        let i,
          n,
          r,
          s,
          o = this._group,
          a = o._map,
          h = o._featureGroup,
          l = this._group.options.spiderLegPolylineOptions;
        for (o._ignoreMove = !0, i = 0; i < e.length; i++) {
          (s = a.layerPointToLatLng(t[i])),
            (n = e[i]),
            (r = new L.Polyline([this._latlng, s], l)),
            a.addLayer(r),
            (n._spiderLeg = r),
            (n._preSpiderfyLatlng = n._latlng),
            n.setLatLng(s),
            n.setZIndexOffset && n.setZIndexOffset(1e6),
            h.addLayer(n);
        }
        this.setOpacity(0.3),
          (o._ignoreMove = !1),
          o.fire("spiderfied", { cluster: this, markers: e });
      },
      _animationUnspiderfy: function () {
        this._noanimationUnspiderfy();
      },
    })),
    L.MarkerCluster.include({
      _animationSpiderfy: function (e, t) {
        let n,
          r,
          s,
          o,
          a,
          h,
          l = this,
          u = this._group,
          _ = u._map,
          d = u._featureGroup,
          c = this._latlng,
          p = _.latLngToLayerPoint(c),
          f = L.Path.SVG,
          m = L.extend({}, this._group.options.spiderLegPolylineOptions),
          g = m.opacity;
        for (
          g === i &&
            (g =
              L.MarkerClusterGroup.prototype.options.spiderLegPolylineOptions
                .opacity),
            f
              ? ((m.opacity = 0),
                (m.className =
                  (m.className || "") + " leaflet-cluster-spider-leg"))
              : (m.opacity = g),
            u._ignoreMove = !0,
            n = 0;
          n < e.length;
          n++
        ) {
          (r = e[n]),
            (h = _.layerPointToLatLng(t[n])),
            (s = new L.Polyline([c, h], m)),
            _.addLayer(s),
            (r._spiderLeg = s),
            f &&
              ((o = s._path),
              (a = o.getTotalLength() + 0.1),
              (o.style.strokeDasharray = a),
              (o.style.strokeDashoffset = a)),
            r.setZIndexOffset && r.setZIndexOffset(1e6),
            r.clusterHide && r.clusterHide(),
            d.addLayer(r),
            r._setPos && r._setPos(p);
        }
        for (
          u._forceLayout(), u._animationStart(), n = e.length - 1;
          n >= 0;
          n--
        ) {
          (h = _.layerPointToLatLng(t[n])),
            (r = e[n]),
            (r._preSpiderfyLatlng = r._latlng),
            r.setLatLng(h),
            r.clusterShow && r.clusterShow(),
            f &&
              ((s = r._spiderLeg),
              (o = s._path),
              (o.style.strokeDashoffset = 0),
              s.setStyle({ opacity: g }));
        }
        this.setOpacity(0.3),
          (u._ignoreMove = !1),
          setTimeout(function () {
            u._animationEnd(), u.fire("spiderfied", { cluster: l, markers: e });
          }, 200);
      },
      _animationUnspiderfy: function (e) {
        let t,
          i,
          n,
          r,
          s,
          o,
          a = this,
          h = this._group,
          l = h._map,
          u = h._featureGroup,
          _ = e
            ? l._latLngToNewLayerPoint(this._latlng, e.zoom, e.center)
            : l.latLngToLayerPoint(this._latlng),
          d = this.getAllChildMarkers(),
          c = L.Path.SVG;
        for (
          h._ignoreMove = !0,
            h._animationStart(),
            this.setOpacity(1),
            i = d.length - 1;
          i >= 0;
          i--
        ) {
          (t = d[i]),
            t._preSpiderfyLatlng &&
              (t.closePopup(),
              t.setLatLng(t._preSpiderfyLatlng),
              delete t._preSpiderfyLatlng,
              (o = !0),
              t._setPos && (t._setPos(_), (o = !1)),
              t.clusterHide && (t.clusterHide(), (o = !1)),
              o && u.removeLayer(t),
              c &&
                ((n = t._spiderLeg),
                (r = n._path),
                (s = r.getTotalLength() + 0.1),
                (r.style.strokeDashoffset = s),
                n.setStyle({ opacity: 0 })));
        }
        (h._ignoreMove = !1),
          setTimeout(function () {
            let e = 0;
            for (i = d.length - 1; i >= 0; i--) {
              (t = d[i]), t._spiderLeg && e++;
            }
            for (i = d.length - 1; i >= 0; i--) {
              (t = d[i]),
                t._spiderLeg &&
                  (t.clusterShow && t.clusterShow(),
                  t.setZIndexOffset && t.setZIndexOffset(0),
                  e > 1 && u.removeLayer(t),
                  l.removeLayer(t._spiderLeg),
                  delete t._spiderLeg);
            }
            h._animationEnd(),
              h.fire("unspiderfied", { cluster: a, markers: d });
          }, 200);
      },
    }),
    L.MarkerClusterGroup.include({
      _spiderfied: null,
      unspiderfy: function () {
        this._unspiderfy.apply(this, arguments);
      },
      _spiderfierOnAdd: function () {
        this._map.on("click", this._unspiderfyWrapper, this),
          this._map.options.zoomAnimation &&
            this._map.on("zoomstart", this._unspiderfyZoomStart, this),
          this._map.on("zoomend", this._noanimationUnspiderfy, this),
          L.Browser.touch || this._map.getRenderer(this);
      },
      _spiderfierOnRemove: function () {
        this._map.off("click", this._unspiderfyWrapper, this),
          this._map.off("zoomstart", this._unspiderfyZoomStart, this),
          this._map.off("zoomanim", this._unspiderfyZoomAnim, this),
          this._map.off("zoomend", this._noanimationUnspiderfy, this),
          this._noanimationUnspiderfy();
      },
      _unspiderfyZoomStart: function () {
        this._map && this._map.on("zoomanim", this._unspiderfyZoomAnim, this);
      },
      _unspiderfyZoomAnim: function (e) {
        L.DomUtil.hasClass(this._map._mapPane, "leaflet-touching") ||
          (this._map.off("zoomanim", this._unspiderfyZoomAnim, this),
          this._unspiderfy(e));
      },
      _unspiderfyWrapper: function () {
        this._unspiderfy();
      },
      _unspiderfy: function (e) {
        this._spiderfied && this._spiderfied.unspiderfy(e);
      },
      _noanimationUnspiderfy: function () {
        this._spiderfied && this._spiderfied._noanimationUnspiderfy();
      },
      _unspiderfyLayer: function (e) {
        e._spiderLeg &&
          (this._featureGroup.removeLayer(e),
          e.clusterShow && e.clusterShow(),
          e.setZIndexOffset && e.setZIndexOffset(0),
          this._map.removeLayer(e._spiderLeg),
          delete e._spiderLeg);
      },
    }),
    L.MarkerClusterGroup.include({
      refreshClusters: function (e) {
        return (
          e
            ? e instanceof L.MarkerClusterGroup
              ? (e = e._topClusterLevel.getAllChildMarkers())
              : e instanceof L.LayerGroup
              ? (e = e._layers)
              : e instanceof L.MarkerCluster
              ? (e = e.getAllChildMarkers())
              : e instanceof L.Marker && (e = [e])
            : (e = this._topClusterLevel.getAllChildMarkers()),
          this._flagParentsIconsNeedUpdate(e),
          this._refreshClustersIcons(),
          this.options.singleMarkerMode &&
            this._refreshSingleMarkerModeMarkers(e),
          this
        );
      },
      _flagParentsIconsNeedUpdate: function (e) {
        let t, i;
        for (t in e) {
          for (i = e[t].__parent; i; ) {
            (i._iconNeedsUpdate = !0), (i = i.__parent);
          }
        }
      },
      _refreshSingleMarkerModeMarkers: function (e) {
        let t, i;
        for (t in e) {
          (i = e[t]),
            this.hasLayer(i) && i.setIcon(this._overrideMarkerIcon(i));
        }
      },
    }),
    L.Marker.include({
      refreshIconOptions: function (e, t) {
        let i = this.options.icon;
        return (
          L.setOptions(i, e),
          this.setIcon(i),
          t && this.__parent && this.__parent._group.refreshClusters(this),
          this
        );
      },
    });
})(window, document);

